import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@/components/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const PasswordField = ({ label = "", ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {!label ? null : (
          <Typography variant="body2" component="label" sx={{ fontWeight: 600, marginBottom: ".5rem" }}>
            {label}
          </Typography>
        )}
        <TextField
          size="small"
          type={showPassword ? "text" : "password"}
          {...props}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} size="small">
                  {showPassword ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default PasswordField;
