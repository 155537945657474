import Dialog, { DialogContent, DialogActions } from "@/components/Dialog";
import DownloadIcon from "@mui/icons-material/DownloadRounded";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import InlineContainer from "@/components/InlineContainer";
import Button from "@mui/material/Button";
import { useTheme, useMediaQuery } from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import CheckMarkIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import useMessage from "@/hooks/useMessage";
import SystemFeedback from "@/components/SystemFeedback";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Query } from "@/types";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { importPriceItemList } from "@/features/price-management/price-management-queries";

export default NiceModal.create(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const modal = useModal();
  const [message, setMessage] = useMessage();

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      "text/csv": [".csv"],
    },
    onDropAccepted: () => {
      setMessage(null);
    },
  });

  const handleUpload = useMutation((file: File) => importPriceItemList(file), {
    onSuccess: (response) => {
      if (response?.errorValidation?.length || response?.reqId?.length) {
        setMessage({
          type: "error",
          message: response?.errorValidation?.length
            ? response?.errorValidation?.map((error, index) => (
                <Typography variant="body2" key={`${error}-${index}`} sx={{ fontSize: "0.85rem" }}>
                  {error}
                </Typography>
              ))
            : response?.message,
        });
      } else {
        enqueueSnackbar("Customer list uploaded successfully", {
          variant: "success",
        });
        queryClient.invalidateQueries([Query.account.PRICE_ITEMS_PAGINATION]);
        queryClient.invalidateQueries([Query.account.INVOICE_BUILDER_ALL_PRICE_ITEMS]);
        modal.hide();
        modal.resolve();
      }
    },
    onError: (error: any) => {
      setMessage({
        type: "error",
        message: error?.errorValidation?.length
          ? error?.errorValidation?.map((error, index) => (
              <Typography variant="body2" key={`${error}-${index}`} sx={{ fontSize: "0.85rem" }}>
                {error}
              </Typography>
            ))
          : error?.message,
      });
    },
  });

  return (
    <Dialog modal={modal} maxWidth="sm" title="Import Price Items (.csv file)">
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box width="80%" display="inline-flex" alignItems="flex-start" justifyContent="center" marginTop="1rem">
          <Box>
            <Box
              sx={{
                backgroundColor: "primary.dark",
                borderRadius: "50%",
                color: "white",
                display: "flex",
                flexBasis: "auto",
                justifyContent: "center",
                alignItems: "center",
                width: 34,
                height: 34,
              }}
            >
              1
            </Box>
          </Box>
          <Box marginLeft="1rem">
            <Typography variant="body1" color="primary.dark" sx={{ fontSize: "1.25rem" }}>
              Download .csv Template
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1, marginBottom: 2 }}>
              Please download and fill in the fields provided in this .csv file template.
            </Typography>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon sx={{ color: "currentColor" }} />}
              sx={{
                boxShadow: 2,
                "&:active": { boxShadow: 5 },
              }}
              href="/Import+PriceItems+Template.csv"
            >
              Download Template
            </Button>
          </Box>
        </Box>
        <Divider
          flexItem
          sx={{
            borderStyle: "dashed",
            borderBottomWidth: "2px !important",
            display: "block",
            margin: "2.5rem 0 !important",
            padding: 0,
          }}
        />
        <Box width="80%" display="inline-flex" alignItems="flex-start" justifyContent="center" marginBottom="2.5rem">
          <Box>
            <Box
              sx={{
                backgroundColor: "primary.dark",
                borderRadius: "50%",
                color: "white",
                display: "flex",
                flexBasis: "auto",
                justifyContent: "center",
                alignItems: "center",
                width: 34,
                height: 34,
              }}
            >
              2
            </Box>
          </Box>
          <Box marginLeft="1rem">
            <Typography variant="body1" color="primary.dark" sx={{ fontSize: "1.25rem" }}>
              Upload Your Price Items
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1, marginBottom: 2 }}>
              Add the completed .csv file template and it will populate your price item list.
            </Typography>
            <Box
              {...getRootProps()}
              style={{
                borderWidth: "2px",
                borderStyle: "dashed",
                borderColor: isDragActive ? "primary.main" : "grey.400",
                borderRadius: "10px",
                backgroundColor: "grey.50",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                padding: "1.5rem",
                height: 140,
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <>
                  <UploadIcon />
                  <Typography variant="body2" sx={{ opacity: 0.7 }}>
                    Drop your .csv file here.
                  </Typography>
                </>
              ) : acceptedFiles?.length ? (
                <>
                  {acceptedFiles.map((file) => (
                    <InlineContainer
                      key={file.name}
                      sx={{
                        color: "success.main",
                        marginBottom: "0.75rem",
                        alignItems: "flex-start",
                      }}
                    >
                      <CheckMarkIcon fontSize="small" sx={{ marginRight: 1 }} />
                      {file.name}
                    </InlineContainer>
                  ))}
                  <Typography variant="body2" sx={{ opacity: 0.7 }}>
                    Drop another .csv file here,
                    <br />
                    or <Link>click to browse files</Link>.
                  </Typography>
                </>
              ) : (
                <>
                  <UploadIcon />
                  <Typography variant="body2" sx={{ opacity: 0.7 }}>
                    Drop your .csv file here, or <Link>browse your files</Link>.
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.7 }}>
                    Supports .csv
                    <br />
                    10MB max file size.
                  </Typography>
                </>
              )}
            </Box>
            <SystemFeedback {...message} />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <InlineContainer justifyContent="center" width="100%">
          <AffirmativeButton
            type="submit"
            form="bulk-upload-form"
            disabled={!Boolean(acceptedFiles?.length)}
            onClick={() => handleUpload.mutate(acceptedFiles?.[0])}
            fullWidth={isMobile}
            sx={{ minWidth: 140 }}
            loading={handleUpload.isLoading}
          >
            Import Price Items
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
