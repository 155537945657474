import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Typography from "@mui/material/Typography";
import InlineContainer from "@/components/InlineContainer";
import { useTheme, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import TextField from "@/components/TextField";
import { useFormik } from "formik";
import getModifiedValues from "@/utils/getModifiedValues";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Query } from "@/types";
import SystemFeedback from "@/components/SystemFeedback";
import useMessage from "@/hooks/useMessage";
import {
  createCustomerCategory,
  saveCustomerCategory,
} from "@/features/customer-management/customer-management-queries";
import NeutralButton from "@/components/buttons/NeutralButton";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";

// TODO: Add types for all major resources
export default NiceModal.create<{
  customerId: string;
  category: any;
  priceItems: any[];
}>(({ customerId, category, priceItems }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = !isMobile;
  const queryClient = useQueryClient();
  const modal = useModal();
  const [message, setMessage] = useMessage();

  const handleCloseModal = () => {
    modal.reject({ priceItems });
    modal.hide();
    formik.resetForm();
  };

  const initialValues = {
    name: category?.name ?? "",
    status: category?.status ?? "ACTIVE",
    note: category?.note ?? "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const valuesToSend = getModifiedValues(values, initialValues);

      if (Object.keys(valuesToSend).length) {
        if (category?.id) {
          editCategory.mutate({ id: category?.id, ...valuesToSend });
        } else {
          createCategory.mutate({
            id: customerId,
            name: values?.name,
            note: values?.note,
          });
        }
      }
    },
  });

  const handleSuccess = (data) => {
    modal.resolve({ category: data, priceItems });
    queryClient.invalidateQueries([Query.account.CUSTOMER_INFO]);
    queryClient.invalidateQueries([Query.account.INVOICE_BUILDER_ALL_CUSTOMERS]);
    queryClient.invalidateQueries([Query.account.INVOICE_BUILDER_ALL_CUSTOMER_CATEGORIES]);
    queryClient.invalidateQueries([Query.account.CUSTOMERS_PAGINATION]);
    modal.hide();
  };

  const createCategory = useMutation((values: any) => createCustomerCategory(values), {
    onSuccess: handleSuccess,
    onError: (error: any) => {
      setMessage({
        type: "error",
        message: error?.response?.data?.message ?? "Cannot create service for.",
      });
    },
  });

  const editCategory = useMutation(saveCustomerCategory, {
    onSuccess: handleSuccess,
    onError: (error: any) => {
      setMessage({
        type: "error",
        message: error?.response?.data?.message ?? "Cannot edit service for.",
      });
    },
  });

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            {category?.id ? "Service For Details" : "Create Service For"}
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "1.25rem" }} dividers>
        <form onSubmit={formik.handleSubmit} id="customer-category-form">
          <SystemFeedback {...message} />
          <TextField
            label="Service For Name"
            inputProps={{
              maxLength: 50,
            }}
            sx={{ width: "100%" }}
            {...formik.getFieldProps("name")}
          />
          <Typography
            variant="body2"
            sx={{
              color: (formik.values.name.length ?? 0) > 50 ? "error.dark" : "grey.600",
              display: "block",
              margin: "0.5rem 0 0 0",
              textAlign: "right",
            }}
          >
            {formik.values.name.length ?? 0} / 50
          </Typography>
          <TextField
            label="Notes"
            rows={4}
            multiline
            sx={{
              width: "100%",
              marginTop: "20px",
            }}
            {...formik.getFieldProps("note")}
          />
          {/*{category?.id && (*/}
          {/*  <>*/}
          {/*    <Select*/}
          {/*      label="Service For Name"*/}
          {/*      {...formik.getFieldProps("status")}*/}
          {/*      sx={{ marginTop: 3 }}*/}
          {/*    >*/}
          {/*      <MenuItem value="ACTIVE">ACTIVE</MenuItem>*/}
          {/*      <MenuItem value="INACTIVE">INACTIVE</MenuItem>*/}
          {/*    </Select>*/}
          {/*  </>*/}
          {/*)}*/}
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          {isDesktop && (
            <NeutralButton disableElevation type="button" onClick={handleCloseModal}>
              Cancel
            </NeutralButton>
          )}
          <AffirmativeButton
            type="submit"
            form="customer-category-form"
            disabled={createCategory.isLoading || editCategory.isLoading}
            sx={{ width: { xs: "100%", md: 170 } }}
            loading={createCategory.isLoading || editCategory.isLoading}
          >
            Save
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
