import Dialog, { DialogContent, DialogActions } from "@/components/Dialog";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InlineContainer from "@/components/InlineContainer";
import { useTheme, useMediaQuery } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { useEffect, useState } from "react";
import useAccountUpdater from "@/hooks/useAccountUpdater";
import useUserUpdater from "@/hooks/useUserUpdater";
import NeutralButton from "../../components/buttons/NeutralButton";
import NotShady from "@/features/onboarding/NotShady";
import LegalPersonalName from "@/features/onboarding/personal/FullLegalName";
import DateOfBirth from "@/features/onboarding/personal/DateOfBirth";
import PersonalAddress from "@/features/onboarding/personal/PersonalAddress";
import LegalBusinessName from "@/features/onboarding/business/LegalBusinessName";
import BusinessAddress from "@/features/onboarding/business/BusinessAddress";
import BusinessContact from "@/features/onboarding/business/BusinessContact";
import BusinessService from "@/features/onboarding/business/BusinessService";
import BusinessType from "@/features/onboarding/business/BusinessType";
import BusinessSsn from "@/features/onboarding/business/BusinessSsn";
import BusinessRelation from "@/features/onboarding/business/BusinessRelation";
import BusinessLinks from "@/features/onboarding/business/BusinessLinks";
import BusinessWalletInstrument from "@/features/onboarding/business/BusinessWalletInstrument";
import ChevronRightRounded from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded";
import { useAddNewWalletInstrument } from "@/features/wallet/components/payment-method/AddWalletInstrument";
import {
  AllowedPaymentMethodSelection,
  BusinessStructureEnum,
  ComplianceStatusEnum,
  PaymentMethodTypeEnum,
  Query,
} from "@/types";
import { selectWallet } from "@/features/wallet/wallet-selectors";
import useAppSelector from "@/hooks/useAppSelector";
import Image from "next/legacy/image";
import { updateWallet } from "@/features/wallet/wallet-queries";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAccount from "@/features/account-management/useAccount";
import { complianceNotSubmitted } from "@/utils/compliance";
import { verifyUserInquiry } from "@/features/account-management/account-management-queries";
import PersonaVerification from "@/features/onboarding/PersonaVerification";
import { formatWalletInstruments } from "@/utils/wallet";

const images = {
  [ComplianceStatusEnum.PASS]: (
    <Image src="/icon-approved-app.svg" width={72} height={72} alt="Compliance Status: Approved" />
  ),
  [ComplianceStatusEnum.PENDING]: (
    <Image src="/icon-reviewing-app.svg" width={72} height={72} alt="Compliance Status: Pending" />
  ),
  [ComplianceStatusEnum.PROVISIONAL]: (
    <Image src="/icon-approved-app.svg" width={72} height={72} alt="Compliance Status: Approved" />
  ),
  [ComplianceStatusEnum.FAIL]: (
    <Image src="/icon-unapproved-app.svg" width={72} height={72} alt="Compliance Status: Failed" />
  ),
  [ComplianceStatusEnum.NOT_SUBMITTED]: (
    <Image src="/icon-unapproved-app.svg" width={72} height={72} alt="Compliance Status: Not Submitted" />
  ),
};

const titles = {
  [ComplianceStatusEnum.PASS]: "Congratulations, you’re approved!",
  [ComplianceStatusEnum.PENDING]: "We’re reviewing your application",
  [ComplianceStatusEnum.PROVISIONAL]: "Congratulations, you’re approved!",
  [ComplianceStatusEnum.FAIL]: "We are unable to approve you for payment processing",
  [ComplianceStatusEnum.NOT_SUBMITTED]: "You're almost ready to submit your application",
};

const descriptions = {
  [ComplianceStatusEnum.PASS]:
    "Payments from your customers will now be deposited directly into your Bank Account for Deposits. You can see which payments have been sent under “Deposits Sent” on your Dashboard.",
  [ComplianceStatusEnum.PROVISIONAL]:
    "Payments from your customers will now be deposited directly into your Bank Account for Deposits. You can see which payments have been sent under “Deposits Sent” on your Dashboard.",
  [ComplianceStatusEnum.PENDING]:
    "After your business details are verified, funds will be directly deposited to your bank account.",
  [ComplianceStatusEnum.FAIL]:
    "No worries! All other services will keep running smoothly, except for Invoices and Pay-by Link.",
  [ComplianceStatusEnum.NOT_SUBMITTED]:
    "Once you've provided all of the information requested in this window, we'll be able to review your application and enable direct deposits! Come back at any time to continue or click the 'Back' button.",
};

const ComplianceResult = ({ account }) => {
  return (
    <DialogContent
      dividers={false}
      sx={{
        minHeight: "55vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box>{images[account?.compliance_status ?? ComplianceStatusEnum.NOT_SUBMITTED]}</Box>
      <Typography variant="h4" sx={{ margin: "1rem 0" }}>
        {titles[account?.compliance_status ?? ComplianceStatusEnum.NOT_SUBMITTED]}
      </Typography>
      <Typography variant="body1" sx={{ margin: "0" }}>
        {descriptions[account?.compliance_status ?? ComplianceStatusEnum.NOT_SUBMITTED]}
      </Typography>
    </DialogContent>
  );
};

const steps = [
  {
    key: "personal-info",
    label: "Personal Info",
    children: {
      0: (props) => <NotShady {...props} />,
      1: (props) => <LegalPersonalName {...props} />,
      2: (props) => <DateOfBirth {...props} />,
      3: (props) => <PersonalAddress {...props} />,
    },
    validation: {
      1: "fullName",
      2: "dateOfBirth",
      3: "personalAddress",
    },
  },
  {
    key: "business-info",
    label: "Business Info",
    children: {
      0: (props) => <LegalBusinessName {...props} />,
      1: (props) => <BusinessAddress {...props} />,
      2: (props) => <BusinessContact {...props} />,
      3: (props) => <BusinessService {...props} />,
      4: (props) => <BusinessType {...props} />,
      5: (props) => <BusinessSsn {...props} />,
      6: (props) => <BusinessRelation {...props} />,
      7: (props) => <BusinessLinks {...props} />,
    },
    validation: {
      0: "businessName",
      1: "businessAddress",
      2: "businessContact",
      3: "businessService",
      4: "businessType",
      5: "businessSsn",
      6: "businessRelation",
    },
  },
  {
    key: "bank-account",
    label: "Link Bank Account",
    children: {
      0: (props) => <BusinessWalletInstrument {...props} />,
    },
  },
  {
    key: "id-verification",
    label: "ID Verification",
    children: {
      0: (props) => <PersonaVerification {...props} />,
      1: (props) => <ComplianceResult {...props} />,
    },
  },
];

const getCurrentStep = (userFields, accountFields, account) => {
  const isFreelancer =
    accountFields?.compliance_answer?.business_structure === BusinessStructureEnum.FREELANCE ||
    accountFields?.compliance_answer?.business_structure === BusinessStructureEnum.SOLE_PROPRIETOR;

  const hasEin = !account?.ein?.includes("-") && !account?.ein?.includes("_") && account?.ein?.length > 0;

  const hasSsn = !account?.ssn?.includes("-") && !account?.ssn?.includes("_") && account?.ssn?.length > 0;

  // If the user has already submitted their application, we want to show them the result screen
  if (!complianceNotSubmitted(account)) {
    return { step: 3, child: 1 };
  }

  // If the user needs to acknowledge they're not a risky business
  if (!userFields?.is_not_shady) {
    return { step: 0, child: 0 };
  }

  // If the user hasn't provided their legal name
  if (!userFields?.compliance_answer?.first_name || !userFields?.compliance_answer?.last_name) {
    return { step: 0, child: 1 };
  }

  // If the user hasn't provided their date of birth
  if (!userFields?.compliance_answer?.date_of_birth) {
    return { step: 0, child: 2 };
  }

  // If the user hasn't provided their personal address
  if (
    !userFields?.compliance_answer?.address_1 ||
    !userFields?.compliance_answer?.city ||
    !userFields?.compliance_answer?.state ||
    !userFields?.compliance_answer?.zip_code
  ) {
    return { step: 0, child: 3 };
  }

  // If the user hasn't provided their business name
  if (!accountFields?.legal_name || !accountFields?.public_name) {
    return { step: 1, child: 0 };
  }

  // If the user hasn't provided their business address
  if (
    !accountFields?.legal_address1 ||
    !accountFields?.legal_city ||
    !accountFields?.legal_state ||
    !accountFields?.legal_zip_code
  ) {
    return { step: 1, child: 1 };
  }

  // If the user hasn't provided their business contact info
  if (!accountFields?.legal_phone || !accountFields?.legal_email) {
    return { step: 1, child: 2 };
  }

  // If the user hasn't provided their business service type
  if (accountFields?.service_type === "x" || !accountFields?.customer_type || !accountFields?.description) {
    return { step: 1, child: 3 };
  }

  // If the user hasn't provided their business structure
  // state of incorporation, or the appropriate SSN or EIN
  if (
    !accountFields?.compliance_answer?.business_structure ||
    (isFreelancer && !hasSsn) ||
    (!isFreelancer && !hasEin) ||
    !accountFields?.state_incorporation
  ) {
    // means the account already pass business info
    if (accountFields?.relationship_to_business) {
      // If user doesn't have a bank account
      if (!account?.wallet?.payout_wallet_instrument_id) {
        return { step: 2, child: 0 };
      }

      // id verification take precedence over bank account
      if (!account?.persona_id_completed_at) {
        return { step: 3, child: 0 };
      }
    }

    return { step: 1, child: 4 };
  }

  // If the user hasn't provide the last 4 of their SSN
  if (!accountFields?.last_four_ssn) {
    return { step: 1, child: 5 };
  }

  // If the user hasn't provided their relationship to the business
  if (!accountFields?.relationship_to_business) {
    return { step: 1, child: 6 };
  }

  if (!account?.wallet?.payout_wallet_instrument_id) {
    return { step: 2, child: 0 };
  }

  // id verification take precedence over bank account
  if (!account?.persona_id_completed_at) {
    return { step: 3, child: 0 };
  }

  return { step: 0, child: 0 };
};

export default NiceModal.create(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const modal = useModal();
  const [isVerifyingIdentity, setIsVerifyingIdentity] = useState(false);
  const { walletInstrumentId } = useAppSelector(selectWallet);
  const queryClient = useQueryClient();
  const { account } = useAccount();

  const [currentStep, setCurrentStep] = useState(0);
  const [currentChild, setCurrentChild] = useState(0);

  const {
    fields: userFields,
    user,
    isLoading: userIsUpdating,
  } = useUserUpdater({
    quiet: true,
    validate: steps?.[currentStep]?.validation?.[currentChild] ?? "",
    onSuccess: () => {
      const timer = setTimeout(() => {
        handleNext();
        clearTimeout(timer);
      }, 300);
    },
    context: "compliance",
  });

  const { fields: accountFields, isLoading: accountIsUpdating } = useAccountUpdater({
    quiet: true,
    validate: steps?.[currentStep]?.validation?.[currentChild] ?? "",
    onSuccess: (response) => {
      if (steps[currentStep].key === "business-info" && currentChild === 4 && response?.last_four_ssn) {
        setCurrentChild(6);
      } else {
        handleNext();
      }
    },
  });

  const handleNext = () => {
    const thisStep = steps?.[currentStep];
    const childrenCount = Object.keys(thisStep.children).length;

    if (currentChild < childrenCount - 1) {
      setCurrentChild(currentChild + 1);
    } else if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
      setCurrentChild(0);
    }
  };

  const handlePrev = () => {
    if (currentChild > 0) {
      setCurrentChild(currentChild - 1);
    } else if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      const prevStepChildrenCount = Object.keys(steps[currentStep - 1].children).length;
      setCurrentChild(prevStepChildrenCount - 1);
    }
  };

  const { mutate: verifyIdentity } = useMutation((inquiryId: string) => verifyUserInquiry(inquiryId), {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.account.ACCOUNT_INFO]);
      queryClient.invalidateQueries([Query.user.USER_INFO]);
      const timer = setTimeout(() => {
        clearTimeout(timer);
        setIsVerifyingIdentity(false);
        handleNext();
      }, 1000);
    },
    onError: () => {
      setIsVerifyingIdentity(false);
    },
  });

  const isPartnershipSignUp = !!account?.partnership?.routing_number && !!account?.partnership?.name;

  const newPaymentMethodFormik = useAddNewWalletInstrument({
    wallet_id: account?.wallet?.id as string,
    defaultPaymentType: PaymentMethodTypeEnum.BANK_ACCOUNT,
    successCallback: (res) => {
      if (res?.wallet_instrument_id) {
        setSelectedWalletInstrumentId(res.wallet_instrument_id);
      }
    },
    supportAddRawBankInfo: isPartnershipSignUp,
    initialValues: {
      routing_number: account?.partnership?.routing_number,
    },
  });

  const walletInstruments = !account?.wallet
    ? []
    : formatWalletInstruments(account?.wallet?.wallet_instruments)
        .filter((instrument) => instrument?.type === PaymentMethodTypeEnum.BANK_ACCOUNT)
        .filter(
          (instrument) =>
            !account?.partnership?.routing_number ||
            // Typescript doesn't know which version of FlattenedWalletInstrument to use
            // @ts-ignore
            instrument?.routing_number === account?.partnership?.routing_number,
        );

  const [selectedWalletInstrumentId, setSelectedWalletInstrumentId] = useState<AllowedPaymentMethodSelection>(
    walletInstruments?.[0]?.id || PaymentMethodTypeEnum.NEW,
  );

  const { mutate: setPayoutWalletInstrument, isLoading: isPayoutAccountLoading } = useMutation(
    (values: any) =>
      updateWallet(values.walletId, {
        payout_wallet_instrument_id: values.payoutWalletInstrumentId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([Query.account.ACCOUNT_INFO]);
        handleNext();
      },
    },
  );

  const initiatePersonaVerification = async () => {
    setIsVerifyingIdentity(true);
    // https://docs.withpersona.com/docs/troubleshooting-common-issues-embedded
    // https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading#with-external-libraries
    const Persona = (await import("persona")).default;

    const client = new Persona.Client({
      ...(account?.persona_inquiry_id
        ? { inquiryId: account?.persona_inquiry_id }
        : { templateId: "itmpl_jZs4WCiK8ha3ssZ2WcoDyVS1" }),
      referenceId: account?.id,
      environment: process.env.ENV_NAME === "production" ? "production" : "sandbox",
      onReady: () => client.open(),
      onComplete: ({ inquiryId }) => {
        verifyIdentity(inquiryId);
      },
      onCancel: () => {
        setIsVerifyingIdentity(false);
      },
      onError: () => {
        setIsVerifyingIdentity(false);
      },
    });
  };

  useEffect(() => {
    // means a plaid wallet instrument just got created
    // some wallet instruments might not be qualified for payout
    if (walletInstruments.length > 0 && walletInstrumentId) {
      setSelectedWalletInstrumentId(walletInstrumentId);
    }
  }, [walletInstrumentId]);

  useEffect(() => {
    if (user?.compliance_answer?.date_of_birth && complianceNotSubmitted(account)) {
      const { step, child } = getCurrentStep(
        {
          ...userFields.values,
          // temporary hack to put the user into the correct business info step
          is_not_shady: true,
        },
        accountFields.values,
        account,
      );

      setCurrentStep(step);
      setCurrentChild(child);
    }
  }, [account?.compliance_status]);

  const isFinalStep =
    currentStep === steps.length - 1 && currentChild === Object.keys(steps[steps.length - 1].children).length - 1;

  const disableNext =
    (currentStep === 0 && currentChild === 0 && userFields.values.is_not_shady === false) ||
    (selectedWalletInstrumentId === PaymentMethodTypeEnum.NEW && currentStep === 2 && currentChild === 0);

  const disableBack = (currentStep === 0 && currentChild === 0) || currentStep === 3;

  return (
    <Dialog
      modal={modal}
      maxWidth="md"
      fullScreen={isMobile}
      title="Enable Direct Deposits"
      titleMode="dark"
      preventCloseOn={["backdropClick", "escapeKeyDown"]}
      onExited={() => {
        setCurrentStep(0);
        setCurrentChild(0);
      }}
    >
      {isFinalStep && Boolean(account?.compliance_status) ? (
        steps[currentStep].children[currentChild]({
          user,
          account,
        })
      ) : (
        <DialogContent sx={{ position: "relative", minHeight: "55vh" }}>
          <InlineContainer
            sx={{
              alignItems: "start",
              justifyContent: "start",
              padding: { xs: "1rem 0", md: "2rem 1.5rem" },
              gap: "3rem",
            }}
          >
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Stepper activeStep={currentStep === 0 && currentChild === 0 ? -1 : currentStep} orientation="vertical">
                {steps.map((step) => (
                  <Step
                    key={step.label}
                    sx={{
                      "& .MuiStepIcon-root.Mui-active, & .MuiStepIcon-root.Mui-completed": {
                        color: "primary.main",
                      },
                    }}
                  >
                    <StepLabel sx={{ whiteSpace: "nowrap" }}>{step.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box sx={{ width: { xs: "100%", lg: "65%" } }}>
              {steps?.[currentStep]?.children?.[currentChild]({
                formik: currentStep === 0 ? userFields : accountFields,
                user,
                account,
                newPaymentMethodFormik,
                walletInstruments,
                selectedWalletInstrumentId,
                setSelectedWalletInstrumentId,
              })}
            </Box>
          </InlineContainer>
        </DialogContent>
      )}
      <DialogActions>
        <InlineContainer justifyContent="space-between" width="100%">
          {disableBack ? (
            <Box />
          ) : (
            <NeutralButton onClick={handlePrev} startIcon={<ChevronLeftRounded />}>
              Back
            </NeutralButton>
          )}
          <AffirmativeButton
            onClick={async () => {
              if (steps[currentStep].key === "personal-info") {
                userFields.handleSubmit();
              } else if (steps[currentStep].key === "business-info") {
                accountFields.handleSubmit();
              } else if (steps[currentStep].key === "bank-account" && currentChild === 0) {
                setPayoutWalletInstrument({
                  walletId: account?.wallet?.id,
                  payoutWalletInstrumentId: selectedWalletInstrumentId,
                });
              } else if (steps[currentStep].key === "id-verification" && currentChild === 0) {
                initiatePersonaVerification();
              } else if (isFinalStep) {
                modal.hide();
              }
            }}
            sx={{ minWidth: 132 }}
            loading={userIsUpdating || accountIsUpdating || isPayoutAccountLoading || isVerifyingIdentity}
            disabled={disableNext}
            endIcon={isFinalStep ? null : <ChevronRightRounded />}
          >
            {isFinalStep ? "Done" : "Continue"}
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
