import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Typography from "@mui/material/Typography";
import InlineContainer from "@/components/InlineContainer";
import getFieldErrors from "@/utils/getFieldErrors";
import { useFormik } from "formik";
import { useTheme, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import SystemFeedback from "@/components/SystemFeedback";
import useUser from "@/features/auth/useUser";
import NeutralButton from "@/components/buttons/NeutralButton";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { initApi, routes } from "@/config/api";
import PasswordField from "@/components/PasswordField";
import useMessage from "@/hooks/useMessage";
import { HttpStatus } from "@/types";

export default NiceModal.create(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const modal = useModal();
  const { user } = useUser();
  const [message, setMessage] = useMessage();

  const initialValues = {
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors: any = {};
      if (!values.password) {
        errors.password = "Password is required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const response: any = await initApi().post(routes.user.login, {
        ...(user?.email ? { email: user?.email } : user?.cell_phone ? { cell_phone: user?.cell_phone } : {}),
        password: values.password,
      });

      if (response?.status === HttpStatus.SUCCESS) {
        modal.resolve();
        modal.hide();
      } else {
        // Assume login failed
        setMessage({
          message: "Incorrect password. Please try again.",
          type: "error",
        });
      }
    },
  });

  const handleCloseModal = () => {
    modal.hide();
  };

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="common.black"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Verify Password
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" sx={{ color: "primary.dark" }} />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "1.25rem" }} dividers>
        <form onSubmit={formik.handleSubmit} id="verify-password-form">
          <SystemFeedback {...message} />
          <Typography variant="body1" marginBottom={"1rem"}>
            For your security, please enter your password in the field below.
          </Typography>
          <PasswordField
            placeholder="Current Password"
            fullWidth
            autoComplete="current-password"
            sx={{ marginBottom: "0.5rem" }}
            {...formik.getFieldProps("password")}
            {...getFieldErrors("password", formik)}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton
            disableElevation
            onClick={() => {
              modal.reject();
              modal.hide();
            }}
          >
            Cancel
          </NeutralButton>
          <AffirmativeButton type="submit" form="verify-password-form">
            Verify
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
