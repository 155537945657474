import { LoginValues } from "@/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { initApi, routes } from "@/config/api";
import { GetServerSidePropsContext } from "next";

export const login = async (credentials: LoginValues) =>
  await initApi()
    .post(routes.user.login, credentials)
    .then(({ data }) => data?.data);

export const getInvite = async (roleId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.user.invite(roleId))
    .then(({ data }) => data?.data);

export const getUser = async (req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.user.base)
    .then(({ data }) => data?.data);

export const initiatePasswordReset = async (values, redirect) =>
  await initApi().post(routes.user.reset.passwordNotification, {
    ...values,
    ...(Boolean(redirect) ? { redirect } : {}),
  });

export const resetPassword = async (values) =>
  await initApi().post(routes.user.reset.password, {
    code: values.code,
    password: values.password,
  });

export const logout = async (req?: GetServerSidePropsContext["req"]) => await initApi(req).post(routes.user.logout);

export const logoutThunk = createAsyncThunk("auth/logout", async () => {
  try {
    return await initApi().post(routes.user.logout);
  } catch (error: any) {
    return error?.response?.data;
  }
});

export const sendOtc = async (values) =>
  await initApi().post(routes.user.otc, {
    email: (values?.email ?? "").toLowerCase(),
    cell_phone: values?.cell_phone,
  });

export const resendPhoneVerification = async (cell_phone: string) =>
  await initApi().post(routes.user.resend, {
    cell_phone,
  });

export const resendEmailVerification = async (email: string) =>
  await initApi().post(routes.user.resend, {
    email,
  });

export const verifyPhone = async (values) =>
  await initApi().post(routes.user.verify, {
    user_id: values?.user_id,
    code: values?.code,
  });

export const enrollOtp = async ({ user_id }) =>
  await initApi()
    .post(routes.user.otp.enroll, {
      user_id,
    })
    .then(({ data }) => data?.data);

export const unenrollOtp = async ({ user_id }) =>
  await initApi().post(routes.user.otp.unenroll, {
    user_id,
  });

export const verifyOtp = async ({ user_id, code }) =>
  await initApi().post(routes.user.otp.verify, {
    user_id,
    code,
  });

export const verifyEmail = async (values) =>
  await initApi().post(routes.user.verify, {
    user_id: values?.user_id,
    code: values?.code,
  });

export const connectGoogle = async ({ google_auth_token }) =>
  await initApi().post(routes.user.googleConnect, {
    google_auth_token,
  });

export const disconnectGoogle = async () => await initApi().post(routes.user.googleDisconnect);
