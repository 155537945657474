const getInitialsFromName = (name) =>
  name
    ? name
        ?.match(/(\b\S)?/g)
        ?.join("")
        ?.match(/(^\S|\S$)?/g)
        ?.join("")
        ?.toUpperCase()
    : "";

export default getInitialsFromName;
