import Box from "@mui/material/Box";
import { Dialog, DialogContent, DialogActions } from "@/components/Dialog";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Typography from "@mui/material/Typography";
import InlineContainer from "@/components/InlineContainer";
import { matchSorter } from "match-sorter";
import { useQuery } from "@tanstack/react-query";
import { useTheme, useMediaQuery } from "@mui/material";
import { fetchAllCustomers } from "@/features/customer-management/customer-management-queries";
import { useState, Fragment } from "react";
import PlusIcon from "@mui/icons-material/AddRounded";
import TextField from "@/components/TextField";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { styled } from "@mui/material";
import FormattedPhone from "@/components/FormattedPhone";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Query, DialogKeys, AccountStatuses, InvoiceCustomer } from "@/types";
import useAppSelector from "@/hooks/useAppSelector";
import { selectInvoice } from "@/features/invoice-management/invoice-management-selectors";
import Checkbox from "@mui/material/Checkbox";
import NeutralButton from "@/components/buttons/NeutralButton";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { handleAddCustomerToInvoice } from "@/utils/invoices";
import { useRouter } from "next/router";
import { resetInvoice } from "@/features/invoice-management/invoice-management-reducers";
import TestId, { dynamicTestId } from "@/constants/testIds";
import { useSnackbar } from "notistack";

export const CustomerGroup = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.palette.grey[200]};
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(1)};
`,
);

const CustomerCard = styled(InlineContainer)(
  ({ theme }) => `
  background-color: ${theme.palette.common.white};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.common.white};
  cursor: pointer;
  padding: 0.75rem 1rem;
  width: 75%;

  &:hover {
    border: 1px solid ${theme.palette.primary.main};
  }
  
  &.selected {
    border: 2px solid ${theme.palette.primary.main};
  }

  .MuiTypography-body1 {
    color: ${theme.palette.common.black};
    font-weight: 500;
  }

  .MuiTypography-body2 {
    color: ${theme.palette.grey[700]};
  }
`,
);

const BatchInvoiceCard = styled(InlineContainer)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .75rem;
  background-color: ${theme.palette.common.white};
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.common.white};
  padding: 0.75rem 1rem;
  width: 75%;
`,
);

const useLimitedArrayState = (
  initialState: any[],
  arrayLimit: number,
  key: string,
): [any[], (newState: any[]) => void] => {
  const [state, setState] = useState(initialState);
  const { enqueueSnackbar } = useSnackbar();

  const updateState = (newState) => {
    if (newState.length > arrayLimit) {
      enqueueSnackbar(`You can only add ${arrayLimit} ${arrayLimit === 1 ? key : `${key}s`} to this list.`, {
        variant: "error",
      });
    } else {
      setState(newState);
    }
  };

  return [state, updateState];
};

export default NiceModal.create<{ prevSelectedCustomers: InvoiceCustomer[] }>(({ prevSelectedCustomers }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [search, setSearch] = useState("");
  const modal = useModal();
  const selectedInvoice = useAppSelector(selectInvoice);
  const maxCustomerLimit = selectedInvoice?.id ? 1 : 50;
  const [selectedCustomers, setSelectedCustomers] = useLimitedArrayState(
    prevSelectedCustomers ?? [],
    maxCustomerLimit,
    "customer",
  );
  const router = useRouter();

  const { data } = useQuery<{ results?: InvoiceCustomer[] }>(
    [Query.account.INVOICE_BUILDER_ALL_CUSTOMERS],
    () => fetchAllCustomers(),
    {
      keepPreviousData: true,
    },
  );
  const customers = data?.results ?? [];

  const activeCustomers = customers.filter((c) => c.status === AccountStatuses.ACTIVE);

  const hasActiveCustomers = activeCustomers?.length > 0;

  const searchedCustomers: any = matchSorter(activeCustomers ?? [], search, {
    keys: ["name", "phone", "cell_phone", "email"],
  });

  const handleCloseModal = () => {
    modal.hide();
  };

  // Sort alphabetically by name and split into groups by first letter.
  const sortedCustomers = groupBy(
    sortBy(searchedCustomers ?? [], ["name"]),
    (customer) => Array.from(customer?.name)[0],
  );
  // Got rid of lodash, we were comparing by reference instead of by value which was not performing includes
  // correctly and was forcing the user to click a customer twice to remove them from the array
  const handleSelectCustomer = (customer: InvoiceCustomer) => {
    if (selectedCustomers?.some((c) => c?.id === customer?.id)) {
      return setSelectedCustomers(selectedCustomers.filter((i) => i?.id !== customer?.id));
    }

    setSelectedCustomers([...selectedCustomers, customer]);
  };

  const handleAddCustomer = (customers: InvoiceCustomer[]) => {
    handleAddCustomerToInvoice(dispatch, customers, selectedInvoice);
    handleCloseModal();
  };

  const handleSetCustomerList = () => {
    handleCloseModal();
    NiceModal.show(DialogKeys.INVOICE_CUSTOMER_OPTIONS, {
      prevSelectedCustomers: selectedCustomers,
    }).catch(() => {
      modal.show();
    });
  };

  const handleReRoute = () => {
    modal.hide();
    dispatch(resetInvoice());
    router.push("/invoices/create");
  };

  const handleAddNewCustomer = () => {
    modal.hide();
    NiceModal.show<InvoiceCustomer>(DialogKeys.CUSTOMER, {
      shouldShowBulkImport: false,
    }).then((newCustomer) => {
      const updatedList = [...selectedCustomers].concat(newCustomer);
      modal.show({
        prevSelectedCustomers: updatedList,
      });
      setSelectedCustomers(updatedList);
    });
  };

  const CreateNewButton = hasActiveCustomers ? NeutralButton : AffirmativeButton;

  return (
    <Dialog modal={modal} title="Customers">
      {!hasActiveCustomers ? (
        <DialogContent sx={{ paddingTop: "3rem" }} dividers>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              textAlign: "center",
              ...(isMobile ? {} : { marginBottom: "2.5rem" }),
            }}
          >
            Looks like you don't have any customers saved in the system. To create a new customer,{" "}
            <strong>{isMobile ? "tap" : "click"} the "+ Create New" button</strong>.
          </Typography>
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            padding: { xs: "0 0 5rem 0", md: " 0rem 1.25rem  " },
          }}
          dividers
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              alignItems: "center",
              backgroundColor: "grey.200",
              textAlign: "center",
              gap: ".5rem",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                backgroundColor: "grey.500",
                color: "white",
                padding: "0.5rem 1.5rem",
                textAlign: "left",
                width: "100%",
              }}
            >
              Customer List
            </Typography>
            <TextField
              name="customer-search"
              placeholder="Search"
              sx={{
                backgroundColor: "white",
                borderRadius: "0.5rem",
                margin: "1rem",
                width: "75%",
              }}
              onChange={(event) => setSearch(event?.target?.value)}
            />
            {selectedCustomers?.length > 1 && (
              <BatchInvoiceCard marginBottom="1rem">
                <InlineContainer gap=".5rem">
                  <Typography variant="body1" fontWeight={600}>
                    Batch invoice Recipients
                  </Typography>
                  <Typography variant="body1" color="primary" fontWeight={600}>
                    ({selectedCustomers?.length}/{maxCustomerLimit})
                  </Typography>
                </InlineContainer>
                <Typography variant="body2" textAlign="start">
                  Selecting multiple customers creates a Batch Invoice. Each customer receives their own separate
                  invoice and is billed individually.
                </Typography>
              </BatchInvoiceCard>
            )}
            {selectedInvoice?.id && (
              <BatchInvoiceCard marginBottom="1rem">
                <Typography variant="body2" textAlign="start">
                  Multiple recipients cannot be added to a draft. If you'd like to create a Batch Invoice,{" "}
                  <strong
                    style={{
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={handleReRoute}
                  >
                    create a new invoice.
                  </strong>
                </Typography>
              </BatchInvoiceCard>
            )}
          </Box>
          <Box>
            {Object.keys(sortedCustomers || {}).map((letter) => (
              <Fragment key={`invoice-list-${letter}`}>
                <Box padding="0.5rem 1.5rem" fontWeight={700}>
                  {letter}
                </Box>
                <CustomerGroup>
                  {sortedCustomers?.[letter]?.map((customer, index) => {
                    const isSelected = selectedCustomers
                      ?.map((selectedCustomer) => selectedCustomer?.id)
                      ?.includes(customer?.id);
                    const shouldDisableCustomerCard =
                      selectedCustomers?.length === 1 && !!selectedInvoice?.id && !isSelected;

                    return (
                      <CustomerCard
                        key={`invoice-customer-${customer?.id}`}
                        onClick={() => {
                          if (!shouldDisableCustomerCard) {
                            handleSelectCustomer(customer);
                          }
                        }}
                        className={isSelected ? "selected" : ""}
                        sx={{
                          cursor: shouldDisableCustomerCard ? "not-allowed" : "pointer",
                        }}
                      >
                        <Box>
                          <Checkbox
                            size="small"
                            sx={{
                              position: "relative",
                              left: "-.5rem",
                            }}
                            checked={isSelected}
                            disabled={shouldDisableCustomerCard}
                            data-testid={dynamicTestId(TestId.Dialog.InvoiceCustomerCheckbox, index)}
                          />
                        </Box>
                        <Box>
                          <Typography variant="body1">{customer?.name}</Typography>
                          {customer?.email && <Typography variant="body2">{customer?.email}</Typography>}
                          {customer?.cell_phone && (
                            <Typography variant="body2">
                              <FormattedPhone value={customer?.cell_phone} />
                            </Typography>
                          )}
                        </Box>
                      </CustomerCard>
                    );
                  })}
                </CustomerGroup>
              </Fragment>
            ))}
          </Box>
        </DialogContent>
      )}
      <DialogActions
        sx={{
          padding: "0.75rem 1.25rem",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {hasActiveCustomers && (
          <AffirmativeButton
            disabled={selectedCustomers?.length < 1 || selectedCustomers?.length > maxCustomerLimit}
            sx={{ width: { xs: "100%", md: 350 } }}
            onClick={() =>
              selectedCustomers.length === 1 ? handleAddCustomer(selectedCustomers) : handleSetCustomerList()
            }
            data-testid={TestId.Dialog.InvoiceAddCustomer}
          >
            Continue
          </AffirmativeButton>
        )}
        <CreateNewButton
          onClick={handleAddNewCustomer}
          startIcon={<PlusIcon fontSize="small" />}
          sx={{ width: { xs: "100%", md: 350 } }}
        >
          Create New
        </CreateNewButton>
      </DialogActions>
    </Dialog>
  );
});
