import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import NeutralButton from "@/components/buttons/NeutralButton";
import ErrorButton from "@/components/buttons/ErrorButton";
import { Invoice } from "@/types";
import { padWithZeros } from "@/utils/stringUtils";

export default NiceModal.create<{ invoice: Invoice }>(({ invoice }) => {
  const modal = useModal();

  const handleCloseModal = () => {
    modal.hide();
  };

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.dark",
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Cancel this invoice?
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: "grey.500",
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          Are you sure you want to cancel Invoice #{padWithZeros(invoice?.invoice_number || 0)}? This cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton
            disableElevation
            onClick={() => {
              modal.reject();
              handleCloseModal();
            }}
          >
            Close
          </NeutralButton>
          <ErrorButton
            onClick={() => {
              modal.resolve();
              handleCloseModal();
            }}
          >
            Cancel Invoice
          </ErrorButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
