import { NumericFormat, NumericFormatProps } from "react-number-format";

const FormattedNumber = (
  props: NumericFormatProps & {
    hideDecimals?: boolean | null;
  },
) => {
  const decimals = {
    decimalScale: 2,
    fixedDecimalScale: true,
  };

  const { hideDecimals, ...rest } = props;

  return (
    <NumericFormat
      thousandSeparator=","
      decimalSeparator="."
      {...(hideDecimals ? {} : decimals)}
      displayType="text"
      {...rest}
      {...(rest?.allowNegative && rest?.prefix?.includes("-")
        ? {
            prefix: rest?.prefix?.replace("-", ""),
          }
        : {})}
      type="text"
    />
  );
};

export default FormattedNumber;
