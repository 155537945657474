import * as yup from "yup";
import { email_or_phone } from "@/features/auth/auth-validation";
import "yup-phone";
import { BusinessStructureEnum } from "@/types";

export const createAccountValidationSchema = yup.object().shape({
  businessName: yup.string().required("Please enter your business name"),
  businessType: yup.string().required("Please select your business type"),
});

export const inviteMemberValidationSchema = yup.object().shape({
  email_or_phone,
  // title: yup.string().required("Type is required"),
});

export const accountSettingsValidationSchema = yup.object().shape({
  legal_name: yup.string().required("Legal business name is required").nullable(),
  legal_email: yup.string().email("Please enter a valid email address").required("Legal business name is required"),
  legal_phone: yup.string().phone("US", true, "Please enter a valid phone number"),
  legal_address1: yup.string().required("Business address is required").nullable(),
  legal_city: yup.string().required("City is required").nullable(),
  legal_state: yup.string().required("State is required").nullable(),
  legal_zip_code: yup.string().required("Zip code is required").nullable(),
});

export const businessNameValidationSchema = yup.object().shape({
  legal_name: yup.string().required("Legal Business Name required").nullable(),
  public_name: yup.string().required("Public Business Name required").nullable(),
});

export const businessAddressValidationSchema = yup.object().shape({
  legal_address1: yup.string().required("Business address is required").nullable(),
  legal_city: yup.string().required("City Required").nullable(),
  legal_state: yup.string().required("State Required").nullable(),
  legal_zip_code: yup.string().required("Zip Required").nullable(),
});

export const businessContactValidationSchema = yup.object().shape({
  legal_email: yup.string().email("Please enter a valid email"),
  legal_phone: yup.string().phone("US", true, "Please enter a valid phone number").required("Phone number is required"),
});

export const businessServiceValidationSchema = yup.object().shape({
  service_type: yup.string().required("Service type is required").nullable(),
  customer_type: yup.string().required("Customer type is required").nullable(),
  description: yup.string().required("Description is required").nullable(),
});

export const businessTypeValidationSchema = yup.object().shape({
  business_structure: yup.string().required("Business Type is required").nullable(),
  ssn: yup.string().when("business_structure", {
    is: (val) => val === BusinessStructureEnum.FREELANCE || val === BusinessStructureEnum.SOLE_PROPRIETOR,
    then: yup
      .string()
      .required("SSN is required")
      .min(11, "SSN must be 9 digits")
      .test("ssnCheck", "Please enter a valid SSN", (value) => {
        return Boolean(value) && !value?.includes("_");
      }),
  }),
  ein: yup.string().when(["business_structure", "state_incorporation"], {
    is: (structure) =>
      structure !== BusinessStructureEnum.FREELANCE && structure !== BusinessStructureEnum.SOLE_PROPRIETOR,
    then: yup
      .string()
      .required("EIN is required ")
      .min(9, "EIN must be 9 digits")
      .test("einCheck", "Please enter a valid EIN", (value) => {
        return Boolean(value) && !value?.includes("_");
      }),
  }),
  state_incorporation: yup.string().when("business_structure", {
    is: (structure) =>
      structure !== BusinessStructureEnum.FREELANCE && structure !== BusinessStructureEnum.SOLE_PROPRIETOR,
    then: yup
      .string()
      .required("State Incorporation is required")
      .test("ssnCheck", "State of Incorporation is required", (value) => {
        return value !== "x";
      }),
  }),
});

export const businessSsnValidationSchema = yup.object().shape({
  last_four_ssn: yup
    .string()
    .required("Last four of your SSN is required")
    .test("ssnCheck", "SSN is required", (value) => {
      return Boolean(value) && !value?.includes("_");
    })
    .min(4, "Minimum of 4 digits required")
    .max(4, "Maximum of 4 digits required")
    .nullable(),
});

export const businessRelationValidationSchema = yup.object().shape({
  relationship_to_business: yup.string().required("Relationship to Business is required").nullable(),
});
