export const capitalizeFirstLetter = (value: string | undefined) => {
  if (!value) return "";
  const newString = value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();
  return newString;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  return emailRegex.test(email);
};

export const padWithZeros = (value: number): string => `${value}`.padStart(7, "0");

export const unformattedAmount = (amount: string) => {
  return parseFloat((amount === "" ? "0" : amount).replaceAll(",", ""));
};
