import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dialog, DialogContent, DialogActions } from "@/components/Dialog";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import NeutralButton from "@/components/buttons/NeutralButton";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import ErrorButton from "@/components/buttons/ErrorButton";
import TestId from "@/constants/testIds";
import { ReactNode } from "react";

export default NiceModal.create<{
  title?: ReactNode;
  content?: ReactNode;
  note?: ReactNode;
  isLoading?: boolean;
  confirmButton?: any;
  cancelButton?: any;
  isImportant?: boolean;
}>(
  ({
    title = "",
    content = "",
    note = "",
    isLoading = false,
    cancelButton = "Cancel",
    confirmButton = "Confirm",
    isImportant = false,
  }) => {
    const modal = useModal();

    const handleCloseModal = () => {
      modal.hide();
    };

    const ConfirmButton = isImportant ? ErrorButton : AffirmativeButton;

    return (
      <Dialog modal={modal} title={title} fullScreen={false}>
        <DialogContent>
          {typeof content !== "string" ? (
            content
          ) : (
            <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: 1.5, fontSize: "16px" }}>
              {content}
            </Typography>
          )}
          {typeof note !== "string" ? (
            note
          ) : note ? (
            <Typography variant="body2" sx={{ fontWeight: 500, marginBottom: 1.5 }}>
              {note}
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <InlineContainer
            width="100%"
            flexWrap="wrap"
            sx={{
              justifyContent: { xs: "center", sm: "space-between" },
            }}
          >
            <NeutralButton
              type="button"
              onClick={() => {
                modal.reject();
                handleCloseModal();
              }}
              disableElevation
              sx={{ margin: "0.25rem 0.5rem" }}
            >
              {cancelButton}
            </NeutralButton>
            <ConfirmButton
              type="submit"
              data-testid={TestId.Dialog.ConfirmButton}
              sx={{
                minWidth: 120,
                margin: "0.25rem 0.5rem",
              }}
              onClick={() => {
                modal.resolve();
                modal.hide();
              }}
              loading={isLoading}
            >
              {confirmButton}
            </ConfirmButton>
          </InlineContainer>
        </DialogActions>
      </Dialog>
    );
  },
);
