import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dialog, DialogContent, DialogActions } from "@/components/Dialog";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import NeutralButton from "@/components/buttons/NeutralButton";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { ChangeEvent, useState } from "react";
import {
  BankAccountType,
  FlattenedWalletInstrument,
  ScheduledBusinessPayments,
  Query,
  PaymentsByBusiness,
  AllowedPaymentMethodSelection,
  PaymentMethodTypeEnum,
  ScheduledPayment,
  ScheduledPaymentTypeEnum,
} from "@/types";
import { deleteWalletInstrument } from "@/features/wallet/wallet-queries";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getInstrumentLabel, formatWalletInstruments } from "@/utils/wallet";
import { useSnackbar } from "notistack";
import { formatDollar, formattedDate } from "@/utils/formatters";
import { useAddNewWalletInstrument } from "@/features/wallet/components/payment-method/AddWalletInstrument";
import useUser from "@/features/auth/useUser";
import AutopayIcon from "../../../../components/icons/AutopayIcon";
import { padWithZeros } from "@/utils/stringUtils";
import Link from "@/components/Link";
import Divider from "@mui/material/Divider";
import PaymentMethodList from "./PaymentMethodList";

const ScheduledInvoicePayment = ({ payment }: { payment: ScheduledPayment }) => {
  const { invoice_number, amount, payin_at, invoice_item_names, invoice_id } = payment;
  return (
    <>
      <InlineContainer
        justifyContent="space-between"
        alignItems="start"
        sx={{ margin: "1rem 0 0.25rem 0", width: "100%" }}
      >
        <Typography variant="body2" sx={{ flexBasis: "100%" }}>
          <Link href={`/pay/${invoice_id}`} target="_blank" sx={{ fontWeight: 600 }}>
            Invoice #{padWithZeros(invoice_number ?? 0)}
          </Link>
        </Typography>
        <Typography variant="body2" sx={{ flexBasis: "100%" }}>
          {invoice_item_names?.[0]}
        </Typography>
        <Typography variant="body2" sx={{ flexBasis: "100%", textAlign: "right" }}>
          {formatDollar(amount)}
        </Typography>
      </InlineContainer>
      <InlineContainer alignItems="start" sx={{ marginBottom: "0.75rem" }}>
        <Typography display="block" variant="subtext" sx={{ flexBasis: "100%" }}>
          {Boolean(payin_at) ? `Due ${formattedDate(payin_at)}` : null}
        </Typography>
        {Boolean(invoice_item_names?.length > 1) ? (
          <Typography variant="subtext" sx={{ flexBasis: "100%" }}>
            {` + ${invoice_item_names?.length - 1} more`}
          </Typography>
        ) : null}
        <Box sx={{ flexBasis: "100%" }} />
      </InlineContainer>
    </>
  );
};

const ScheduledPBLPayment = ({ payment: { id, amount, payin_at, type } }) => {
  return (
    <>
      <InlineContainer
        justifyContent="space-between"
        alignItems="start"
        sx={{ margin: "1rem 0 0.25rem 0", width: "100%" }}
      >
        <Typography variant="body2" sx={{ flexBasis: "100%" }}>
          <Link
            href={`/user/payments?payment_status[]=SCHEDULED&source=PAY_BY_LINK`}
            target="_blank"
            sx={{ fontWeight: 600 }}
          >
            {type === ScheduledPaymentTypeEnum.RECURRING_PBL ? "Recurring Pay-by Link" : "Pay-by Link"}
          </Link>
        </Typography>
        <Typography variant="body2" sx={{ flexBasis: "100%", textAlign: "right" }}>
          {formatDollar(amount)}
        </Typography>
      </InlineContainer>
      <InlineContainer alignItems="start" sx={{ marginBottom: "0.75rem" }}>
        <Typography display="block" variant="subtext" sx={{ flexBasis: "100%" }}>
          {type === ScheduledPaymentTypeEnum.RECURRING_PBL
            ? `Next payment on ${formattedDate(payin_at)}`
            : `Due ${formattedDate(payin_at)}`}
        </Typography>
        <Box sx={{ flexBasis: "100%" }} />
      </InlineContainer>
    </>
  );
};

const ScheduledPaymentsList = ({ payments }: { payments: PaymentsByBusiness }) => {
  return Object.entries(payments ?? {}).map(([id, business]) => {
    return (
      <Box key={`payments-for-${id}`}>
        <Typography variant="h6" sx={{ fontSize: "0.95rem", margin: "0.75rem 0" }}>
          {business?.business_name}{" "}
          {business?.isAutopay && (
            <>
              <AutopayIcon
                fontSize="small"
                sx={{
                  color: "custom.autopay",
                  position: "relative",
                  top: "0.4rem",
                  margin: "0 0.25rem 0 0.35rem",
                }}
              />
              <Box component="span" sx={{ color: "custom.autopay", fontWeight: 400 }}>
                Autopay
              </Box>
            </>
          )}
        </Typography>
        <Box
          sx={(theme) => ({
            border: "1px solid",
            borderColor: "grey.300",
            borderRadius: `${theme.shape.borderRadius}px`,
            padding: "0 1rem 0 1rem",
            marginTop: "0.5rem",
            width: "100%",
          })}
        >
          {business?.payments?.map((payment, index) => {
            return (
              <>
                {index !== 0 && <Divider />}
                {payment.type === ScheduledPaymentTypeEnum.PIL ? (
                  <ScheduledInvoicePayment payment={payment} key={`future-payment-invoice-${payment?.id}`} />
                ) : (
                  <ScheduledPBLPayment payment={payment} key={`future-payment-invoice-${payment?.id}`} />
                )}
              </>
            );
          })}
        </Box>
      </Box>
    );
  });
};

export default NiceModal.create<{
  futurePayments: ScheduledBusinessPayments;
  instrumentToDelete: FlattenedWalletInstrument;
}>(({ futurePayments, instrumentToDelete }) => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useUser();

  const walletInstruments = formatWalletInstruments(user?.wallet?.wallet_instruments).filter(
    (i) => i?.id !== instrumentToDelete?.id,
  );

  const hasFuturePayments = Object.keys(futurePayments?.paymentsByBusiness ?? {})?.length > 0;

  const [selectedWalletInstrumentId, setSelectedWalletInstrumentId] = useState<AllowedPaymentMethodSelection>("");

  const replacementMethod = walletInstruments?.find((i) => i?.id === selectedWalletInstrumentId);

  // This is the user's selection from the radio group.
  const [currentStep, setCurrentStep] = useState<"delete" | "select" | "replace" | "confirm" | null>(
    hasFuturePayments ? "select" : "delete",
  );

  const [approachSelected, setApproachSelected] = useState<string>("");

  const formik = useAddNewWalletInstrument({
    successCallback: (response) => {
      setSelectedWalletInstrumentId(response.wallet_instrument_id);
      queryClient.invalidateQueries([Query.account.ACCOUNT_INFO]);
      queryClient.invalidateQueries([Query.user.USER_INFO]);
      queryClient.invalidateQueries([Query.user.PIL_INVOICE]);
    },
    wallet_id: instrumentToDelete?.wallet_id,
    isResetForm: true,
    supportAddRawBankInfo: false,
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: {
      account_type: BankAccountType.PERSONAL,
    },
  });

  const deletePaymentMethod = useMutation(
    (instrumentId: string) => deleteWalletInstrument(instrumentId, selectedWalletInstrumentId),
    {
      onSuccess: () => {
        enqueueSnackbar("Payment method deleted.", {
          variant: "success",
        });
        queryClient.invalidateQueries([Query.user.PIL_INVOICE]);

        modal.resolve({ instrumentToDelete });
        modal.hide();
      },
      onError: (error: any) => {
        enqueueSnackbar(error?.response?.data?.message ?? "Error deleting payment method.", {
          variant: "error",
        });
        modal.reject();
        modal.hide();
      },
    },
  );

  const cancelButtonText =
    !hasFuturePayments || currentStep === "delete" || currentStep === "select" ? "Cancel" : "Back";

  const confirmButtonText = !hasFuturePayments || currentStep === "confirm" ? "Confirm" : "Next";

  const shouldDisableNext = () => {
    if (currentStep === "select") {
      return !approachSelected;
    }

    if (currentStep === "replace") {
      return !selectedWalletInstrumentId || selectedWalletInstrumentId === PaymentMethodTypeEnum.NEW;
    }

    return false;
  };

  return (
    <Dialog modal={modal} title="Delete Payment Method" fullScreen={false}>
      <DialogContent>
        {!hasFuturePayments && (
          <Typography variant="body2">
            Are you sure you want to delete <strong>{getInstrumentLabel(instrumentToDelete)}</strong>? This will not
            affect any processing payments.
          </Typography>
        )}

        {hasFuturePayments && currentStep === "select" && (
          <>
            <Typography variant="body2">
              The following payments were scheduled using <strong>{getInstrumentLabel(instrumentToDelete)}</strong>.
            </Typography>
            <ScheduledPaymentsList payments={futurePayments?.paymentsByBusiness} />
            <Typography variant="body2" sx={{ margin: "1rem 0" }}>
              Choose one of the following options:
            </Typography>

            <FormControl fullWidth>
              <RadioGroup
                aria-labelledby="cancel_or_replace"
                defaultValue={approachSelected}
                name="cancel_or_replace"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setApproachSelected(event.target.value);
                  setSelectedWalletInstrumentId("");
                }}
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <FormControlLabel
                  value="cancel"
                  control={<Radio size="small" />}
                  sx={{
                    backgroundColor: "#F7F9FB",
                    margin: 0,
                    padding: "1rem 0.5rem",
                  }}
                  label={
                    <>
                      <Typography variant="body2" fontWeight="bold">
                        Cancel all scheduled payments
                      </Typography>
                      <Typography variant="body2">This includes turning off any Autopay payments.</Typography>
                    </>
                  }
                />
                <FormControlLabel
                  value="replace"
                  control={<Radio size="small" />}
                  sx={{
                    backgroundColor: "#F7F9FB",
                    margin: 0,
                    padding: "0.5rem",
                    cursor: "pointer",
                  }}
                  label={
                    <Typography variant="body2" fontWeight="bold">
                      Replace payment method on scheduled payments
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </>
        )}

        {hasFuturePayments && currentStep === "replace" && (
          <>
            <Typography variant="body2">Select an alternative payment method or add a new one.</Typography>
            <Box padding="1rem">
              <PaymentMethodList
                formik={formik}
                walletInstruments={walletInstruments || []}
                isBackdoor={false}
                selectedWalletInstrumentId={selectedWalletInstrumentId}
                setSelectedWalletInstrumentId={setSelectedWalletInstrumentId}
                showProcessingFee={false}
                walletId={user?.wallet_id}
              />
            </Box>
          </>
        )}

        {hasFuturePayments && currentStep === "confirm" && (
          <>
            {approachSelected === "cancel" && (
              <Typography variant="body2">
                <strong>{getInstrumentLabel(instrumentToDelete)}</strong> will be deleted and the following scheduled
                payments will be cancelled. Click "confirm" to proceed.
              </Typography>
            )}

            {approachSelected === "replace" && (
              <>
                <Typography variant="body2" sx={{ margin: "0 0 1rem 0" }}>
                  <strong>{getInstrumentLabel(instrumentToDelete)}</strong> will be deleted.
                </Typography>
                <Typography variant="body2" sx={{ margin: "1rem 0" }}>
                  <strong>{getInstrumentLabel(replacementMethod as FlattenedWalletInstrument)}</strong> will now be used
                  for the following scheduled payments. Click "confirm" to proceed.
                </Typography>
              </>
            )}

            <ScheduledPaymentsList payments={futurePayments?.paymentsByBusiness} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton
            type="button"
            onClick={() => {
              if (!hasFuturePayments || currentStep === "delete" || currentStep === "select") {
                modal.reject();
                modal.hide();
              } else if (currentStep === "replace") {
                setCurrentStep("select");
              } else if (currentStep === "confirm" && approachSelected === "replace") {
                setCurrentStep("replace");
              } else if (currentStep === "confirm" && approachSelected === "cancel") {
                setCurrentStep("select");
              }
            }}
            sx={{ whiteSpace: "nowrap" }}
            disableElevation
          >
            {cancelButtonText}
          </NeutralButton>
          <AffirmativeButton
            type="submit"
            sx={{
              minWidth: 120,
              whiteSpace: "nowrap",
            }}
            disabled={shouldDisableNext()}
            onClick={() => {
              if (!hasFuturePayments || currentStep === "confirm") {
                deletePaymentMethod.mutate(instrumentToDelete.id);
              } else if (approachSelected === "cancel" && currentStep === "select") {
                setCurrentStep("confirm");
              } else if (approachSelected === "replace" && currentStep === "select") {
                setCurrentStep("replace");
              } else {
                setCurrentStep("confirm");
              }
            }}
          >
            {confirmButtonText}
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
