import { styled } from "@mui/material";
import BaseButton from "@/components/buttons/BaseButton";
import { ButtonProps } from "@mui/material/Button";
import { forwardRef } from "react";

const Button = styled(BaseButton)(
  ({ theme }) => `
  background-color: ${theme.palette.background.default};
  border-color: ${theme.palette.primary.dark};
  color: ${theme.palette.primary.dark};
  cursor: pointer;

  &:hover {
    background-color: ${theme.palette.background.default};
    border-color: ${theme.palette.primary.dark};
  }

  &.Mui-disabled {
    box-shadow: none;
  }
`,
);

const NeutralButton = forwardRef<HTMLButtonElement, ButtonProps>(({ children, type = "button", ...props }, ref) => {
  return (
    <Button type={type} color="primary" ref={ref} variant="outlined" {...props}>
      {children}
    </Button>
  );
});

export default NeutralButton;
