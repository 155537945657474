import { Message } from "@/hooks/useMessage";
import { AccountRoles, AuthValues, LoginValues } from "@/types";
import { identifyLogRocketUser } from "@/utils/logrocket";
import { OnboardingSteps, onboardingPaths } from "@/features/onboarding/steps";
import { formatPhone } from "@/utils/phone";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getUser, login, sendOtc } from "./auth-queries";
import { initialValidationSchema, otcValidationSchema } from "./auth-validation";
import { getPhoneOrEmail } from "./useLogin";
import useAppDispatch from "@/hooks/useAppDispatch";
import { setAccountId } from "./auth-reducers";

const initialValues = {
  isPhone: 0,
  email_or_phone: "",
  otc_code: ["", "", "", "", "", ""],
};

type SignupSteps = "initial" | "verify";

type UseSignupProps = {
  setMessage: Dispatch<SetStateAction<Message | null>>;
  is_account_signup?: boolean;
  cell_phone?: string;
  email?: string;
  role_id?: string;
  partnershipId?: string;
  onSuccess?: () => void;
  stayOnThisPage?: boolean;
};

const useSignup = ({
  setMessage,
  cell_phone,
  email,
  onSuccess,
  is_account_signup = true,
  partnershipId,
  stayOnThisPage = false,
}: UseSignupProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const [signupStep, setSignupStep] = useState<SignupSteps>("initial");

  const { mutate: attemptLogin } = useMutation(
    async (credentials: LoginValues & { role_id?: string }) =>
      await login({
        ...credentials,
        is_account_signup,
        signup_url: window.location.href,
      }),
    {
      onSuccess: async (response) => {
        const { user_id, must_finish_user_setup, pending_account_id } = response;

        if (typeof onSuccess === "function") {
          onSuccess();
        }

        dispatch(setAccountId(pending_account_id ?? null));

        if (!must_finish_user_setup && Boolean(router?.query?.dest?.length)) {
          return router.push(router?.query?.dest as string);
        }

        const userData = {
          user_id,
          must_finish_user_setup,
          ...(Boolean(formik.values?.isPhone) ? { cell_phone: formatPhone(formik.values.email_or_phone) } : {}),
          ...(!Boolean(formik.values?.isPhone) ? { email: formik.values.email_or_phone } : {}),
          ...(Boolean(pending_account_id) ? { pending_account_id } : {}),
        };

        identifyLogRocketUser({
          id: user_id,
          email: userData?.email,
          cell_phone: userData?.cell_phone,
        });

        if (stayOnThisPage) {
          window.location.reload();
          return;
        }

        if (router.pathname.includes("/pay") || router.pathname.includes("/approve")) {
          window.location.reload();
          return;
        }

        if (must_finish_user_setup) {
          return router.push({
            pathname: onboardingPaths[OnboardingSteps.USER_PASSWORD],
            query: {
              ...userData,
              ...router?.query,
              uid: user_id,
              ...(Boolean(router?.query?.dest) ? { dest: router?.query?.dest } : {}),
            },
          });
        }

        const user = await getUser();

        const ownerOrMemberRoles = user?.roles.filter(({ type }) =>
          [AccountRoles.ACCOUNT_OWNER, AccountRoles.ACCOUNT_MEMBER].includes(type),
        );

        if (ownerOrMemberRoles?.length === 0) {
          // normal payer login
          return router.push("/user/settings");
        } else {
          return router.push("/choose-account");
        }
      },
      onError: (error: any) => {
        if (error?.response?.data?.message?.includes("already")) {
          setMessage({
            type: "error",
            message: "Seems you may already have an account. Please try logging in.",
          });
        } else {
          setMessage({
            type: "error",
            message: error?.response?.data?.message,
          });
        }
      },
    },
  );

  const { mutate: sendOneTimeCode } = useMutation(
    async (values: AuthValues) => await sendOtc(getPhoneOrEmail(values)),
    {
      onSuccess: async () => {
        setMessage({
          type: "info",
          message: `We sent you a 6-digit code. Please check your ${
            formik.values?.isPhone ? "phone" : "email"
          } and enter the code below.`,
        });
        formik.setSubmitting(false);
        setSignupStep("verify");
      },
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error.response.data.message || "An error occured. Please try again.",
        });
        formik.setSubmitting(false);
      },
    },
  );

  const handleGoogleAuthSuccess = async (values: Pick<AuthValues, "googleAuthToken" | "email">) => {
    setMessage(null);
    const isGoogle = Boolean(values?.googleAuthToken);

    if (!isGoogle) {
      setMessage({
        type: "error",
        message: "Something went wrong with Google Sign Up. Please try again later.",
      });
      return;
    }

    const payload = {
      google_auth_token: values?.googleAuthToken,
      partnership_id: partnershipId,
    };

    attemptLogin(payload);
  };

  const signupActions = {
    initial: sendOneTimeCode,
    verify: (values) =>
      attemptLogin({
        ...getPhoneOrEmail(values),
        otc_code: values?.otc_code?.join(""),
      }),
  };

  const formik = useFormik<any>({
    initialValues: {
      ...initialValues,
      partnership_id: partnershipId,
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema:
      signupStep === "initial" ? initialValidationSchema : signupStep === "verify" ? otcValidationSchema : null,
    onSubmit: (values) => signupActions[signupStep](values),
  });

  useEffect(() => {
    if (cell_phone) {
      formik.setFieldValue("email_or_phone", formatPhone(cell_phone));
    }
    if (email) {
      formik.setFieldValue("email_or_phone", email);
    }
  }, [email, cell_phone]);

  return {
    fields: formik,
    signupStep,
    setSignupStep,
    sendOneTimeCode,
    handleGoogleAuthSuccess,
  };
};

export default useSignup;
