import { FormLabel } from "@/features/auth/components/styled";
import TextField from "@/components/TextField";
import getFieldErrors from "@/utils/getFieldErrors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PhoneField from "@/components/PhoneField";
import { intlFormat } from "@/utils/phone";

const BusinessContact = ({ formik }) => {
  return (
    <>
      <Typography variant="h4">Contact Information</Typography>
      <Box marginTop="2rem">
        <Box marginBottom="1.25rem">
          <FormLabel sx={{ marginBottom: "6px" }}>What is your business email address?</FormLabel>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Business Email"
            {...formik.getFieldProps("legal_email")}
            {...getFieldErrors("legal_email", formik)}
          />
        </Box>
        <Box marginTop="1rem">
          <FormLabel sx={{ marginBottom: "6px" }}>What is your business phone number?</FormLabel>
          <PhoneField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Business Phone Number"
            {...formik.getFieldProps("legal_phone")}
            onChange={(e) => {
              formik.setFieldValue("legal_phone", intlFormat(e.target.value));
            }}
            {...getFieldErrors("legal_phone", formik)}
          />
        </Box>
      </Box>
    </>
  );
};

export default BusinessContact;
