import Typography from "@mui/material/Typography";

const PersonaVerification = () => {
  return (
    <>
      <Typography variant="h4">ID Verification</Typography>
      <Typography variant="body1" marginTop="1rem">
        We need some information to help us confirm your identity.
      </Typography>
      <Typography variant="body1" marginTop="1rem">
        By clicking ‘Continue’, you will be redirected to Persona to verify your identity.
      </Typography>
    </>
  );
};

export default PersonaVerification;
