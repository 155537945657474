import MuiDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Typography from "@mui/material/Typography";
import InlineContainer from "@/components/InlineContainer";
import { useTheme, useMediaQuery } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Quote, QuotePreviewState, Query } from "@/types";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import ErrorButton from "@/components/buttons/ErrorButton";
import QuotePreview from "@/features/quote-management/components/QuotePreview";
import { approveQuote, disapproveQuote } from "@/features/quote-management/quote-management-queries";
import { useSnackbar } from "notistack";

export default NiceModal.create<{
  previewState: QuotePreviewState;
  quote: Quote;
}>(({ previewState, quote }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const modal = useModal();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseModal = () => {
    modal.hide();
  };

  const approveQuoteMutation = useMutation(approveQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.user.QUOTE]);
      handleCloseModal();
      enqueueSnackbar("Failed to approve quote", { variant: "error" });
    },
  });

  const disapproveQuoteMutation = useMutation(disapproveQuote, {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.user.QUOTE]);
      handleCloseModal();
    },
    onError: () => {
      enqueueSnackbar("Failed to reject quote", { variant: "error" });
    },
  });

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Update to Quote Amount
          </Typography>
        </InlineContainer>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="body2"
          sx={{
            marginBottom: "1.5rem",
          }}
        >
          Changes have been made to this quote. Please review the updated quote details below.
          <br />
          <br />
          By selecting "Accept Changes," your scheduled payment will be automatically adjusted to reflect the new total
          amount.
          <br />
          <br />
          To make modifications or cancel the payment, select "Cancel Payment."
        </Typography>
        <QuotePreview previewState={previewState} sync />
      </DialogContent>
      <DialogActions>
        <InlineContainer justifyContent="space-between" width="100%" padding="0.5rem">
          <ErrorButton
            onClick={() => {
              disapproveQuoteMutation.mutate(quote?.id);
            }}
          >
            Cancel Payment
          </ErrorButton>
          <AffirmativeButton
            onClick={() => {
              approveQuoteMutation.mutate(quote?.id);
            }}
          >
            Accept Changes
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </MuiDialog>
  );
});
