import MuiDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Decimal from "decimal.js-light";
import TextField from "@/components/TextField";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import InlineContainer from "@/components/InlineContainer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useMutation } from "@tanstack/react-query";
import getFieldErrors from "@/utils/getFieldErrors";
import { priceItemValidationSchema } from "@/features/price-management/price-management-validation";
import { useFormik } from "formik";
import { useTheme, useMediaQuery, Collapse, Tooltip } from "@mui/material";
import {
  createPriceItem,
  deletePriceItem,
  editPriceItem,
  setInitialItemInventory,
  updateInventory,
} from "@/features/price-management/price-management-queries";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/CloseRounded";
import NumberField from "@/components/NumberField";
import { DialogKeys, PriceItemValues } from "@/types";
import DashedDivider from "@/components/DashedDivider";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import SystemFeedback from "@/components/SystemFeedback";
import { getIntFromDollars } from "@/utils/calculations";
import unformatNumber from "@/utils/unformatNumber";
import useMessage from "@/hooks/useMessage";
import NumberSelect from "@/components/NumberSelect";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import getModifiedValues from "@/utils/getModifiedValues";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import ErrorButton from "@/components/buttons/ErrorButton";
import NeutralButton from "@/components/buttons/NeutralButton";
import { useSnackbar } from "notistack";
import TestId from "@/constants/testIds";
import CloseRounded from "@mui/icons-material/CloseRounded";
import Link from "@/components/Link";

export default NiceModal.create((existingValues: PriceItemValues) => {
  const modal = useModal(DialogKeys.PRICE_ITEM);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = !isMobile; // This is done just so it's easier to read
  const [message, setMessage] = useMessage();
  const { enqueueSnackbar } = useSnackbar();
  const [showBulkImport, setShowBulkImport] = useState(true);

  const isNew = typeof existingValues?.id === "undefined";

  const formik = useFormik<PriceItemValues>({
    initialValues: {
      name: "",
      price: "",
      description: "",
      is_adhoc: false,
      is_track_inventory: false,
      inventory_on_hand: 0,
      inventory_reorder_point: 0,
      from_invoice: false,
    },
    validationSchema: priceItemValidationSchema,
    onSubmit: (values) => {
      if (values.is_adhoc) {
        const transformed = {
          ...values,
          price: getIntFromDollars(unformatNumber(values?.price as string)),
        };
        enqueueSnackbar("Price item successfully added.", {
          variant: "success",
        });
        handleSuccess(transformed);
        return;
      }

      if (!isNew) {
        editItem({ ...values, id: existingValues.id as string });
        return;
      }

      saveItem(values);
    },
  });

  useEffect(() => {
    formik.setValues({
      name: existingValues?.name ?? "",
      price: existingValues?.price ?? "",
      description: existingValues?.description ?? "",
      is_adhoc: existingValues?.is_adhoc ?? false,
      is_track_inventory: existingValues?.is_track_inventory ?? false,
      inventory_reorder_point: existingValues?.inventory_reorder_point ?? 0,
      inventory_on_hand: existingValues?.inventory_on_hand ?? 0,
    });
  }, [existingValues]);

  const handleCloseModal = () => {
    modal.reject();
    modal.hide();
    if (isNew) {
      formik.resetForm();
    }
  };

  const handleSuccess = async (data) => {
    modal.resolve(data);
    modal.hide();
  };

  const { mutate: saveItem, isLoading: isSavingPriceItem } = useMutation(
    (values: PriceItemValues) => createPriceItem(values),
    {
      onSuccess: (response) => {
        enqueueSnackbar("Price item successfully saved", {
          variant: "success",
        });
        handleSuccess(response);
      },
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error?.response?.data?.message ?? "Cannot create price item.",
        });
      },
    },
  );

  const { mutate: editItem, isLoading: isEditingPriceItem } = useMutation(
    (values: PriceItemValues & Required<Pick<PriceItemValues, "id">>) => editPriceItem(values),
    {
      onSuccess: (response) => {
        const modifiedValues = getModifiedValues<PriceItemValues>(formik.values, existingValues);

        // If inventory is turned on, and the quantity has changed,
        // and the user previously had inventory tracking turned on:
        if (
          formik?.values?.is_track_inventory &&
          modifiedValues?.inventory_on_hand &&
          (Boolean(existingValues?.inventory_on_hand) || Boolean(existingValues?.inventory_reorder_point))
        ) {
          updateQuantity({
            priceItemId: existingValues?.id as string,
            changeAmount: new Decimal(unformatNumber(modifiedValues?.inventory_on_hand ?? 0))
              .minus(unformatNumber(existingValues?.inventory_on_hand ?? 0))
              .toNumber(),
          });
        } else if (formik?.values?.is_track_inventory) {
          updateExistingItemInventory({
            id: existingValues?.id as string,
            is_track_inventory: formik.values.is_track_inventory,
            inventory_on_hand: formik.values.inventory_on_hand,
            inventory_reorder_point: formik.values.inventory_reorder_point,
          });
        } else {
          enqueueSnackbar("Price item successfully updated", {
            variant: "success",
          });
          handleSuccess(response);
        }
      },
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error?.response?.data?.message ?? "Cannot edit price item.",
        });
      },
    },
  );

  const { mutate: deleteItem, isLoading: isDeletingPriceItem } = useMutation(
    (itemId?: string) => deletePriceItem(itemId),
    {
      onSuccess: (response) => {
        enqueueSnackbar("Price item successfully deleted", {
          variant: "info",
        });
        handleSuccess(response);
      },
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error?.response?.data?.message ?? "Cannot delete price item.",
        });
      },
    },
  );

  const { mutate: updateQuantity, isLoading: isUpdatingQuantity } = useMutation(
    ({ priceItemId, changeAmount }: { priceItemId: string; changeAmount: number }) =>
      updateInventory(priceItemId, changeAmount),
    {
      onSuccess: (response) => {
        enqueueSnackbar("Price item successfully updated", {
          variant: "success",
        });
        handleSuccess(response);
      },
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error?.response?.data?.message ?? "Cannot delete price item.",
        });
      },
    },
  );

  const { mutate: updateExistingItemInventory, isLoading: isUpdatingExistingItemInventory } = useMutation(
    (
      values: Required<
        Pick<PriceItemValues, "id" | "is_track_inventory" | "inventory_on_hand" | "inventory_reorder_point">
      >,
    ) => setInitialItemInventory(values),
    {
      onSuccess: (response) => {
        enqueueSnackbar("Price item successfully updated", {
          variant: "success",
        });
        handleSuccess(response);
      },
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error?.response?.data?.message ?? "Cannot delete price item.",
        });
      },
    },
  );

  const toggleInventory = () => {
    const shouldTrack = !formik.values.is_track_inventory;

    if (formik.values.is_adhoc) {
      formik.setFieldValue("is_adhoc", false);
    }

    formik.setFieldValue("is_track_inventory", shouldTrack);

    if (!shouldTrack) {
      formik.setFieldValue("inventory_on_hand", 0);
      formik.setFieldValue("inventory_reorder_point", 0);
    }
  };

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => {
          formik.resetForm();
          modal.remove();
        },
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            variant="h6"
            color="primary"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            {isNew ? "Create" : "Edit"} Price Item
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "1.25rem" }} dividers>
        {showBulkImport && (
          <Alert
            icon={<InfoOutlined fontSize="small" />}
            color="info"
            variant="standard"
            sx={{
              margin: "0 0 1rem 0",
              textAlign: "left",
              "& > .MuiAlert-action": {
                paddingTop: "2px",
              },
            }}
            action={
              <IconButton size="small" onClick={() => setShowBulkImport(false)}>
                <CloseRounded fontSize="small" />
              </IconButton>
            }
          >
            To add multiple price items at once,{" "}
            <Link
              onClick={() => {
                formik.resetForm();
                handleCloseModal();
                NiceModal.show(DialogKeys.PRICE_ITEM_BULK_UPLOAD);
              }}
              sx={{ cursor: "pointer" }}
            >
              bulk import
            </Link>{" "}
            an item list.
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit} id="price-item-form">
          <SystemFeedback {...message} />
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="Item Name"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            inputProps={{
              tabIndex: 0,
              "data-testid": TestId.PriceItem.Name,
            }}
            {...formik.getFieldProps("name")}
            {...getFieldErrors("name", formik)}
          />
          <NumberField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="$ Amount"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            inputProps={{
              tabIndex: 1,
              "data-testid": TestId.PriceItem.Amount,
            }}
            {...formik.getFieldProps("price")}
            {...getFieldErrors("price", formik)}
          />
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="Description (Optional)"
            sx={{ fontSize: "1rem" }}
            id="description"
            {...formik.getFieldProps("description")}
            inputProps={{
              tabIndex: 2,
              maxLength: 250,
              "data-testid": TestId.PriceItem.Description,
            }}
          />
          <Typography
            variant="body2"
            sx={{
              color: formik?.values?.description?.length >= 250 ? "error.dark" : "grey.600",
              margin: "0.5rem 0 0 0",
              textAlign: "right",
            }}
          >
            {formik?.values?.description?.length ?? 0} / 250
          </Typography>
          <DashedDivider spacing={2} />
          <Box sx={{ maxWidth: { xs: "90%", lg: "70%" } }} margin="0 auto">
            <InlineContainer justifyContent="space-between" marginBottom="0.75rem">
              <Typography variant="body1" component="label" htmlFor="is_track_inventory" sx={{ cursor: "pointer" }}>
                Track Inventory Quantity
              </Typography>
              <Switch
                id="is_track_inventory"
                checked={formik.values.is_track_inventory}
                onChange={toggleInventory}
                {...{ "data-testid": TestId.PriceItem.InventoryToggle }}
              />
            </InlineContainer>
            <Collapse in={formik.values.is_track_inventory}>
              <InlineContainer
                justifyContent="space-between"
                marginBottom="0.75rem"
                data-testid={TestId.PriceItem.InventoryOnHand}
              >
                <Typography variant="body1" component="label" htmlFor="inventory_on_hand" sx={{ fontWeight: "bold" }}>
                  Quantity on Hand
                </Typography>
                <NumberSelect
                  id="inventory_on_hand"
                  allowNegative
                  {...formik.getFieldProps("inventory_on_hand")}
                  onDecrement={() =>
                    formik.setFieldValue(
                      "inventory_on_hand",
                      unformatNumber(formik?.values?.inventory_on_hand ?? 0) - 1,
                    )
                  }
                  onIncrement={() =>
                    formik.setFieldValue(
                      "inventory_on_hand",
                      unformatNumber(formik?.values?.inventory_on_hand ?? 0) + 1,
                    )
                  }
                  sx={{ width: 130 }}
                />
              </InlineContainer>
              <InlineContainer
                justifyContent="space-between"
                marginBottom="0.75rem"
                data-testid={TestId.PriceItem.InventoryReorderPoint}
              >
                <InlineContainer>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="inventory_reorder_point"
                    sx={{ fontWeight: "bold" }}
                  >
                    Reorder Point
                  </Typography>
                  <Tooltip
                    title="We will notify you when your inventory item reaches the specified reorder point."
                    arrow
                  >
                    <InfoOutlined fontSize="small" sx={{ marginLeft: "0.5rem" }} />
                  </Tooltip>
                </InlineContainer>
                <NumberSelect
                  id="inventory_reorder_point"
                  allowNegative
                  {...formik.getFieldProps("inventory_reorder_point")}
                  onDecrement={() =>
                    formik.setFieldValue(
                      "inventory_reorder_point",
                      unformatNumber(formik?.values?.inventory_reorder_point ?? 0) - 1,
                    )
                  }
                  onIncrement={() =>
                    formik.setFieldValue(
                      "inventory_reorder_point",
                      unformatNumber(formik?.values?.inventory_reorder_point ?? 0) + 1,
                    )
                  }
                  sx={{ width: 130 }}
                />
              </InlineContainer>
            </Collapse>
          </Box>
          {existingValues?.from_invoice && (
            <>
              <DashedDivider spacing={2} />
              <Box sx={{ maxWidth: { xs: "90%", lg: "70%" } }} margin="0 auto 0.75rem">
                <InlineContainer justifyContent="space-between">
                  <Typography variant="body1">One-time Use Only</Typography>
                  <Switch
                    checked={formik.values.is_adhoc}
                    onChange={() => {
                      if (formik.values.is_track_inventory) {
                        formik.setFieldValue("is_track_inventory", false);
                      }

                      formik.setFieldValue("is_adhoc", !formik.values.is_adhoc);
                    }}
                  />
                </InlineContainer>
                <Typography variant="body2">
                  If you turn on this toggle, this item will <strong>NOT</strong> be included on the price list for
                  future invoices.
                </Typography>
              </Box>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          {isNew && isDesktop && (
            <NeutralButton onClick={handleCloseModal} sx={{ marginRight: 2 }} disableElevation>
              Cancel
            </NeutralButton>
          )}
          {!isNew && (
            <ErrorButton
              onClick={() => {
                modal.hide();
                NiceModal.show(DialogKeys.CONFIRM, {
                  title: "Delete Item",
                  content: "Are you sure you want to permanently delete this item from your price list?",
                  note: "Note: This item will still apply to any past or issued invoices that include it.",
                  confirmButton: "Yes, Delete",
                }).then(() => {
                  deleteItem(existingValues?.id);
                });
              }}
              disabled={isSavingPriceItem || isEditingPriceItem || isDeletingPriceItem}
              sx={{ width: 90 }}
              disableElevation
              loading={isDeletingPriceItem}
            >
              Delete
            </ErrorButton>
          )}
          <AffirmativeButton
            type="submit"
            form="price-item-form"
            disabled={
              isSavingPriceItem ||
              isEditingPriceItem ||
              isDeletingPriceItem ||
              isUpdatingQuantity ||
              isUpdatingExistingItemInventory
            }
            sx={{
              whiteSpace: "nowrap",
              width: { xs: isNew ? "100%" : 140, md: 140 },
            }}
            data-testid={TestId.PriceItem.Save}
            loading={isSavingPriceItem || isEditingPriceItem || isUpdatingQuantity || isUpdatingExistingItemInventory}
          >
            Save
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </MuiDialog>
  );
});
