import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Box from "@mui/material/Box";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import NeutralButton from "@/components/buttons/NeutralButton";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";

export default NiceModal.create<{ name: string }>(({ name }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const modal = useModal();

  const handleCloseModal = () => {
    modal.hide();
  };

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      onClose={handleCloseModal}
      fullWidth
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        width: "100vw",
      }}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Box>Deactivate Customer</Box>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon sx={{ color: "primary.dark" }} />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "1.25rem" }} dividers>
        <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: 1.5, fontSize: "16px" }}>
          Are you sure you want to make {name ?? "this customer"} inactive?
        </Typography>

        <Typography variant="body1" sx={{ fontWeight: 600, fontSize: "16px" }}>
          The following will occur:
        </Typography>
        <Box
          sx={{
            paddingLeft: "13px",
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "15px",
          }}
        >
          <li>All recurring invoices will be paused</li>
          <li>All scheduled invoices will be cancelled</li>
          <li>Cannot create invoice for this customer until they are reactivated</li>
          <li>Customer will be able to pay for invoices that have been sent</li>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="center" width="100%" sx={{ gap: "40px" }}>
          <NeutralButton
            type="button"
            onClick={() => modal.hide()}
            disableElevation
            sx={{
              height: "35px",
              fontSize: isMobile ? "13px" : "15px",
              padding: "8px",
              fontWeight: 600,
            }}
          >
            No, Cancel
          </NeutralButton>
          <AffirmativeButton
            type="button"
            onClick={() => {
              modal.resolve();
              modal.hide();
            }}
            sx={{
              height: "35px",
              padding: "8px",
              fontSize: isMobile ? "13px" : "15px",
              fontWeight: 600,
            }}
          >
            Yes, Make Inactive
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
