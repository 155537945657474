import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import InlineContainer from "@/components/InlineContainer";
import { useTheme, useMediaQuery, Box, RadioGroup, Radio, FormControlLabel, Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import DashedDivider from "@/components/DashedDivider";
import { Customer } from "@/types";
import { removeAtIndex } from "@/utils/arrayManipulation";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";

enum EMAIL_TYPE {
  to = "to",
  bcc = "bcc",
}

export default NiceModal.create<{ customers: Customer[] }>(({ customers }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const modal = useModal();
  const [customersList, setCustomersList] = useState(customers);
  const [mailType, setMailType] = useState(EMAIL_TYPE.to);

  const handleRadioButtonClick = (e) => {
    setMailType(e.target.value);
  };

  const handleCloseModal = () => {
    modal.hide();
  };

  const doesCustomerHaveEmail = customersList?.some((customer) => customer.email);

  const customersWithEmail = customersList?.filter((customer) => customer.email);

  const createEmailDraft = () => {
    let emailString = mailType === EMAIL_TYPE.to ? `mailto:` : `mailto:?bcc=`;
    for (let i = 0; i < customersWithEmail.length; i++) {
      emailString += `${customersWithEmail[i].email},`;
    }
    window.open(emailString);
  };

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.common.black,
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Send an Email to:
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          padding: "1.25rem",
        }}
        dividers
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {customersWithEmail.length > 0 ? (
            <>
              <Typography variant="body1">
                Please review the following email list and indicate whether you'd like to "To" or "Bcc" all of the
                contacts before selecting "Compose Email".
              </Typography>
              <Typography display="block" variant="body1">
                You will then be redirected to your email client to create your email message or add any attachments
              </Typography>{" "}
            </>
          ) : (
            <Alert severity="error">No customers with an email were selected</Alert>
          )}
        </Box>
        <DashedDivider flexItem spacing={3} />
        <RadioGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: "15px",
            marginBottom: "20px",
          }}
        >
          <FormControlLabel
            value={EMAIL_TYPE.to}
            control={
              <Radio
                value={EMAIL_TYPE.to}
                checked={mailType === EMAIL_TYPE.to}
                size="medium"
                onClick={handleRadioButtonClick}
              />
            }
            label={<Typography sx={{ fontWeight: "bold" }}>To:</Typography>}
          />
          <FormControlLabel
            value={EMAIL_TYPE.bcc}
            control={
              <Radio
                value={EMAIL_TYPE.bcc}
                checked={mailType === EMAIL_TYPE.bcc}
                size="medium"
                onClick={handleRadioButtonClick}
              />
            }
            label={<Typography sx={{ fontWeight: "bold" }}>Bcc:</Typography>}
          />
        </RadioGroup>
        <Typography color="grey.600" variant="body1" sx={{ marginBottom: "10px" }}>
          {customersWithEmail.length} Entries Total
        </Typography>
        <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" spacing={1} flexWrap="wrap">
          {customersList
            ?.filter((customer) => customer?.email)
            ?.map((customer, index) => {
              return (
                <Box
                  key={customer.id}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    border: "2px solid",
                    borderColor: "primary.light",
                    borderRadius: "6px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography key={customer.id} variant="body1" sx={{ padding: "5px", fontWeight: 600 }}>
                    {customer.name},
                  </Typography>
                  <Typography key={customer.id} variant="body1" sx={{ padding: "5px", fontWeight: 500 }}>
                    {customer.email}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setCustomersList(removeAtIndex(index, customersList));
                    }}
                  >
                    <CloseIcon fontSize="small" sx={{ color: "common.black" }} />
                  </IconButton>
                </Box>
              );
            })}
          {customersList?.length >= 8 && (
            <Typography variant="body1">{`+${customersList?.length - 8} more`}</Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="center" width="100%">
          <AffirmativeButton
            disabled={!doesCustomerHaveEmail}
            type="submit"
            form="bulk-upload-form"
            onClick={createEmailDraft}
            sx={{ backgroundColor: theme.palette.primary.dark }}
          >
            Compose Email
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
