import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import MuiDivider from "@mui/material/Divider";
import CustomTextField from "@/components/TextField";
import CustomSelect from "@/components/Select";
import CustomPhoneField from "@/components/PhoneField";

export const SectionContent = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.common.white};
  border-radius: ${theme.shape.borderRadius}px;
  margin-top: 0;
  padding: 0;
  
  ${theme.breakpoints.up("md")} {
    margin-top: ${theme.spacing(3)};
    padding: ${theme.spacing(3)};
  }
`,
);

export const Divider = styled(MuiDivider)(
  ({ theme }) => `
  margin: ${theme.spacing(3, 0)};

  ${theme.breakpoints.up("lg")} {
    margin: ${theme.spacing(3, 4.75)};
  }
`,
);

export const InputGroup = styled(Box)(
  ({ theme }) => `
  margin: ${theme.spacing(2, 0, 0, 0)};
  
  ${theme.breakpoints.up("lg")} {
    margin: ${theme.spacing(2, 4.75)};
  }
`,
);

const input = `
  width: 100%;
  max-width: 100%;
`;

export const TextField = styled(CustomTextField)(input);
export const PhoneField = styled(CustomPhoneField)(input);
export const Select = styled(CustomSelect)(input);
