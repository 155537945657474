import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import InlineContainer from "@/components/InlineContainer";
import { useTheme, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import DashedDivider from "@/components/DashedDivider";
import TextField from "@/components/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchTags, saveTags } from "@/features/customer-management/customer-management-queries";
import TagList from "@/components/TagList";
import { Query } from "@/types";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { useSnackbar } from "notistack";

type TagValue = {
  id?: string;
  inputValue?: string;
  name: string;
};

export default NiceModal.create<{
  customerIds: string[];
  customers: any[];
}>(({ customerIds, customers }) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const modal = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const { data: tags } = useQuery([Query.account.ALL_TAGS], () => fetchTags());

  const [tagsToAdd, setTagsToAdd] = useState<TagValue[]>([]);

  const [tagValue, setTagValue] = useState<TagValue>({
    inputValue: "",
    name: "",
  });

  const tagFilter = createFilterOptions<{
    inputValue?: string;
    name: string;
  }>();

  const handleCloseModal = () => {
    modal.hide();
  };

  const createTags = useMutation(saveTags, {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.account.CUSTOMERS_PAGINATION]);
      queryClient.invalidateQueries([Query.account.ALL_TAGS]);
      handleCloseModal();
    },
    onError: () => {
      enqueueSnackbar("Failed to add tags", { variant: "error" });
    },
  });

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Add Tags to Customer(s)
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          padding: "1.25rem",
        }}
        dividers
      >
        <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" flexWrap="wrap" gap={1}>
          {customers?.map(
            (customer, index) =>
              index < 8 && (
                <Typography key={customer.id} variant="body1" noWrap>
                  {customer.name}
                  {customers?.length - 1 === index ? null : ", "}
                </Typography>
              ),
          )}
          {customers?.length >= 8 && <Typography variant="body1">{`+${customers?.length - 8} more`}</Typography>}
        </Stack>
        <DashedDivider flexItem spacing={3} />
        <Autocomplete
          value={tagValue}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          size="small"
          options={tags}
          sx={{ width: 300 }}
          onChange={(_, newValue) => {
            const updatedList = [...tagsToAdd];

            if (typeof newValue === "string") {
              if (newValue.length < 1) {
                return;
              }
              updatedList.push({ name: newValue });
            } else if (newValue && newValue.inputValue) {
              if (newValue.inputValue.length < 1) {
                return;
              }
              updatedList.push({ name: newValue?.inputValue });
            } else if (newValue !== null) {
              updatedList.push(newValue);
            }

            if (newValue !== null) {
              const names = updatedList.map((t) => t.name.toLowerCase());
              const filtered = updatedList?.filter(
                ({ name }, index) => !names.includes(name?.toLowerCase(), index + 1),
              );
              setTagsToAdd(filtered);
              setTagValue({ name: "", inputValue: "" });
            }
          }}
          filterOptions={(options, params) => {
            const filtered = tagFilter(options, params);

            const inputValue = params?.inputValue;
            // Suggest the creation of a new value
            const isExisting = options?.some((option) => inputValue === option?.name);
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option?.inputValue) {
              return option?.inputValue;
            }
            // Regular option
            return option?.name;
          }}
          renderOption={(props, option) => <li {...props}>{option?.name}</li>}
          renderInput={(params) => <TextField {...params} placeholder="Start typing, create, or select" />}
        />
        <DashedDivider flexItem spacing={3} />
        <Stack justifyContent="flex-start" alignItems="flex-start" width="100%">
          <TagList
            showTitle
            showEmptyMessage
            tags={tagsToAdd}
            onDelete={(tag) => setTagsToAdd(tagsToAdd?.filter((t) => t.name !== tag.name))}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="center" width="100%">
          <AffirmativeButton
            type="submit"
            form="bulk-upload-form"
            disabled={tagsToAdd.length === 0}
            onClick={() => {
              createTags.mutate({
                account_customer_ids: customerIds,
                account_tags: tagsToAdd,
              });
            }}
          >
            Add Tags
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
