import { styled } from "@mui/material";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import { forwardRef } from "react";

const StyledBaseButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "loading",
})<ButtonProps>(
  ({ theme, color, variant, disableElevation }) => `
  background-color: ${typeof color !== "undefined" && variant === "contained" ? theme.palette?.[color]?.main : variant === "outlined" ? "white" : theme.palette.primary.dark};
  box-shadow: ${disableElevation ? "none" : theme.shadows[2]};
  
  &:hover {
    box-shadow: ${disableElevation ? "none" : theme.shadows[5]};
  }
  
  &:active {
    box-shadow: ${disableElevation ? "none" : theme.shadows[4]};
  }
`,
);

const BaseButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <StyledBaseButton ref={ref} {...props} />
));

export default BaseButton;
