import { formatPhone } from "@/utils/phone";
import { PatternFormat, PatternFormatProps } from "react-number-format";

const FormattedPhone = (
  props: Omit<PatternFormatProps, "format"> & {
    format?: string;
  },
) => {
  const format = "+# (###) ###-####";
  return (
    <PatternFormat
      displayType="text"
      mask="_"
      type="tel"
      format={format}
      {...props}
      value={formatPhone((props?.value as string) ?? "")}
    />
  );
};

export default FormattedPhone;
