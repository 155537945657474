import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dialog, DialogContent, DialogActions } from "@/components/Dialog";
import CopyIcon from "@mui/icons-material/ContentCopySharp";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@/components/TextField";
import useMessage from "@/hooks/useMessage";
import SystemFeedback from "@/components/SystemFeedback";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";

export default NiceModal.create<{
  link: string;
}>(({ link }) => {
  const modal = useModal();
  const [message, setMessage] = useMessage();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setMessage({
      type: "success",
      message: "Link successfully copied to clipboard",
    });

    const timer = setTimeout(() => {
      setMessage(null);
      clearTimeout(timer);
    }, 5000);
  };

  return (
    <Dialog modal={modal} title="Share via Link">
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <Box padding="1rem 2rem 0.5rem 2rem" display="flex" flexDirection="column">
          <SystemFeedback {...message} sx={{ margin: "0 0 0.5rem 0" }} />
          <TextField
            value={link}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CopyIcon fontSize="small" sx={{ zIndex: 1 }} />
                </InputAdornment>
              ),
            }}
            onFocus={(event) => event.target.select()}
          />
          <Box textAlign="center" marginTop="0.5rem">
            <Button type="button" variant="text" onClick={handleCopyLink} sx={{ display: "inline-block" }} size="small">
              Copy Link
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <AffirmativeButton size="small" onClick={modal.hide} sx={{ minWidth: "100px" }}>
          Done
        </AffirmativeButton>
      </DialogActions>
    </Dialog>
  );
});
