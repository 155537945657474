import { useFormik } from "formik";
import { Account, Query, BusinessLinkType, CardSurchargeToEnum } from "@/types";
import getModifiedValues from "@/utils/getModifiedValues";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import useAccount from "@/features/account-management/useAccount";
import { saveAccountDetails } from "@/features/account-management/account-management-queries";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { businessUserDemographicValidationSchema, onboardingValidationSchema } from "@/features/auth/auth-validation";
import {
  businessNameValidationSchema,
  businessAddressValidationSchema,
  businessContactValidationSchema,
  businessServiceValidationSchema,
  businessTypeValidationSchema,
  businessSsnValidationSchema,
  businessRelationValidationSchema,
} from "@/features/account-management/account-management-validation";
import useUser from "@/features/auth/useUser";

const schemas = {
  onboarding: onboardingValidationSchema,
  businessUserDemographic: businessUserDemographicValidationSchema,
  businessName: businessNameValidationSchema,
  businessAddress: businessAddressValidationSchema,
  businessContact: businessContactValidationSchema,
  businessService: businessServiceValidationSchema,
  businessType: businessTypeValidationSchema,
  businessSsn: businessSsnValidationSchema,
  businessRelation: businessRelationValidationSchema,
};

const getInitialValues = (account: Account = {} as Account, businessUsesPersonalAddress = false) => ({
  subdomain: account?.subdomain ?? "",
  legal_name: account?.legal_name || account?.public_name || "",
  legal_email: account?.legal_email || account?.public_email || "",
  legal_phone: account?.legal_phone || account?.public_phone || "",
  public_name: account?.public_name || "",
  public_email: account?.public_email ?? "",
  public_phone: account?.public_phone ?? "",
  legal_address1: account?.legal_address1 || account?.public_address1 || "",
  legal_address2: account?.legal_address2 || account?.public_address2 || "",
  legal_city: account?.legal_city || account?.public_city || "",
  legal_state: account?.legal_state || account?.public_state || "",
  legal_zip_code: account?.legal_zip_code || account?.public_zip_code || "",
  public_address1: account?.public_address1 ?? "",
  public_address2: account?.public_address2 ?? "",
  public_city: account?.public_city ?? "",
  public_state: account?.public_state ?? "",
  public_zip_code: account?.public_zip_code ?? "",
  description: account?.description ?? "",
  ein: "",
  ssn: "",
  tax_id: account?.tax_id ?? "",
  display_address: account?.display_address ?? true,
  website_url: account?.website_url ?? "",
  timezone: account?.timezone ?? "",
  compliance_answer: account?.compliance_answer ?? {},
  customer_type: account?.compliance_answer?.customer_type ?? "x",
  service_type: account?.compliance_answer?.industry ?? "x",
  business_structure: account?.compliance_answer?.business_structure ?? "",
  last_four_ssn: account?.compliance_answer?.ssn_last_4 ?? "",
  relationship_to_business: account?.compliance_answer?.relationship_to_business ?? "",
  links: account?.compliance_answer?.social_media || [],
  state_incorporation: account?.compliance_answer?.state_incorporation ?? "",
  same_as_personal_address: businessUsesPersonalAddress ? "yes" : "no",
  card_surcharge_to: account?.card_surcharge_to ?? CardSurchargeToEnum.USER,
  persona_inquiry_id: account?.persona_inquiry_id ?? "",
});

const useAccountUpdater = ({
  validate = "",
  quiet = false,
  onSuccess = undefined,
  validateOnBlur = false,
  validateOnMount = false,
  validateOnChange = false,
}: {
  validate?: string;
  quiet?: boolean;
  onSuccess?: (response?: Account) => void;
  validateOnBlur?: boolean;
  validateOnMount?: boolean;
  validateOnChange?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { account } = useAccount();
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const businessUsesPersonalAddress =
    user?.compliance_answer?.address_1 === account?.legal_address1 &&
    user?.compliance_answer?.city === account?.legal_city &&
    user?.compliance_answer?.state === account?.legal_state &&
    user?.compliance_answer?.zip_code === account?.legal_zip_code;

  const saveDetails = useMutation(
    (values: Partial<Account> & Partial<typeof getInitialValues>) => saveAccountDetails(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([Query.account.ACCOUNT_INFO]);
        queryClient.invalidateQueries([Query.user.USER_INFO]);

        if (!quiet) {
          enqueueSnackbar("Account updated successfully!", {
            variant: "success",
          });
        }

        if (typeof onSuccess === "function") {
          onSuccess(formik.values);
        }
      },
      onError: (error: any) => {
        if (!quiet) {
          enqueueSnackbar(error?.response?.data?.message ?? "Account cannot be updated.", {
            variant: "error",
          });
        }
      },
    },
  );

  const formik = useFormik<Account>({
    initialValues: getInitialValues(account, businessUsesPersonalAddress),
    validateOnBlur,
    validateOnMount,
    validateOnChange,
    ...(Boolean(validate) ? { validationSchema: schemas?.[validate] } : {}),
    onSubmit: (values) => {
      saveDetails.mutate({
        ...values,
        // All compliance answers must be submitted together otherwise
        // the API Will throw away any answers that are not included.
        customer_type: values?.customer_type,
        service_type: values?.service_type,
        business_structure: values?.business_structure,
        state_incorporation: values?.state_incorporation,
        last_four_ssn: values?.last_four_ssn,
        relationship_to_business: values?.relationship_to_business,
        links: (values?.links || []).map((link) => {
          let url = link.url;
          if (
            [
              BusinessLinkType.WEBSITE,
              BusinessLinkType.GOOGLE_PAGES,
              BusinessLinkType.LINKEDIN,
              BusinessLinkType.OTHER,
            ].includes(link.platform as BusinessLinkType) &&
            !url.startsWith("http")
          ) {
            url = "https://" + url;
          }

          return {
            ...link,
            url,
          };
        }),
      });
    },
  });

  useEffect(() => {
    formik.setValues(getInitialValues({ ...account, is_not_shady: true } as Account, businessUsesPersonalAddress));
  }, [account?.updated_at]);

  return {
    account,
    initialValues: getInitialValues(account, businessUsesPersonalAddress),
    fields: formik,
    saveDetails,
    isLoading: saveDetails.isLoading,
    disableSubmit:
      !Object.keys(getModifiedValues<Account>(formik.values, formik.initialValues)).length && formik.isValid,
  };
};

export default useAccountUpdater;
