import InlineContainer from "@/components/InlineContainer";
import Box from "@mui/material/Box";
import { Fragment, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { PaymentBrandIcons } from "@/features/wallet/components/WalletInstrumentIcons";
import { setLinkToken, setWalletId, setWalletInstrumentId, tweakPlaidWidget } from "@/features/wallet/wallet-reducers";
import { AddWalletInstrument } from "@/features/wallet/components/payment-method/AddWalletInstrument";
import useAppDispatch from "@/hooks/useAppDispatch";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Alert, Button, Tooltip, styled } from "@mui/material";
import EditIcon from "@mui/icons-material/EditRounded";
import { getInstrumentLabel, isWalletInstrumentUnverified } from "@/utils/wallet";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import NeutralButton from "@/components/buttons/NeutralButton";
import { ScheduledBusinessPayments, Query, PaymentMethodTypeEnum, PaymentBrand } from "@/types";
import { fetchFuturePayments } from "@/features/wallet/wallet-queries";
import NiceModal from "@ebay/nice-modal-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import DeletePaymentMethodDialog from "@/features/wallet/components/payment-method/DeletePaymentMethodDialog";
import { isApplePayEligible } from "@/features/pay-invoice/payWithApplePay";

const Label = styled(FormControlLabel)<{ notAutoPay?: boolean }>(
  ({ notAutoPay }) => `
  p {
    color: ${notAutoPay ? "rgba(0, 0, 0, 0.38)" : undefined};
  }
  
  > span.MuiRadio-root {
    color: ${notAutoPay ? "rgba(0, 0, 0, 0.26)" : undefined};
  }
`,
);

const PaymentMethodContainer = styled(Box)<{}>(
  () => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`,
);

const WalletInstrumentOption = ({
  instrumentId,
  brand,
  label,
  color = "common.black",
  selectedWalletInstrumentId,
  setSelectedWalletInstrumentId,
  disabledTooltips,
  onClickVerification,
  deletable = true,
  onClickDelete,
  isLimitOneWalletInstrument = false,
  showProcessingFee = false,
}: {
  instrumentId: string | PaymentMethodTypeEnum;
  brand: PaymentBrand;
  label: string;
  color?: string;
  selectedWalletInstrumentId: string;
  setSelectedWalletInstrumentId: Function;
  disabledTooltips?: string;
  onClickVerification?: Function;
  deletable?: boolean;
  onClickDelete?: () => void;
  isLimitOneWalletInstrument?: boolean;
  showProcessingFee?: boolean;
}) => {
  let Container = Fragment;
  if (disabledTooltips) {
    // @ts-ignore
    Container = ({ children }: { children: any }) => (
      <Tooltip title={disabledTooltips} enterTouchDelay={0} leaveTouchDelay={500} placement="top-start">
        {children}
      </Tooltip>
    );
  }

  return (
    <Container>
      <PaymentMethodContainer>
        <Label
          checked={selectedWalletInstrumentId === instrumentId}
          value={instrumentId}
          label={
            <InlineContainer
              sx={{
                opacity: disabledTooltips ? 0.4 : 1,
              }}
            >
              {PaymentBrandIcons[brand]}
              <Typography
                variant="body1"
                sx={{
                  color,
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                {label}
              </Typography>
              {isLimitOneWalletInstrument && (
                <Button
                  variant="text"
                  color="primary"
                  endIcon={<EditIcon fontSize="small" />}
                  sx={{
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setSelectedWalletInstrumentId(PaymentMethodTypeEnum.NEW);
                  }}
                >
                  Edit
                </Button>
              )}
            </InlineContainer>
          }
          control={
            isLimitOneWalletInstrument ? (
              <span style={{ marginRight: "1rem" }}></span>
            ) : (
              <Radio
                size="small"
                onClick={() => setSelectedWalletInstrumentId(instrumentId)}
                disabled={!!disabledTooltips}
              />
            )
          }
        />
        <InlineContainer alignItems="center">
          {onClickVerification && (
            // @ts-ignore
            <Button
              variant="text"
              sx={{
                height: "1.5rem",
                width: "3.5rem",
                fontSize: ".85rem",
                fontWeight: "600",
              }}
              onClick={onClickVerification}
            >
              Verify
            </Button>
          )}
          {deletable && (
            <DeleteIcon
              fontSize="small"
              onClick={onClickDelete}
              sx={{
                ":hover": { cursor: "pointer" },
                marginBottom: ".05rem",
              }}
            />
          )}
        </InlineContainer>
      </PaymentMethodContainer>
      {showProcessingFee && selectedWalletInstrumentId === instrumentId && (
        <Typography variant="body2" color={"grey.600"} sx={{ fontSize: "0.85rem", marginLeft: "2rem" }}>
          A <strong>3% processing fee</strong> will be added to your total.
        </Typography>
      )}
    </Container>
  );
};

const PaymentMethodList = ({
  formik,
  walletInstruments,
  isBackdoor = false,
  selectedWalletInstrumentId,
  setSelectedWalletInstrumentId,
  showProcessingFee,
  walletId,
  isLimitOneWalletInstrument = false,
  metadata = {
    [PaymentMethodTypeEnum.CREDIT_CARD]: {
      visible: true,
      visibleNew: true,
    },
    [PaymentMethodTypeEnum.BANK_ACCOUNT]: {
      visible: true,
      visibleNew: true,
    },
  },
  allowUnverfiedInstrumentSelection = false,
  partnershipInformation,
  preventDeleteOn = [],
}: {
  formik: any;
  walletInstruments: any[];
  isBackdoor?: boolean;
  selectedWalletInstrumentId: string;
  setSelectedWalletInstrumentId: Function;
  showProcessingFee: boolean;
  walletId?: string;
  isLimitOneWalletInstrument?: boolean;
  metadata?: Record<
    any,
    {
      visible: boolean;
      visibleNew: boolean;
      disabledTooltips?: string;
    }
  >;
  allowUnverfiedInstrumentSelection?: boolean;
  partnershipInformation?: {
    routingNumber: string;
    name: string;
  };
  preventDeleteOn?: string[];
}) => {
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const [methodPendingDeletion, setMethodPendingDeletion] = useState<any | null>(null);

  useQuery(
    [Query.wallet.FUTURE_PAYMENTS, methodPendingDeletion?.id],
    () => fetchFuturePayments(methodPendingDeletion?.id),
    {
      enabled: Boolean(methodPendingDeletion?.id ?? null),
      keepPreviousData: false,
      retry: false,
      staleTime: 0,
      cacheTime: 0,
      onSuccess: (response: ScheduledBusinessPayments) => {
        NiceModal.show(DeletePaymentMethodDialog, {
          futurePayments: response,
          instrumentToDelete: methodPendingDeletion,
        }).finally(() => {
          queryClient.invalidateQueries([Query.account.ACCOUNT_INFO]);
          queryClient.invalidateQueries([Query.user.USER_INFO]);
          queryClient.invalidateQueries([Query.user.PIL_INVOICE]);
          queryClient.invalidateQueries([Query.wallet.FUTURE_PAYMENTS]);
          setMethodPendingDeletion(null);
        });
      },
    },
  );

  useEffect(() => {
    setMethodPendingDeletion(methodPendingDeletion || null);
  }, [methodPendingDeletion?.id]);

  const isUnverifiedPaymentInWallet = walletInstruments?.some((instrument) => instrument?.plaid_link_token);

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        {!allowUnverfiedInstrumentSelection && isUnverifiedPaymentInWallet && (
          <Alert
            severity="error"
            sx={{
              marginTop: ".5rem",
              width: "fit-content",
              marginBottom: ".5rem",
              backgroundColor: "error.lighter",
            }}
          >
            A payment method requires verification. Please
            <br /> verify or use a different payment method
          </Alert>
        )}
        <FormControl>
          {(!isLimitOneWalletInstrument || selectedWalletInstrumentId !== PaymentMethodTypeEnum.NEW) && (
            <RadioGroup
              value={selectedWalletInstrumentId}
              onChange={(event) => {
                setSelectedWalletInstrumentId(event.target.value);
              }}
            >
              {(isLimitOneWalletInstrument ? walletInstruments.slice(0, 1) : walletInstruments)
                ?.map((instrument) => {
                  if (!metadata?.[instrument?.type]?.visible) {
                    return null;
                  }

                  const disabledTooltips =
                    metadata?.[instrument?.type]?.disabledTooltips ||
                    (!allowUnverfiedInstrumentSelection && isWalletInstrumentUnverified(instrument)
                      ? "Requires verification"
                      : undefined);

                  // for the case where a user creates a new payment method
                  // we force the button to be the new payment method
                  return (
                    <WalletInstrumentOption
                      key={instrument?.id}
                      instrumentId={instrument?.id}
                      brand={
                        instrument?.type === PaymentMethodTypeEnum.BANK_ACCOUNT
                          ? PaymentBrand.BANK_ACCOUNT
                          : instrument?.brand?.toUpperCase()
                      }
                      label={getInstrumentLabel(instrument, true)}
                      color={isWalletInstrumentUnverified(instrument) ? "error.main" : "common.black"}
                      selectedWalletInstrumentId={selectedWalletInstrumentId}
                      setSelectedWalletInstrumentId={setSelectedWalletInstrumentId}
                      disabledTooltips={disabledTooltips}
                      onClickVerification={
                        isWalletInstrumentUnverified(instrument)
                          ? () => {
                              dispatch(setLinkToken(instrument.plaid_link_token));
                              dispatch(setWalletId(walletId as string));
                              dispatch(setWalletInstrumentId(instrument.id as string));

                              setTimeout(() => {
                                dispatch(tweakPlaidWidget());
                              }, 500);
                            }
                          : undefined
                      }
                      deletable={walletInstruments?.length > 1 && !preventDeleteOn.includes(instrument?.id)}
                      onClickDelete={() => {
                        setMethodPendingDeletion(instrument);
                      }}
                      isLimitOneWalletInstrument={isLimitOneWalletInstrument}
                      showProcessingFee={showProcessingFee}
                    />
                  );
                })
                .filter((pm) => pm)}
              {metadata?.[PaymentMethodTypeEnum.APPLE_PAY]?.visible && isApplePayEligible && (
                <WalletInstrumentOption
                  instrumentId={PaymentMethodTypeEnum.APPLE_PAY}
                  brand={PaymentBrand.APPLE_PAY}
                  label="Apple Pay"
                  selectedWalletInstrumentId={selectedWalletInstrumentId}
                  setSelectedWalletInstrumentId={setSelectedWalletInstrumentId}
                  deletable={false}
                  disabledTooltips={metadata?.[PaymentMethodTypeEnum.APPLE_PAY]?.disabledTooltips}
                  showProcessingFee={showProcessingFee}
                />
              )}
              {walletInstruments.length > 0 && !isLimitOneWalletInstrument && (
                <FormControlLabel
                  value={PaymentMethodTypeEnum.NEW}
                  checked={selectedWalletInstrumentId === PaymentMethodTypeEnum.NEW}
                  label="Add new payment method"
                  control={<Radio size="small" sx={{ color: "grey.500" }} />}
                  onClick={() => setSelectedWalletInstrumentId(PaymentMethodTypeEnum.NEW)}
                  sx={{ width: "100%" }}
                />
              )}
            </RadioGroup>
          )}
        </FormControl>
      </Box>
      {selectedWalletInstrumentId === PaymentMethodTypeEnum.NEW && (
        <>
          <AddWalletInstrument
            formik={formik}
            showProcessingFee={showProcessingFee}
            metadata={{
              [PaymentMethodTypeEnum.BANK_ACCOUNT]: {
                visible: metadata?.[PaymentMethodTypeEnum.BANK_ACCOUNT]?.visibleNew,
                supportAddRawBankInfo: isBackdoor,
                partnershipInformation,
              },
              [PaymentMethodTypeEnum.CREDIT_CARD]: {
                visible: metadata?.[PaymentMethodTypeEnum.CREDIT_CARD]?.visibleNew,
              },
              [PaymentMethodTypeEnum.APPLE_PAY]: {
                visible: metadata?.[PaymentMethodTypeEnum.APPLE_PAY]?.visibleNew,
              },
            }}
            isShowSubmitCTA
          />
          {isLimitOneWalletInstrument && walletInstruments.length !== 0 && (
            <NeutralButton
              fullWidth
              sx={{
                marginTop: ".75rem",
                marginBottom: ".75rem",
              }}
              onClick={() => {
                setSelectedWalletInstrumentId(walletInstruments?.[0]?.id);
              }}
            >
              Cancel
            </NeutralButton>
          )}
        </>
      )}
    </Box>
  );
};

export default PaymentMethodList;
