import { FormLabel } from "@/features/auth/components/styled";
import TextField from "@/components/TextField";
import getFieldErrors from "@/utils/getFieldErrors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const LegalBusinessName = ({ formik }) => {
  return (
    <>
      <Typography variant="h4">What is your business called?</Typography>
      <Box marginTop="2rem" width="100%">
        <Box>
          <FormLabel sx={{ marginBottom: "6px" }}>Legal Business Name</FormLabel>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Business Inc."
            {...formik.getFieldProps("legal_name")}
            {...getFieldErrors("legal_name", formik)}
          />
        </Box>
        <Box marginTop="1rem">
          <FormLabel sx={{ marginBottom: "6px" }}>Doing Business As</FormLabel>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Business Name"
            {...formik.getFieldProps("public_name")}
            {...getFieldErrors("public_name", formik)}
          />
          <Typography variant="body2" sx={{ color: "grey.600", marginTop: "0.5rem" }}>
            This is the name that will be shown to your customers
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default LegalBusinessName;
