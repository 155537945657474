import { styled } from "@mui/material";
import MuiLink from "@mui/material/Link";

const SimpleLink = styled(MuiLink)(
  ({ theme }) => `
    color: ${theme.palette.primary.main};
    font-family: ${theme.typography.fontFamily};
    font-weight: bold;
    text-decoration: none;
  
    &:hover {
      text-decoration: underline;
    }
  `,
) as typeof MuiLink;

export default SimpleLink;
