import { Children, ComponentType, ReactNode } from "react";

/**
 * Renders children based on specified component types.
 *
 * @param {ComponentType | ComponentType[]} Components - The component type(s) to filter by.
 * @param {ReactNode} children - The children nodes to filter.
 * @param {boolean} [excludeBy] - Whether to exclude the specified components.
 * @returns {ReactNode[] | null} An array of filtered children or null if none.
 */
const renderByChildType = (
  Components: ComponentType[] | ComponentType,
  children: ReactNode,
  excludeBy = false,
): ReactNode[] | null => {
  let nodes: ComponentType[];

  if (!(Components instanceof Array)) {
    nodes = [Components];
  } else {
    nodes = Components;
  }

  const filteredChildren = Children.toArray(children)
    .map((child) => {
      if (excludeBy) {
        return nodes.some((Component) => child && child["type"] === Component) ? null : child;
      }
      return nodes.some((Component) => child && child["type"] === Component) ? child : null;
    })
    .filter((node) => node !== null);

  return filteredChildren.length ? filteredChildren : null;
};

export default renderByChildType;
