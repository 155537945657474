import { AccountRoles, Role } from "@/types";
import useUser from "@/features/auth/useUser";
import useAccount from "@/features/account-management/useAccount";

const useCurrentRole = () => {
  const { user } = useUser();
  const { account } = useAccount();

  const currentRole = user?.roles?.find((role) => role?.account?.id === account?.id) as Role;

  const authLevel = currentRole?.type ?? AccountRoles.NONE;

  return {
    currentRole: authLevel,
    isOwner: authLevel === AccountRoles.ACCOUNT_OWNER,
    isMember: authLevel === AccountRoles.ACCOUNT_MEMBER,
  };
};

export default useCurrentRole;
