import Autocomplete from "react-google-autocomplete";
import { styled } from "@mui/material/styles";

export const StyledAddressAutocomplete = styled(Autocomplete)<{
  value?: string;
  placeholder?: string;
}>(
  ({ theme }) => `
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-stretch: 100%;
  letter-spacing: 0.15008px;
  line-height: 24px;
  padding: 8.5px 14px;
  width: 100%;
  height: 40px;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }

  &:focus {
    border: 1px solid ${theme.palette.primary.main};
    outline: 0;
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: ${theme.palette.grey[500]};
  }
`,
);

type PlaceResult = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
};

interface AddressAutocompleteProps {
  placeholder?: string;
  onPlaceSelected?: (props: PlaceResult) => void;
}

const AddressAutocomplete = ({
  placeholder = "Street Address",
  onPlaceSelected = undefined,
  ...props
}: AddressAutocompleteProps) => {
  const handleSelect = (place) => {
    let number = place?.address_components?.find((c) => c.types.includes("street_number"))?.long_name;
    let street = place?.address_components?.find((c) => c.types.includes("route"))?.long_name;
    let address2 = place?.address_components?.find((c) => c.types.includes("subpremise"))?.long_name;
    let city = place?.address_components?.find((c) => c.types.includes("locality"))?.long_name;
    let state = place?.address_components?.find((c) => c.types.includes("administrative_area_level_1"))?.short_name;
    let zip = place?.address_components?.find((c) => c.types.includes("postal_code"))?.long_name;

    number = typeof number === "undefined" ? "" : number;
    street = typeof street === "undefined" ? "" : street;
    address2 = typeof address2 === "undefined" ? "" : address2;
    city = typeof city === "undefined" ? "" : city;
    state = typeof state === "undefined" ? "" : state;
    zip = typeof zip === "undefined" ? "" : zip;

    if (typeof onPlaceSelected === "function") {
      onPlaceSelected({
        address1: `${number} ${street}`.trim(),
        address2: address2.trim(),
        city: city.trim(),
        state: state.trim(),
        zip: zip.trim(),
      });
    }
  };

  return (
    <StyledAddressAutocomplete
      placeholder={placeholder}
      apiKey={process.env.GOOGLE_PLACES_API_KEY}
      options={{
        componentRestrictions: { country: "us" },
        fields: ["address_components"],
        types: ["street_number", "route", "locality", "administrative_area_level_1", "postal_code"],
      }}
      onPlaceSelected={handleSelect}
      {...props}
    />
  );
};

export default AddressAutocomplete;
