import { useState, useCallback, useEffect } from "react";
import Dropzone, { FileWithPath } from "react-dropzone";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material";
import UploadIcon from "@mui/icons-material/UploadFileRounded";
import { initApi, routes } from "@/config/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Account, User, Query } from "@/types";
import { useSnackbar } from "notistack";
import { saveUserSettings, saveAccountDetails } from "@/features/account-management/account-management-queries";
import { updateAdminPartner } from "@/features/admin-management/admin-management-queries";
import { useRouter } from "next/router";

const Container = styled(Box)(
  ({ theme }) => `
  color: ${theme.palette.grey[400]};
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
	gap: 1rem;
	
	${theme.breakpoints.up("md")} {
		justify-content: flex-start;
	}
`,
);

type AvatarUploaderProps = {
  type?: "user" | "account" | "partner";
  title?: string;
  currentAvatar?: string;
  size?: number;
  orientation?: "horizontal" | "vertical";
  showButtons?: boolean;
  showAsSquare?: boolean;
  onChange?: (value: string | null) => void;
  [key: string]: any;
};

const AvatarUploader = ({
  type = "account",
  title = "Add Logo",
  currentAvatar = "",
  size = 120,
  orientation = "horizontal",
  showButtons = true,
  showAsSquare = false,
  onChange,
  ...props
}: AvatarUploaderProps) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [image, setImage] = useState<FileWithPath | string>(currentAvatar);
  const { enqueueSnackbar } = useSnackbar();
  const partnerId = router?.query?.partnerId as string;

  const { mutate: saveUserProfilePic } = useMutation((values: Partial<User>) => saveUserSettings(values), {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.user.USER_INFO]);
      queryClient.invalidateQueries([Query.account.ACCOUNT_INFO]);
      enqueueSnackbar("Profile picture successfully updated!", {
        variant: "success",
      });
    },
    onError: () => {
      enqueueSnackbar("Your profile picture could not be updated.", {
        variant: "error",
      });
    },
  });

  const { mutate: saveBusinessLogo } = useMutation((values: Partial<Account>) => saveAccountDetails(values), {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.user.USER_INFO]);
      queryClient.invalidateQueries([Query.account.ACCOUNT_INFO]);
      enqueueSnackbar("Business logo successfully updated!", {
        variant: "success",
      });
    },
    onError: (error: any) => {
      enqueueSnackbar(error?.response?.data?.message ?? "Your business logo could not be updated.", {
        variant: "error",
      });
    },
  });

  const { mutate: savePartnerLogo } = useMutation(
    (values: any) => updateAdminPartner(router?.query?.partnerId as string, values),
    {
      onSuccess: () => {
        enqueueSnackbar("Partner updated successfully", { variant: "success" });
        queryClient.invalidateQueries([Query.admin.SPECIFIC_PARTNER, partnerId]);
        queryClient.invalidateQueries(["partner", partnerId]);
      },
      onError: () => {
        enqueueSnackbar("Failed to update partner", { variant: "error" });
      },
    },
  );

  const { mutate: uploadAvatar } = useMutation(
    async (file) =>
      await initApi().post(
        routes.user.upload,
        {
          content: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      ),
    {
      onSuccess: ({ data }) => {
        if (type === "user") {
          if (typeof onChange === "function") {
            onChange(data?.data?.[0]?.output_url);
            return;
          }

          saveUserProfilePic({ avatar_url: data?.data?.[0]?.output_url });
          return;
        }

        if (type === "partner") {
          if (typeof onChange === "function") {
            onChange(data?.data?.[0]?.output_url);
            return;
          }

          savePartnerLogo({ logo: data?.data?.[0]?.output_url });
          return;
        }

        if (type === "account") {
          if (typeof onChange === "function") {
            onChange(data?.data?.[0]?.output_url);
            return;
          }

          saveBusinessLogo({ avatar_url: data?.data?.[0]?.output_url });
          return;
        }
      },
      onError: (error: any) => {
        enqueueSnackbar(
          error?.response?.data?.message ??
            `Could not upload your ${type === "user" ? "profile picture" : "business logo"}.`,
          {
            variant: "error",
          },
        );
      },
    },
  );

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    setImage(file);

    const data = new FormData();
    data.append("image", file, file.name);

    uploadAvatar(file);
  }, []);

  const hasImage = Boolean(image);

  useEffect(() => {
    if (currentAvatar) {
      setImage(currentAvatar);
    }
  }, [currentAvatar]);

  return (
    <Dropzone
      onDrop={onDrop}
      accept={{
        "image/*": [".jpg", ".jpeg", ".png", ".tiff", ".bmp", ".webp"],
      }}
      noClick={true}
      multiple={false}
    >
      {({ open, getRootProps, getInputProps, isDragActive }) => (
        <Container
          {...getRootProps()}
          sx={{
            display: orientation === "horizontal" ? "inline-flex" : "flex",
            flexDirection: orientation === "horizontal" ? "row" : "column",
            gap: props?.gap ?? "1rem",
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Box
            onClick={open}
            sx={{
              borderRadius: showAsSquare ? "0" : "50%",
              overflow: "hidden",
              width: size,
              height: size,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: hasImage ? "none" : "2px dashed",
              borderColor: "grey.300",
            }}
          >
            {!hasImage ? (
              <Box
                width={size}
                height={size}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                }}
              >
                {isDragActive ? (
                  <Typography variant="body2">Drop Image Here</Typography>
                ) : (
                  <UploadIcon fontSize={size <= 90 ? "medium" : "large"} color="inherit" />
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  background: `url(${typeof image === "string" ? image : URL.createObjectURL(image)})`,
                  backgroundColor: "common.white",
                  backgroundSize: "70%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  width: size,
                  height: size,
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: orientation === "vertical" ? "column" : { xs: "row", md: "column" },
              marginLeft: orientation === "vertical" ? 0 : { xs: 0, md: "0.5rem" },
            }}
          >
            <input {...getInputProps()} />
            {showButtons && (
              <Box display="inline-flex" gap="0.5rem">
                <Button size="small" variant="outlined" onClick={open}>
                  {hasImage ? "Replace" : title}
                </Button>
                {hasImage && (
                  <Button
                    size="small"
                    variant="text"
                    onClick={async (event) => {
                      event.stopPropagation();
                      setImage("");

                      if (type === "user") {
                        saveUserProfilePic({ avatar_url: null });
                        return;
                      }

                      if (type === "account") {
                        saveBusinessLogo({ avatar_url: null });
                      }

                      if (type === "partner") {
                        if (typeof onChange === "function") {
                          onChange(null);
                          return;
                        }

                        savePartnerLogo({ logo: null });
                        return;
                      }
                    }}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Container>
      )}
    </Dropzone>
  );
};

export default AvatarUploader;
