import { createApplePaySession } from "@/features/payment-management/payment-management-queries";
import { PaymentMethodTypeEnum } from "@/types";

const payWithApplePay = ({ label, amount, formik, mutate }) => {
  const applePayTotalLineItem = {
    label,
    type: "final",
    amount: amount.toString(),
  };

  const applePaySessionRequest = {
    countryCode: "US",
    currencyCode: "USD",
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: ["visa", "masterCard", "amex", "discover"],
    total: applePayTotalLineItem,
    requiredBillingContactFields: ["postalAddress"],
  };

  const session = new ApplePaySession(3, applePaySessionRequest);

  session.onvalidatemerchant = async (event) => {
    const oneTimeSession = await createApplePaySession(event.validationURL);
    session.completeMerchantValidation(oneTimeSession);
  };

  session.onpaymentmethodselected = (_event) => {
    session.completePaymentMethodSelection({
      newTotal: applePayTotalLineItem,
    });
  };

  session.onpaymentauthorized = async (event) => {
    /* eslint-disable-next-line  */
    const encrypted_payment_data = btoa(JSON.stringify(event.payment.token.paymentData));
    const result = {
      status: ApplePaySession.STATUS_SUCCESS,
    };

    session.completePayment(result); // TODO also show failure if PiL fails

    const last_four_digits = event.payment.token.paymentMethod.displayName.split(" ")[1];
    const brand = event.payment.token.paymentMethod.network.toLowerCase();

    formik.setSubmitting(true);
    mutate({
      ...formik.values,
      payment_type: PaymentMethodTypeEnum.APPLE_PAY,
      encrypted_payment_data,
      brand,
      last_four_digits,
      billing_zip_code: event.payment.billingContact.postalCode,
      first_name: event.payment.billingContact.givenName || "first",
      last_name: event.payment.billingContact.familyName || "last",
    });
  };

  session.oncancel = (_event) => {};
  session.begin();
};

let canMakeApplePayPayments = false;
// @ts-ignore
if (typeof window !== "undefined" && window.ApplePaySession) {
  try {
    // @ts-ignore
    canMakeApplePayPayments = ApplePaySession.canMakePayments();
  } catch (err) {
    // pass
  }
}

export const isApplePayEligible = canMakeApplePayPayments;

export default payWithApplePay;
