import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import NeutralButton from "@/components/buttons/NeutralButton";
import QuotePreview from "@/features/quote-management/components/QuotePreview";
import DownloadIcon from "@mui/icons-material/DownloadRounded";
import { useRouter } from "next/router";
import { useTheme, useMediaQuery } from "@mui/material";
import { routes } from "@/config/api";
import { downloadFile } from "@/utils/download";
import useAppSelector from "@/hooks/useAppSelector";
import { selectQuote } from "@/features/quote-management/quote-management-selectors";
import { QuotePreviewState } from "@/types";
import { DateTime } from "luxon";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";

export default NiceModal.create(
  ({ previewState, onExited = null }: { previewState: QuotePreviewState; onExited?: any }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const router = useRouter();
    const quote = useAppSelector(selectQuote);
    const modal = useModal();

    const handleCloseModal = () => {
      modal.hide();
    };

    return (
      <Dialog
        open={modal.visible}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
        onClose={handleCloseModal}
        TransitionProps={{
          onExited: () => {
            if (typeof onExited === "function") {
              onExited();
            }
            modal.remove();
          },
        }}
      >
        <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
          <InlineContainer justifyContent="space-between">
            <Typography
              variant="h6"
              sx={{
                color: "primary.dark",
                paddingLeft: { xs: "30px", md: 0 },
                textAlign: { xs: "center", md: "left" },
                width: "100%",
              }}
            >
              Quote Preview
            </Typography>
            <IconButton
              aria-label="close"
              size="small"
              onClick={handleCloseModal}
              sx={{
                color: "grey.500",
                position: "relative",
                right: -6,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InlineContainer>
        </DialogTitle>
        <DialogContent sx={{ padding: "2rem 1.25rem" }} dividers>
          <QuotePreview previewState={previewState} sync />
        </DialogContent>
        <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
          <InlineContainer
            justifyContent={router?.query?.quoteId || quote?.id ? "space-between" : "center"}
            width="100%"
          >
            <NeutralButton
              disableElevation
              onClick={() => {
                modal.reject();
                handleCloseModal();
              }}
            >
              Close
            </NeutralButton>
            {(router?.query?.quoteId || quote?.id) && (
              <AffirmativeButton
                size="small"
                startIcon={<DownloadIcon fontSize="small" />}
                onClick={async () => {
                  await downloadFile(
                    routes.quote.downloadAbl((router?.query?.quoteId || quote?.id) as string),
                    `${quote?.account?.public_name}-Quote-${Math.floor(DateTime.now().toSeconds())}.pdf`,
                  );
                }}
              >
                Download .pdf
              </AffirmativeButton>
            )}
          </InlineContainer>
        </DialogActions>
      </Dialog>
    );
  },
);
