import MuiDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@/components/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import InlineContainer from "@/components/InlineContainer";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useMutation } from "@tanstack/react-query";
import getFieldErrors from "@/utils/getFieldErrors";
import { capitalizeFirstLetter } from "@/utils/stringUtils";
import { priceModifierValidationSchema } from "@/features/price-management/price-management-validation";
import { useFormik } from "formik";
import { useTheme, useMediaQuery, styled } from "@mui/material";
import {
  createPriceModifier,
  deletePriceModifier,
  editPriceModifier,
} from "@/features/price-management/price-management-queries";
import { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import NumberField from "@/components/NumberField";
import { DialogKeys } from "@/types";
import DashedDivider from "@/components/DashedDivider";
import SystemFeedback from "@/components/SystemFeedback";
import unformatNumber from "@/utils/unformatNumber";
import useMessage from "@/hooks/useMessage";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import NeutralButton from "@/components/buttons/NeutralButton";
import ErrorButton from "@/components/buttons/ErrorButton";
import { useSnackbar } from "notistack";
import TestId from "@/constants/testIds";

const ToggleButton = styled(MuiToggleButton)(
  ({ theme }) => `
  &.Mui-selected {
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.primary.dark};
    
    &:hover {
      background-color: ${theme.palette.primary.darker};
    }
  }
`,
);

type PriceModifierValues = {
  name?: string;
  value?: string;
  type?: string;
  mod?: string;
  description?: string;
  id?: string;
  is_adhoc?: boolean;
  from_invoice?: boolean;
};

export default NiceModal.create<{
  existingModifier: PriceModifierValues;
  priceItems: any[];
}>(({ existingModifier, priceItems }) => {
  const modal = useModal(DialogKeys.PRICE_MODIFIER);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = !isMobile;
  const [message, setMessage] = useMessage();
  const { enqueueSnackbar } = useSnackbar();

  const isNew = typeof existingModifier?.id === "undefined";
  // modify the useEffect so that it correctly sets the initial values
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      value: "",
      type: "DISCOUNT",
      mod: "fixed",
      description: "",
      is_adhoc: false,
    },
    validationSchema: priceModifierValidationSchema,
    onSubmit: async (values) => {
      if (values.is_adhoc) {
        handleSuccess();
        return;
      }

      if (!isNew) {
        editModifier({ ...values, id: existingModifier?.id });
        return;
      }

      createModifier(values);
    },
  });

  useEffect(() => {
    formik.setValues({
      name: existingModifier?.name ?? "",
      value: existingModifier?.value ?? "",
      mod: existingModifier?.mod ?? "fixed",
      type:
        unformatNumber(existingModifier?.value ?? "0") <= 0 || !unformatNumber(existingModifier?.value ?? "0")
          ? "DISCOUNT"
          : "SURCHARGE",
      description: existingModifier?.description ?? "",
      is_adhoc: existingModifier?.is_adhoc ?? false,
    });
  }, [existingModifier]);

  const handleCloseModal = () => {
    modal.reject({ priceItems });
    modal.hide();
    formik.resetForm();
  };

  const handleSuccess = () => {
    enqueueSnackbar(`${capitalizeFirstLetter(formik.values.type.toLowerCase())} successfully saved`, {
      variant: "success",
    });
    modal.resolve({ modifier: formik.values, priceItems });
    modal.hide();
  };

  const { mutate: createModifier, isLoading: isSavingModifier } = useMutation(
    (values: PriceModifierValues) => createPriceModifier(values),
    {
      onSuccess: handleSuccess,
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error?.response?.data?.message ?? "Cannot create price modifier.",
        });
      },
    },
  );

  const { mutate: editModifier, isLoading: isEditingModifier } = useMutation(
    (values: PriceModifierValues) => editPriceModifier(values),
    {
      onSuccess: handleSuccess,
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error?.response?.data?.message ?? "Cannot edit price modifier.",
        });
      },
    },
  );

  const { mutate: deleteModifier, isLoading: isDeletingModifier } = useMutation(
    (modId?: string) => deletePriceModifier(modId),
    {
      onSuccess: handleSuccess,
      onError: (error: any) => {
        setMessage({
          type: "error",
          message: error?.response?.data?.message ?? "Cannot delete price modifier.",
        });
      },
    },
  );

  return (
    <MuiDialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => {
          formik.resetForm();
          modal.remove();
        },
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="primary"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            {isNew ? "Create" : "Edit"} Discount or Surcharge
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "1.25rem" }} dividers>
        <form onSubmit={formik.handleSubmit} id="price-modifier-form">
          <SystemFeedback {...message} />
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="Discount or Surcharge Name"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            inputProps={{
              tabIndex: 0,
              "data-testid": TestId.PriceModifier.Name,
            }}
            {...formik.getFieldProps("name")}
            {...getFieldErrors("name", formik)}
          />
          <ToggleButtonGroup
            exclusive
            {...formik.getFieldProps("type")}
            size="small"
            id="type"
            sx={{ marginBottom: "1.5rem", width: "100%" }}
            onChange={(_, value) => {
              if (value !== null) {
                formik.setFieldValue("type", value);
              }
            }}
          >
            <ToggleButton
              name="debit"
              value="DISCOUNT"
              sx={{ flexBasis: "100%" }}
              {...{ "data-testid": TestId.PriceModifier.Discount }}
              tabIndex={1}
            >
              (-) Discount
            </ToggleButton>
            <ToggleButton
              name="credit"
              value="SURCHARGE"
              sx={{ flexBasis: "100%" }}
              {...{ "data-testid": TestId.PriceModifier.Surcharge }}
              tabIndex={2}
            >
              (+) Surcharge
            </ToggleButton>
          </ToggleButtonGroup>
          <InlineContainer sx={{ alignItems: "flex-start" }}>
            <ToggleButtonGroup
              exclusive
              {...formik.getFieldProps("mod")}
              id="mod"
              size="small"
              onChange={(_, value) => {
                if (value !== null) {
                  formik.setFieldValue("mod", value);
                }
              }}
            >
              <ToggleButton
                name="fixed"
                value="fixed"
                sx={{ width: "60px" }}
                {...{ "data-testid": TestId.PriceModifier.Fixed }}
                tabIndex={3}
              >
                $
              </ToggleButton>
              <ToggleButton
                name="percentage"
                value="percentage"
                sx={{ width: "60px" }}
                {...{ "data-testid": TestId.PriceModifier.Percentage }}
                tabIndex={4}
              >
                %
              </ToggleButton>
            </ToggleButtonGroup>
            <NumberField
              size="small"
              color="primary"
              variant="outlined"
              fullWidth
              label={formik?.values?.mod === "percentage" ? "% Percentage" : "$ Amount"}
              sx={{
                fontSize: "1rem",
                marginLeft: "1.25rem",
                marginBottom: "1.25rem",
                flexBasis: "100%",
              }}
              inputProps={{
                tabIndex: 5,
                "data-testid": TestId.PriceModifier.Amount,
              }}
              {...formik.getFieldProps("value")}
              {...getFieldErrors("value", formik)}
            />
          </InlineContainer>
          {!formik.values.is_adhoc && (
            <>
              <TextField
                size="small"
                color="primary"
                variant="outlined"
                fullWidth
                label="Description"
                sx={{ fontSize: "1rem" }}
                {...formik.getFieldProps("description")}
                inputProps={{
                  maxLength: 250,
                  tabIndex: 6,
                  "data-testid": TestId.PriceModifier.Description,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: formik?.values?.description?.length >= 250 ? "error.dark" : "grey.600",
                  margin: "0.5rem 0 0 0",
                  textAlign: "right",
                }}
              >
                {formik?.values?.description?.length ?? 0} / 250
              </Typography>
            </>
          )}
          {existingModifier?.from_invoice && (
            <>
              <DashedDivider spacing={3} />
              <Box sx={{ maxWidth: { xs: "75%", lg: "60%" } }} margin="0 auto 0.75rem">
                <InlineContainer justifyContent="space-between">
                  <Typography variant="body1">One-time discount or surcharge</Typography>
                  <Switch
                    checked={formik.values.is_adhoc}
                    onChange={() => formik.setFieldValue("is_adhoc", !formik.values.is_adhoc)}
                    {...{ "data-testid": TestId.PriceModifier.AdHoc }}
                  />
                </InlineContainer>
                <Typography variant="body2">
                  If you turn on this toggle, this item will <strong>NOT</strong> be available for future invoices.
                </Typography>
              </Box>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          {isNew && isDesktop && (
            <NeutralButton variant="outlined" onClick={handleCloseModal} sx={{ marginRight: 2 }} disableElevation>
              Cancel
            </NeutralButton>
          )}
          {!isNew && (
            <ErrorButton
              color="error"
              onClick={() => deleteModifier(existingModifier?.id)}
              disabled={isSavingModifier || isEditingModifier || isDeletingModifier}
              sx={{ width: 90 }}
              disableElevation
              loading={isDeletingModifier}
            >
              Delete
            </ErrorButton>
          )}
          <AffirmativeButton
            type="submit"
            form="price-modifier-form"
            data-testid={TestId.PriceModifier.Save}
            disabled={isSavingModifier || isEditingModifier || isDeletingModifier}
            sx={{
              whiteSpace: "nowrap",
              width: { xs: isNew ? "100%" : 170, md: 170 },
            }}
            loading={isSavingModifier || isEditingModifier}
          >
            Save
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </MuiDialog>
  );
});
