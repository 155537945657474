import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Box from "@mui/material/Box";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default NiceModal.create<{ title: string; content: any }>(({ title, content }) => {
  const modal = useModal();

  const handleCloseModal = () => {
    modal.hide();
  };

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      onClose={handleCloseModal}
      fullWidth
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        width: "100vw",
      }}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Box>{title}</Box>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon sx={{ color: "primary.dark" }} />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "1.25rem" }} dividers>
        <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: 1.5, fontSize: "16px" }}>
          {content}
        </Typography>
      </DialogContent>
    </Dialog>
  );
});
