import { FC } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import MuiChip, { ChipProps } from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { styled, Tooltip } from "@mui/material";

const Chip = styled(MuiChip)(
  ({ theme }) => `
	color: #333638;
	display: inline-flex;
	font-size: 12px;
	align-items: center;
	height: auto;
	justify-content: center;
	padding: ${theme.spacing(0.25, 0)};
	border-radius: 4px;
`,
);

type TagListProps = BoxProps & {
  tags: any[];
  max?: number;
  spacing?: number;
  showTitle?: boolean;
  title?: string;
  showEmptyMessage?: boolean;
  emptyMessage?: string;
  chipSize?: ChipProps["size"];
  onDelete?: (tag: any) => void;
};

const TagList: FC<TagListProps> = ({
  tags,
  spacing = 1.5,
  max = 100,
  onDelete = null,
  showTitle = false,
  title = "Tag List",
  chipSize = "medium",
  showEmptyMessage = false,
  emptyMessage = "You have not yet added any tags.",
  ...props
}) => {
  return (
    <>
      {showTitle && (
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          {title}
        </Typography>
      )}
      {tags?.length ? (
        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" gap={spacing} {...props}>
          {tags?.map((tag, index) =>
            index < max && tag?.name?.length ? (
              <Chip
                color="primary"
                variant="outlined"
                size={chipSize}
                key={`tag-${tag?.name}-${tag?.id ?? index}`}
                label={tag?.name}
                sx={{
                  fontSize: chipSize === "small" ? "0.85rem" : "0.925rem",
                  textTransform: "none",
                }}
                {...(onDelete === null
                  ? {}
                  : {
                      onDelete: () => onDelete(tag),
                    })}
              />
            ) : null,
          )}
          {tags?.length > max ? (
            <Tooltip
              title={tags?.map((tag, index) =>
                index < max ? undefined : (
                  <Typography
                    key={tag.name}
                    variant="body2"
                    sx={{
                      display: "inline-block",
                      marginRight: tags?.length - 1 === index ? 0 : "0.25rem",
                    }}
                  >{`${tag.name}${tags?.length - 1 === index ? "" : ", "}`}</Typography>
                ),
              )}
            >
              <Typography variant="body2">+ {tags?.length - max} More</Typography>
            </Tooltip>
          ) : null}
        </Box>
      ) : (
        showEmptyMessage && (
          <Typography variant="body2" marginTop="0.5rem">
            {emptyMessage}
          </Typography>
        )
      )}
    </>
  );
};

export default TagList;
