import Dialog, { DialogContent, DialogActions } from "@/components/Dialog";
import TextField from "@/components/TextField";
import Select from "@/components/Select";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InlineContainer from "@/components/InlineContainer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import getFieldErrors from "@/utils/getFieldErrors";
import { customerValidation } from "@/features/customer-management/customer-management-validation";
import { useFormik } from "formik";
import { useTheme, useMediaQuery } from "@mui/material";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { createCustomer, saveCustomer } from "@/features/customer-management/customer-management-queries";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import Link from "@/components/Link";
import Divider from "@mui/material/Divider";
import { DialogKeys, Query, stateNamesAndAbbrs } from "@/types";
import { useSnackbar } from "notistack";
import PhoneField from "@/components/PhoneField";
import SystemFeedback from "@/components/SystemFeedback";
import useMessage from "@/hooks/useMessage";
import { intlFormat } from "@/utils/phone";
import NeutralButton from "@/components/buttons/NeutralButton";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import TestId from "@/constants/testIds";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import CloseRounded from "@mui/icons-material/CloseRounded";
import AddressAutocomplete from "@/components/AddressAutocomplete";
import DownArrowIcon from "@mui/icons-material/KeyboardArrowDownRounded";

export default NiceModal.create((existingValues: any) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = !isMobile;
  const modal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useMessage();
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showBulkImport, setShowBulkImport] = useState(true);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      cell_phone: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip_code: "",
    },
    validate: customerValidation,
    onSubmit: async (values) => {
      const cleaned = {
        ...values,
        cell_phone: values.cell_phone.includes("_") ? "" : values.cell_phone,
      };
      // If is new customer
      if (!Boolean(existingValues?.id)) {
        newCustomer(cleaned);
        return;
      }

      editCustomer.mutate({ ...cleaned, id: existingValues?.id });
    },
  });
  useEffect(() => {
    formik.setValues({
      name: existingValues?.name ?? "",
      email: existingValues?.email ?? "",
      cell_phone: existingValues?.cell_phone ?? "",
      address1: existingValues?.address1 ?? "",
      address2: existingValues?.address2 ?? "",
      city: existingValues?.city ?? "",
      state: existingValues?.state ?? "",
      zip_code: existingValues?.zip_code ?? "",
    });
  }, [existingValues]);

  const handleCloseModal = () => {
    modal.reject();
    modal.hide();
    formik.resetForm();
  };

  const { mutate: newCustomer, isLoading: isSavingCustomer } = useMutation(createCustomer, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([Query.account.CUSTOMERS_PAGINATION]);
      queryClient.invalidateQueries([Query.account.INVOICE_BUILDER_ALL_CUSTOMERS]);
      enqueueSnackbar("Customer created successfully", {
        variant: "success",
      });
      modal.resolve(data?.[0]);
      modal.hide();
      formik.resetForm();
    },
    onError: (error: any) => {
      setMessage({
        type: "error",
        message: error?.response?.data?.message ?? "Could not create customer using the information provided.",
      });
    },
  });

  const editCustomer = useMutation(saveCustomer, {
    onSuccess: () => {
      queryClient.invalidateQueries([Query.account.CUSTOMERS_PAGINATION]);
      queryClient.invalidateQueries([Query.account.INVOICE_BUILDER_ALL_CUSTOMERS]);
      handleCloseModal();
      enqueueSnackbar("Customer updated successfully", {
        variant: "success",
      });
    },
    onError: (error: any) => {
      setMessage({
        type: "error",
        message: error?.response?.data?.message ?? "Could not edit customer using the information provided.",
      });
    },
  });

  return (
    <Dialog modal={modal} maxWidth="sm" title={`${existingValues?.name ? "Edit" : "Create"} Customer`}>
      <DialogContent>
        {showBulkImport && (
          <Alert
            icon={<InfoOutlined fontSize="small" />}
            color="info"
            variant="standard"
            sx={{
              margin: "0 0 1rem 0",
              textAlign: "left",
              "& > .MuiAlert-action": {
                paddingTop: "2px",
              },
            }}
            action={
              <IconButton size="small" onClick={() => setShowBulkImport(false)}>
                <CloseRounded fontSize="small" />
              </IconButton>
            }
          >
            To add multiple customers at once,{" "}
            <Link
              onClick={() => {
                formik.resetForm();
                handleCloseModal();
                NiceModal.show(DialogKeys.CUSTOMER_BULK_UPLOAD);
              }}
              sx={{ cursor: "pointer" }}
            >
              bulk import
            </Link>{" "}
            a customer list.
          </Alert>
        )}
        <form onSubmit={formik.handleSubmit} id="customer-form">
          <SystemFeedback {...message} />
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="Customer Name"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            {...formik.getFieldProps("name")}
            {...getFieldErrors("name", formik)}
            inputProps={{
              "data-testid": TestId.Customer.Name,
            }}
          />
          <TextField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            type="email"
            label="Email"
            sx={{ fontSize: "1rem", marginBottom: "1.25rem" }}
            {...formik.getFieldProps("email")}
            {...getFieldErrors("email", formik)}
            onBlur={(e) => {
              if (e.target.value === "") {
                formik.setFieldTouched("email", false);
              } else {
                formik.setFieldTouched("email", true);
              }
            }}
            inputProps={{
              "data-testid": TestId.Customer.Email,
            }}
          />
          <PhoneField
            size="small"
            color="primary"
            variant="outlined"
            fullWidth
            label="Mobile Phone #"
            sx={{ fontSize: "1rem" }}
            {...formik.getFieldProps("cell_phone")}
            onChange={(e) => {
              formik.setFieldValue("cell_phone", intlFormat(e.target.value));
            }}
            {...getFieldErrors("cell_phone", formik)}
            onBlur={(e) => {
              if (e.target.value === "" || e.target.value.includes("_")) {
                formik.setFieldValue("cell_phone", "");
                formik.setFieldTouched("cell_phone", false);
              } else {
                formik.setFieldTouched("cell_phone", true);
              }
            }}
            inputProps={{
              "data-testid": TestId.Customer.Phone,
            }}
          />
          <Divider
            flexItem
            sx={{
              borderStyle: "dashed",
              borderBottomWidth: "2px !important",
              display: "block",
              margin: "1.5rem 0 !important",
              padding: 0,
            }}
          />
          <InlineContainer alignItems="center">
            <Typography
              variant="h6"
              sx={{
                cursor: "pointer",
                fontSize: "1rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                formik.setFieldValue("address1", "");
                formik.setFieldValue("address2", "");
                formik.setFieldValue("city", "");
                formik.setFieldValue("state", "");
                formik.setFieldValue("zip_code", "");

                setShowAdditionalFields(!showAdditionalFields);
              }}
            >
              Additional Information
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                formik.setFieldValue("address1", "");
                formik.setFieldValue("address2", "");
                formik.setFieldValue("city", "");
                formik.setFieldValue("state", "");
                formik.setFieldValue("zip_code", "");

                setShowAdditionalFields(!showAdditionalFields);
              }}
            >
              <DownArrowIcon
                fontSize="small"
                sx={{
                  rotate: showAdditionalFields ? "180deg" : "0",
                  transition: "rotate 250ms",
                }}
              />
            </IconButton>
          </InlineContainer>
          <Collapse in={showAdditionalFields}>
            <Typography variant="body1" sx={{ fontWeight: "bold", margin: "1rem 0 0 0" }}>
              Address (Optional)
            </Typography>
            <Grid container pt="1rem" spacing={2}>
              <Grid item xs={12}>
                <AddressAutocomplete
                  placeholder="Street Address"
                  onPlaceSelected={(place) => {
                    formik.setFieldValue("address1", place.address1);
                    formik.setFieldValue("address2", place.address2);
                    formik.setFieldValue("city", place.city);
                    formik.setFieldValue("state", place.state);
                    formik.setFieldValue("zip_code", place.zip);
                  }}
                  {...formik.getFieldProps("address1")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="address2"
                  label="Suite, Unit or Apt"
                  size="small"
                  autoComplete="address-line2"
                  {...formik.getFieldProps("address2")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="City"
                  size="small"
                  autoComplete="address-level2"
                  {...formik.getFieldProps("city")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  label="State"
                  autoComplete="address-level1"
                  InputLabelProps={{
                    style: {
                      color: "primary.dark",
                    },
                  }}
                  fullWidth
                  {...formik.getFieldProps("state")}
                  sx={{
                    flexBasis: "calc(65% - 1.5rem)",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a State</em>
                  </MenuItem>
                  {stateNamesAndAbbrs.map((state) => (
                    <MenuItem key={state.ABBR} value={state.ABBR}>
                      {state.NAME}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="zip_code"
                  label="Zip Code"
                  size="small"
                  autoComplete="postal-code"
                  autoFocus
                  {...formik.getFieldProps("zip_code")}
                  inputProps={{ maxLength: 5 }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Collapse>
        </form>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          {isDesktop && (
            <NeutralButton onClick={handleCloseModal} sx={{ marginRight: 2 }} disableElevation>
              Cancel
            </NeutralButton>
          )}
          <AffirmativeButton
            type="submit"
            form="customer-form"
            disabled={isSavingCustomer}
            sx={{ whiteSpace: "nowrap", width: { xs: "100%", md: 170 } }}
            loading={isSavingCustomer}
            data-testid={TestId.Customer.Save}
          >
            Create
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
