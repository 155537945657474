import BaseButton from "@/components/buttons/BaseButton";
import CircularProgress from "@mui/material/CircularProgress";

const ErrorButton = ({ children, ...props }) => {
  return (
    <BaseButton
      color="error"
      variant="contained"
      {...props}
      sx={(theme) => ({
        ...(Boolean(Object.keys(props?.sx ?? {}).length) ? props.sx : {}),
        color: theme.palette.getContrastText(theme.palette.error.main),
      })}
      disableElevation
    >
      {props?.loading ? (
        <CircularProgress size={24} thickness={4} color="inherit" sx={{ margin: "0.125rem" }} />
      ) : (
        children
      )}
    </BaseButton>
  );
};

export default ErrorButton;
