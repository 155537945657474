import { initApi, routes } from "@/config/api";
import { PriceItemValues } from "@/types";
import { downloadFromBlob } from "@/utils/download";
import { getIntFromDollars, getIntFromPercent } from "@/utils/calculations";
import unformatNumber from "@/utils/unformatNumber";
import { GetServerSidePropsContext } from "next";

export const fetchPriceItems = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.price.items(filters))
    .then(({ data }) => data?.data);

export const fetchAllPriceItems = async (req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.price.allItems)
    .then(({ data }) => data?.data);

export const createPriceItem = async (values: PriceItemValues) =>
  await initApi()
    .post(routes.price.item, {
      name: values?.name,
      price: getIntFromDollars(unformatNumber(values?.price ?? 0)),
      description: values?.description,
      is_adhoc: values?.is_adhoc ?? false,
      is_track_inventory: values?.is_track_inventory ?? false,
      initial_inventory_on_hand: unformatNumber(values?.inventory_on_hand ?? 0),
      inventory_reorder_point: unformatNumber(values?.inventory_reorder_point ?? 0),
    })
    .then(({ data }) => data?.data);

export const editPriceItem = async ({ id, ...values }) =>
  await initApi()
    .patch(`${routes.price.item}/${id}`, {
      name: values?.name,
      price: getIntFromDollars(unformatNumber(values?.price)),
      description: values?.description,
      is_adhoc: values?.is_adhoc ?? false,
      is_track_inventory: values?.is_track_inventory,
      inventory_on_hand: unformatNumber(values?.inventory_on_hand),
      inventory_reorder_point: unformatNumber(values?.inventory_reorder_point),
    })
    .then(({ data }) => data?.data);

export const setInitialItemInventory = async ({
  id,
  ...values
}: Required<Pick<PriceItemValues, "id" | "is_track_inventory" | "inventory_on_hand" | "inventory_reorder_point">>) =>
  await initApi()
    .patch(`${routes.price.item}/${id}`, {
      is_track_inventory: values?.is_track_inventory,
      initial_inventory_on_hand: unformatNumber(values?.inventory_on_hand ?? 0),
      inventory_reorder_point: unformatNumber(values?.inventory_reorder_point ?? 0),
    })
    .then(({ data }) => data?.data);

export const deletePriceItem = async (userId?: string) => {
  if (!userId) {
    return null;
  }

  return await initApi().delete(routes.price.delete.item(userId));
};

export const updateInventory = async (priceItemId: string, changeAmount: number) =>
  await initApi()
    .patch(routes.price.updateInventory(priceItemId), {
      inventoryChange: unformatNumber(changeAmount),
    })
    .then(({ data }) => data?.data);

export const fetchPriceModifiers = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.price.modifiers(filters))
    .then(({ data }) => data?.data);

export const fetchAllPriceModifiers = async (req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.price.allModifiers)
    .then(({ data }) => data?.data);

export const createPriceModifier = async (values) =>
  await initApi()
    .post(routes.price.modifier, {
      name: values?.name,
      description: values?.description,
      ...(values?.mod === "fixed"
        ? {
            value_flat: getIntFromDollars(unformatNumber(values?.value)) * (values?.type === "DISCOUNT" ? -1 : 1),
          }
        : {
            value_pct: getIntFromPercent(unformatNumber(values?.value)) * (values?.type === "DISCOUNT" ? -1 : 1),
          }),
    })
    .then(({ data }) => data?.data);

export const editPriceModifier = async (values) => {
  return await initApi()
    .patch(`${routes.price.modifier}/${values.id}`, {
      name: values?.name,
      type: values?.type,
      description: values?.description,
      ...(values?.mod === "fixed"
        ? {
            value_flat:
              getIntFromDollars(unformatNumber(Math.abs(values?.value))) * (values?.type === "DISCOUNT" ? -1 : 1),
          }
        : {
            value_pct:
              getIntFromPercent(unformatNumber(Math.abs(values?.value))) * (values?.type === "DISCOUNT" ? -1 : 1),
          }),
    })
    .then(({ data }) => data?.data);
};

export const deletePriceModifier = async (userId) => await initApi().delete(routes.price.delete.modifier(userId));

export const exportPriceItems = async (filters = {}) => {
  return await initApi()
    .get(`${routes.price.export.item(filters)}`, {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));
};

export const exportPriceModifiers = async (filters) => {
  return await initApi()
    .get(`${routes.price.export.modifiers(filters)}`, {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));
};

export const fetchCustomerCategories = async (customerId: string, req?: GetServerSidePropsContext["req"]) => {
  return await initApi(req)
    .get(routes.customer.categories(customerId))
    .then(({ data }) => data?.data);
};

export const importPriceItemList = async (file: File) =>
  await initApi()
    .post(
      routes.price.itemsImport,
      { file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
    .then(() => true)
    .catch(({ response }) => response?.data);
