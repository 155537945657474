import { jwtDecode } from "jwt-decode";

const decodeToken = (token: string) => {
  return jwtDecode<{
    userId: string;
    accountId?: string;
    partnershipId?: string;
    adminRole?: "MEMBER" | "OWNER" | null;
    oid?: string;
  }>(token);
};

export default decodeToken;
