import { PaymentBrand, FlattenedWalletInstrument, PaymentMethodTypeEnum, WalletInstrument } from "@/types";
import { capitalizeFirstLetter } from "@/utils/stringUtils";
import validateCard from "card-validator";
import { hasIntersection } from "./arrayManipulation";

export const formatWalletInstruments = (walletInstruments: WalletInstrument[] = []): FlattenedWalletInstrument[] => {
  return walletInstruments
    .map((instrument: any) => {
      const { card, ach, ...rest } = instrument;
      return {
        ...rest,
        ...card,
        ...ach,
        type: card ? PaymentMethodTypeEnum.CREDIT_CARD : PaymentMethodTypeEnum.BANK_ACCOUNT,
      };
    })
    .filter((i) => i.id);
};

export const getInstrumentLabel = (instrument: FlattenedWalletInstrument, showDetail = false) => {
  let bankName = "Bank account";

  let detail = "";

  if (
    instrument?.type === PaymentMethodTypeEnum.BANK_ACCOUNT &&
    instrument?.bank_name &&
    instrument?.bank_name !== "Unknown"
  ) {
    bankName = instrument?.bank_name?.[0]?.toUpperCase() + instrument?.bank_name?.slice(1)?.toLowerCase();
  } else if (
    instrument?.type === PaymentMethodTypeEnum.CREDIT_CARD &&
    instrument?.exp_month &&
    instrument?.brand !== PaymentBrand.UNKNOWN
  ) {
    bankName = capitalizeFirstLetter(instrument?.brand);
    if (showDetail) {
      detail = ` (exp ${instrument?.exp_month < 10 ? "0" : ""}${instrument?.exp_month}/${instrument?.exp_year % 100})`;
    }
  }

  if (instrument?.last_four_digits) {
    return `${bankName} ****${instrument?.last_four_digits}` + detail;
  }

  return `${bankName} ` + detail;
};

export const beautifyCardNumber = (card: string): string => {
  let c = card.replace(/[^0-9]+/g, "");
  const validCard = validateCard.number(c);
  const maxCardLength = validCard?.card?.lengths ? Math.max(...(validCard?.card?.lengths || [])) : 25;

  c = c.slice(0, maxCardLength);

  const cardArr: string[] = [];
  const gaps = validCard?.card?.gaps || [];

  let formattedCard = c;

  if (gaps.length > 0) {
    gaps.forEach((g, idx) => {
      cardArr.push(c.slice(idx === 0 ? 0 : gaps[idx - 1], g));

      if (idx === gaps.length - 1) {
        cardArr.push(c.slice(g));
      }
    });

    formattedCard = cardArr.join(" ").trim();
  }

  return formattedCard;
};

export const isAmex = (card?: string): boolean => {
  if (!card) {
    return false;
  }

  const c = card.replace(/[^0-9]+/g, "");
  const validCard = validateCard.number(c);
  return validCard?.card?.type === "american-express";
};

export const isSelectedWalletInstrumentUnverified = (
  walletInstruments: FlattenedWalletInstrument[],
  walletInstrumentId: string,
) => {
  //* If the selected wallet instrument is unverified, we need to show the plaid flow
  const matchingWalletInstrument = walletInstruments?.find(
    (walletInstrument) => walletInstrument.id === walletInstrumentId,
  );

  //* This should only return true if the walletInstrument is a bank account
  //* Otherwise we return false in the case of card
  //* Or undefined in the case of a no instrument

  if (!matchingWalletInstrument || matchingWalletInstrument?.type !== PaymentMethodTypeEnum.BANK_ACCOUNT) {
    return false;
  }
  return Boolean("plaid_link_token" in matchingWalletInstrument && !!matchingWalletInstrument?.plaid_link_token);
};

export const isWalletInstrumentUnverified = (instrument: FlattenedWalletInstrument) => {
  return "plaid_link_token" in instrument && !!instrument?.plaid_link_token;
};

export const shouldPayTransactionFee = (paymentTypes) =>
  hasIntersection(paymentTypes, [PaymentMethodTypeEnum.CREDIT_CARD, PaymentMethodTypeEnum.APPLE_PAY]);
