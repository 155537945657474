import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface WalletState {
  walletId: string;
  walletInstrumentId: string;
  walletInstrumentPlaidLinkType: string;
  plaidLinkToken: string;
  showPlaidWidget: boolean;
  account_type: string;
  account_subtype: string;
}

export const initialState: WalletState = {
  walletId: "",
  walletInstrumentId: "",
  walletInstrumentPlaidLinkType: "",
  plaidLinkToken: "",
  showPlaidWidget: false,
  account_type: "",
  account_subtype: "",
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletId: (state, action: PayloadAction<string>) => {
      state.walletId = action.payload;
    },
    setWalletInstrumentId: (state, action: PayloadAction<string>) => {
      state.walletInstrumentId = action.payload;
    },
    setWalletInstrumentPlaidLinkType: (state, action: PayloadAction<string>) => {
      state.walletInstrumentPlaidLinkType = action.payload;
    },
    setLinkToken: (state, action: PayloadAction<string>) => {
      state.plaidLinkToken = action.payload;
    },
    tweakPlaidWidget: (state) => {
      state.showPlaidWidget = !state.showPlaidWidget;
    },
    setAccountType: (state, action?: PayloadAction<any>) => {
      state.account_type = action?.payload.account_type || "";
      state.account_subtype = action?.payload.account_subtype || "";
    },
    resetWallet: (state) => {
      state.walletInstrumentId = "";
      state.walletInstrumentPlaidLinkType = "";
      state.plaidLinkToken = "";
      state.account_type = "";
      state.account_subtype = "";
    },
  },
});

export const {
  setWalletId,
  setWalletInstrumentId,
  setWalletInstrumentPlaidLinkType,
  setLinkToken,
  tweakPlaidWidget,
  resetWallet,
  setAccountType,
} = walletSlice.actions;

export default walletSlice.reducer;
