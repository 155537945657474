import { useQuery } from "@tanstack/react-query";
import { getUser } from "@/features/auth/auth-queries";
import { Query, User } from "@/types";

interface UseUser {
  user?: User;
}

const useUser = (options = {}): UseUser => {
  const { data: user } = useQuery<User, Error>([Query.user.USER_INFO], () => getUser(), {
    retry: 0,
    keepPreviousData: false,
    staleTime: 120000, // 2 min
    refetchOnWindowFocus: false,
    ...options,
  });

  return {
    user,
  };
};

export default useUser;
