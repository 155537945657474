import TextField from "@/components/TextField";
import Box from "@mui/material/Box";
import InlineContainer from "@/components/InlineContainer";
import { removeAtIndex } from "@/utils/arrayManipulation";
import Select from "@/components/Select";
import Typography from "@mui/material/Typography";
import NeutralButton from "@/components/buttons/NeutralButton";
import MenuItem from "@mui/material/MenuItem";
import PlusIcon from "@mui/icons-material/AddRounded";
import TrashIcon from "@/components/icons/TrashIcon";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { BusinessLinkType } from "@/types";

const BusinessType = ({ formik }) => {
  const websiteLinks = [
    BusinessLinkType.OTHER,
    BusinessLinkType.WEBSITE,
    BusinessLinkType.GOOGLE_PAGES,
    BusinessLinkType.LINKEDIN,
  ];

  return (
    <>
      <Typography variant="h4">Where can we find your business online?</Typography>
      <Typography variant="body1" marginTop="0.5rem">
        Please share your website and social media accounts so we can validate your identity.
      </Typography>
      <Box marginTop="1.5rem">
        {(formik?.values?.links || [])?.map((link, index) => {
          return (
            <>
              <Box
                key={`${link?.platform}-${index}`}
                sx={{
                  margin: "1.5rem auto",
                }}
              >
                <Box textAlign="left" width="100%">
                  <InlineContainer alignItems="center" margin="0.5rem 0">
                    <Select
                      size="small"
                      fullWidth
                      sx={{ marginRight: "0.5rem" }}
                      {...formik.getFieldProps(`links[${index}].platform`)}
                    >
                      <MenuItem value={BusinessLinkType.WEBSITE}>Business Website</MenuItem>
                      <MenuItem value={BusinessLinkType.YELP}>Yelp</MenuItem>
                      <MenuItem value={BusinessLinkType.TWITTER}>Twitter</MenuItem>
                      <MenuItem value={BusinessLinkType.INSTAGRAM}>Instagram</MenuItem>
                      <MenuItem value={BusinessLinkType.FACEBOOK}>Facebook</MenuItem>
                      <MenuItem value={BusinessLinkType.GOOGLE_PAGES}>Google Pages</MenuItem>
                      <MenuItem value={BusinessLinkType.LINKEDIN}>LinkedIn</MenuItem>
                      <MenuItem value={BusinessLinkType.TIKTOK}>TikTok</MenuItem>
                      <MenuItem value={BusinessLinkType.OTHER}>Other</MenuItem>
                    </Select>

                    <IconButton
                      size="small"
                      onClick={() => {
                        const updatedLinks = removeAtIndex(index, formik.values.links);

                        formik.setFieldValue("links", updatedLinks);
                      }}
                    >
                      <TrashIcon color="error" fontSize="small" />
                    </IconButton>
                  </InlineContainer>
                </Box>
                <Box width="100%">
                  <TextField
                    size="small"
                    InputProps={{
                      startAdornment: websiteLinks.includes(link.platform) ? (
                        !link?.url?.startsWith("http") && <InputAdornment position="start">https://</InputAdornment>
                      ) : (
                        <InputAdornment position="start">@</InputAdornment>
                      ),
                    }}
                    fullWidth
                    {...formik.getFieldProps(`links[${index}].url`)}
                    onBlur={(event) => {
                      let value = event.target.value;

                      formik.setFieldValue(`links[${index}].url`, value);
                    }}
                  />
                </Box>
              </Box>
            </>
          );
        })}
        <NeutralButton
          size="small"
          startIcon={<PlusIcon fontSize="small" />}
          sx={{ marginBottom: "0.75rem" }}
          onClick={() => {
            formik.setFieldValue("links", formik.values.links.concat([{ platform: "website", url: "" }]));
          }}
        >
          Add a link
        </NeutralButton>
      </Box>
    </>
  );
};

export default BusinessType;
