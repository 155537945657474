export const isObject = (value) => typeof value === "object" && value !== null && !Array.isArray(value);

const stringifyQueryParams = (params) => {
  if (!params || Object.keys(params).length === 0) {
    return "";
  }

  const stringified = Object.keys(params)
    .map((key) => {
      if (Array.isArray(params[key]) && params?.[key]?.length) {
        return params[key].flatMap((v) => `${key}[]=${v}`).join("&");
      } else if (isObject(params[key])) {
        const obj = Object.entries(params[key])
          .map(([subkey, subvalue]) => {
            return Boolean(subvalue) ? `${key}[${subkey}]=${subvalue}` : null;
          })
          .filter((v) => Boolean(v))
          .join("&");
        return obj;
      } else if (Boolean(params[key]) && !Array.isArray(params[key])) {
        return `${key}=${encodeURIComponent(params[key])}`;
      } else {
        return null;
      }
    })
    .filter((v) => Boolean(v))
    .join("&");

  return `?${stringified}`;
};

export default stringifyQueryParams;
