// Each set of enums below should be grouped by the page they are used on
// The exception to this rule is if the component is considered generic and can
// be used on multiple pages.

// For dynamic test IDs, use the following format: <component>-<dynamic value>
// When using a dynamic test ID, the preference is to use the dynamicTestId function.

/** Generic Test IDs */
enum Dialog {
  ConfirmButton = "button-confirm",
  InvoiceAddCustomer = "button-invoice-add-customer",
  InvoiceSavePriceItem = "button-invoice-save-price-item",
  InvoiceAddPriceItemToInvoice = "button-invoice-add-price-item-to-invoice",
  InvoiceAddPriceModifierToInvoice = "button-invoice-add-price-modifier-to-invoice",
  InvoicePriceItemCheckbox = "button-invoice-price-item-checkbox-",
  InvoicePriceModifierCheckbox = "button-invoice-price-modifier-checkbox",
  InvoiceCustomerCheckbox = "invoice-customer-checkbox-",
  InvoiceConfirmSendInvoice = "button-invoice-confirm-send-invoice",
  InvoiceConfirmSentSuccess = "button-invoice-confirm-sent-success",
}

enum Quantity {
  Input = "input-qty",
  Decrease = "button-qty-decrease",
  Increase = "button-qty-increase",
}

enum System {
  Feedback = "system-feedback",
}

enum Input {
  EmailOrPhone = "input-email-or-phone",
  Password = "input-password",
  Submit = "input-form-submit",
  Continue = "input-form-continue",
}

/** Page Specific Test IDs */
enum Login {
  ForgotPassword = "link-forgot-password",
  SignUp = "link-sign-up",
}

enum PriceItem {
  Table = "table-price-item",
  Row = "table-price-item-row-",
  MoreMenu = "more-menu-",
  MoreMenuEdit = "edit-",
  MoreMenuDelete = "delete-",
  Name = "input-price-item-name",
  Amount = "input-price-item-amount",
  Description = "input-price-item-description",
  Save = "button-price-item-save",
  Create = "button-create-price-item",
  InventoryToggle = "switch-price-item-inventory",
  InventoryOnHand = "switch-inventory-on-hand",
  InventoryReorderPoint = "switch-inventory-reorder-point",
}

enum PriceModifier {
  Table = "table-price-modifier",
  Row = "table-price-modifier-row-",
  MoreMenu = "more-menu-",
  MoreMenuEdit = "edit-",
  MoreMenuDelete = "delete-",
  Name = "input-price-modifier-name",
  Amount = "input-price-modifier-amount",
  Description = "input-price-modifier-desc",
  Fixed = "radio-price-modifier-fixed",
  Percentage = "radio-price-modifier-percentage",
  Save = "button-price-modifier-save",
  Discount = "radio-price-modifier-discount",
  Surcharge = "radio-price-modifier-surcharge",
  AdHoc = "switch-price-modifier-adhoc",
  Create = "button-create-price-modifier",
}

enum ChooseAccount {
  Account = "button-account-",
}

enum Customer {
  Name = "input-customer-name",
  Email = "input-customer-email",
  Phone = "input-customer-phone",
  Save = "button-customer-save",
  Create = "button-create-customer",
  Table = "table-customer",
  Row = "table-customer-row-",
  MoreMenu = "more-menu-",
  MoreMenuEdit = "more-menu-edit-",
  MoreMenuDelete = "more-menu-delete-",
  MoreMenuDeactivate = "more-menu-deactivate-",
  MoreMenuActivate = "more-menu-activate-",
  Delete = "button-customer-delete",
  Link = "link-customer-",
}

enum Invoice {
  AddCustomer = "button-add-customer",
  AddPriceItem = "invoice-add-price-item",
  AddPriceModifier = "invoice-add-price-modifier",
  SaveSendInvoice = "invoice-save-send",
  RecipientMessage = "invoice-recipient-message",
  InvoiceNumber = "invoice-header-invoice-number",
}

enum Partner {
  TeamsTable = "table-partner-teams",
  TeamMembers = "table-partner-team-members",
  Team = "table-partner-team",
  Member = "table-partner-team-member",
}

export const dynamicTestId = (testId: string, dynamicValue: string | number) => `${testId}${dynamicValue}`;

const TestId = {
  ChooseAccount,
  Quantity,
  Dialog,
  System,
  Login,
  PriceItem,
  PriceModifier,
  Input,
  Customer,
  Invoice,
  Partner,
};

export default TestId;
