import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Typography from "@mui/material/Typography";
import InlineContainer from "@/components/InlineContainer";
import { useFormik } from "formik";
import { useTheme, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import SystemFeedback from "@/components/SystemFeedback";
import useUser from "@/features/auth/useUser";
import NeutralButton from "@/components/buttons/NeutralButton";
import { PinInput } from "react-input-pin-code";
import { verifyPhone, resendPhoneVerification } from "@/features/auth/auth-queries";
import { HttpStatus, Query } from "@/types";
import ChangePhoneNumberDialog from "@/features/user-management/components/ChangePhoneNumberDialog";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import SimpleLink from "@/components/SimpleLink";
import useMessage from "@/hooks/useMessage";
import { unformatPhoneNumber } from "@/features/user-management/user-management-adapters";
import { useQueryClient } from "@tanstack/react-query";

export default NiceModal.create(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const queryClient = useQueryClient();
  const modal = useModal();
  const { user } = useUser();
  const [message, setMessage] = useMessage();

  const formik = useFormik({
    initialValues: { code: ["", "", "", "", "", ""] },
    onSubmit: async (values) => {
      try {
        const verifyPhoneResponse = await verifyPhone({
          user_id: user?.id,
          code: values?.code?.join(""),
        });

        if (verifyPhoneResponse?.status === HttpStatus.SUCCESS_NO_CONTENT) {
          queryClient.invalidateQueries([Query.user.USER_INFO]);
          modal.resolve();
          modal.hide();
          setMessage({
            type: "success",
            message: `Your phone number has been verified!`,
          });
        }
      } catch (error: any) {
        setMessage({ type: "error", message: error?.response?.data?.message });
      }
    },
  });

  const handleResend = async () => {
    formik.resetForm();
    setMessage(null);
    try {
      await resendPhoneVerification(unformatPhoneNumber(user?.cell_phone as string));
    } catch (error: any) {
      setMessage({ type: "error", message: error?.response?.data?.message });
    }
  };

  const handleCloseModal = () => {
    modal.hide();
  };

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      fullScreen={isMobile}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            color="common.black"
            variant="h6"
            sx={{
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Verify Phone Number
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: (theme) => theme.palette.grey[500],
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" sx={{ color: "primary.dark" }} />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent sx={{ padding: "1.25rem" }} dividers>
        <form onSubmit={formik.handleSubmit} id="invite-member-form">
          <SystemFeedback {...message} />
          <Typography variant="body1" marginBottom={"1.25rem"} textAlign="center">
            Enter the code sent by text message to {user?.cell_phone}
          </Typography>
          <InlineContainer flexDirection="row" justifyContent="center" margin="2rem 0 2.5rem">
            <PinInput
              autoFocus
              showState={false}
              placeholder=""
              size="lg"
              autoComplete="one-time-code"
              values={formik.values.code}
              onChange={(_value, _index, values) => formik.setValues({ code: values })}
            />
          </InlineContainer>
        </form>
        <Typography
          variant="body2"
          sx={{
            fontSize: "13px",
            margin: "1.25rem 0 1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Problems receiving the code?
          <SimpleLink component="button" type="button" onClick={handleResend} sx={{ marginLeft: "0.35rem" }}>
            Resend
          </SimpleLink>
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton
            onClick={() => {
              NiceModal.show(ChangePhoneNumberDialog);
              handleCloseModal();
            }}
            disableElevation
          >
            Cancel
          </NeutralButton>
          <AffirmativeButton onClick={() => formik.handleSubmit()}>Verify</AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
