import { initApi, routes } from "@/config/api";
import { Quote, QuoteAction, QuoteCustomer } from "@/types";
import { downloadFromBlob } from "@/utils/download";
import { adaptOutgoingQuote } from "@/features/quote-management/quote-management-adapters";
import { GetServerSidePropsContext } from "next";

export const saveQuote = async (quote) => {
  return await initApi()
    .post(routes.quote.save, adaptOutgoingQuote(quote))
    .then(({ data }) => data?.data);
};

export const updateQuote = async (quoteId, payload) => {
  return await initApi()
    .patch(routes.quote.id(quoteId), payload)
    .then(({ data }) => data?.data);
};

export const fetchAdminAllQuotes = async (filters, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.admin.quotes.list(filters))
    .then(({ data }) => data?.data);

export const exportAdminAllQuotes = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.admin.quotes.export(filters))
    .then(({ data }) => data?.data);

export const fetchCustomerQuotes = async (customerId, filters, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.customer.quotes(customerId, filters))
    .then(({ data }) => data?.data);

export const fetchQuote = async (quoteId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.quote.id(quoteId))
    .then(({ data }) => data?.data);

export const fetchQuotes = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.quote.list(filters))
    .then(({ data }) => data?.data);

export const fetchUserQuotes = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.user.quotes(filters))
    .then(({ data }) => data?.data);

export const performQuoteAction = async (action: keyof typeof QuoteAction, quoteId: string) =>
  await initApi()
    .post(routes.quote.action(quoteId), {
      action,
    })
    .then(({ data }) => data?.data);

export const exportCustomerQuotes = async (filters, customerId) =>
  await initApi()
    .get(routes.customer.quoteExport(customerId, filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));

export const exportQuotes = async (filters) =>
  await initApi()
    .get(routes.quote.export(filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));

export const sendBatchQuote = async ({ customers, quote }: { customers: QuoteCustomer[]; quote: Quote }) =>
  await initApi()
    .post(routes.quote.batch, {
      customers: customers.map((customer) => customer.id),
      ...adaptOutgoingQuote(quote),
    })
    .then(({ data }) => data?.data);

export const approveQuote = async (quoteId: string) => {
  return await initApi()
    .post(routes.quote.approve(quoteId))
    .then(({ data }) => data?.data);
};

export const rejectQuote = async (quoteId: string) => {
  return await initApi()
    .post(routes.quote.reject(quoteId))
    .then(({ data }) => data?.data);
};

export const disapproveQuote = async (quoteId: string) => {
  return await initApi()
    .post(routes.quote.disapprove(quoteId))
    .then(({ data }) => data?.data);
};

export const fetchAblQuote = async (quoteId, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.quote.abl(quoteId))
    .then(({ data }) => data?.data);

export const verifyAbl = async ({ quoteId, code }: { quoteId: string; code: string }) =>
  await initApi()
    .post(routes.quote.login(quoteId), { code })
    .then(() => {
      return { status: 204, redirectPath: `/approve/${quoteId}` };
    })
    .catch((error) => ({
      message: error?.response?.data?.message ?? "request failed",
    }));

export const sendQuoteMagicCode = async ({ quoteId }: { quoteId: string }) =>
  await initApi()
    .get(routes.quote.code(quoteId))
    .catch((error) => ({
      message: error?.response?.data?.message ?? "request failed",
    }));
