import { initApi, routes } from "@/config/api";
import stringifyQueryFilters from "@/utils/stringifyQueryParams";
import { downloadFromBlob } from "@/utils/download";
import { GetServerSidePropsContext } from "next";

export const fetchCustomer = async (customerId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.customer.id(customerId))
    .then(({ data }) => data?.data);

export const fetchCustomers = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.customer.list(filters))
    .then(({ data }) => data?.data);

export const fetchAllCustomers = async (req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.customer.all)
    .then(({ data }) => data?.data);

export const searchAllCustomers = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.customer.search(filters))
    .then(({ data }) => data?.data);

export const createCustomer = async (values) =>
  await initApi()
    .post(routes.customer.create, values)
    .then(({ data }) => data?.data);

export const saveCustomer = async (values) => {
  const { id, ...valuesToSend } = values;

  return await initApi()
    .patch(`${routes.customer.id(id)}`, valuesToSend)
    .then(({ data }) => data?.data);
};

export const deleteCustomer = async (customerId) =>
  await initApi()
    .delete(routes.customer.id(customerId))
    .then(() => ({ deleted: true, message: "Customer deleted successfully" }));

export const exportCustomers = async (filters) =>
  await initApi()
    .get(`${routes.customer.export}${stringifyQueryFilters(filters)}`, {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));

export const createCustomerCategory = async (values) => {
  const { id, ...valuesToSend } = values;
  return await initApi()
    .post(`${routes.customer.id(id)}/category`, valuesToSend)
    .then(({ data }) => data?.data);
};

export const saveCustomerCategory = async (values) => {
  const { id, ...valuesToSend } = values;
  return await initApi()
    .patch(`${routes.customer.category(id)}`, valuesToSend)
    .then(({ data }) => data?.data);
};

export const deleteCustomerCategory = async (categoryId) =>
  await initApi()
    .delete(routes.customer.category(categoryId))
    .then(() => ({
      deleted: true,
      message: "Service For deleted successfully",
    }))
    .catch(() => ({
      deleted: false,
      message: "Service For could not be deleted",
    }));

export const fetchTags = async (req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.tags.all)
    .then(({ data }) => data?.data);

export const saveTags = async (values) => {
  return await initApi()
    .post(`${routes.tags.create}`, values)
    .then(({ data }) => data?.data);
};

export const deleteTag = async (tagId: string) =>
  await initApi()
    .delete(routes.tags.delete(tagId))
    .then(() => ({ deleted: true, message: "Tag deleted successfully" }))
    .catch(() => ({ deleted: false, message: "Tag could not be deleted" }));

export const importCustomerList = async (file: File) =>
  await initApi().post(
    routes.customer.import,
    { file },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

export const connectCustomerAndBusiness = async (customerId: string, is_connected: boolean) =>
  await initApi().patch(routes.user.associateBusiness(customerId), {
    is_connected,
  });

export const searchContactList = async (searchQuery, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.contact.search(searchQuery))
    .then(({ data }) => data?.data);

export const notifyContact = async (customers) =>
  await initApi()
    .post(`${routes.contact.notify}`, { contact: customers })
    .catch((error) => {
      return error?.response?.data;
    });
