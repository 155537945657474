import removeEmptyValues from "@/utils/removeEmptyValues";
import * as yup from "yup";
import "yup-phone";

export const emailValidation = yup.object().shape({
  email: yup.string().email("Please enter a valid email"),
});

export const nameValidation = yup.object().shape({
  name: yup.string().required("Please enter customer name"),
});

export const phoneValidation = yup.object().shape({
  cell_phone: yup.string().phone("US", true, "Please enter a valid phone number"),
});

export const customerValidation = async (values) => {
  // TODO: Validation is done like this because using yup alone took too long to figure out.
  // Doing this in "yup" seems to be more complex than it's worth but I also didn't spend much
  // time trying to figure out the right approach.
  const errors: any = {};

  const name = values?.name;
  const email = values?.email;
  const cell_phone = values?.cell_phone;

  if (email) {
    errors.email = await emailValidation
      .validate({ email })
      .then((v) => (v?.email === email ? "" : v?.email))
      .catch((e) => e.message);
  }

  if (Boolean(cell_phone) && !cell_phone.includes("_") && cell_phone !== "+1") {
    errors.cell_phone = await phoneValidation
      .validate({ cell_phone })
      .then((v) => (v?.cell_phone === cell_phone ? "" : v?.cell_phone))
      .catch((e) => e.message);
  }

  errors.name = await nameValidation
    .validate({ name })
    .then((v) => (v?.name === name ? "" : v?.name))
    .catch((e) => e.message);

  if (!email && !cell_phone) {
    errors.email = "At least one contact method is required";
    errors.cell_phone = "At least one contact method is required";
  }

  removeEmptyValues(errors);

  return errors;
};
