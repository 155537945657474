import Decimal from "decimal.js-light";

export const getDollarsFromInt = (flat) => {
  return new Decimal(flat ?? 0).dividedBy(100).toNumber();
};

export const getPercentFromInt = (percent) => {
  return new Decimal(percent ?? 0).times(100).toNumber();
};

export const getIntFromDollars = (flat) => {
  return new Decimal(flat ?? 0).times(100).toNumber();
};

export const getIntFromPercent = (percent) => {
  return new Decimal(percent ?? 0).dividedBy(100).toNumber();
};

export const getPriceModifierCalculation = (modifier) => {
  return !Boolean(modifier?.value_flat) ? "percentage" : "fixed";
};

export const isPercentModifier = (modifier) => getPriceModifierCalculation(modifier) === "percentage";

export const getPriceModifierValue = (modifier) => {
  const isPercent = isPercentModifier(modifier);

  return isPercent ? getPercentFromInt(modifier?.value_pct) : getDollarsFromInt(modifier?.value_flat);
};

export const getChangeAmount = (amount = 0, quantity = 1, modifier) => {
  const isPercent = isPercentModifier(modifier);

  return isPercent
    ? getDollarsFromInt(
        Math.round(
          new Decimal(amount)
            .times(quantity)
            .times(modifier?.value_pct ?? 0)
            .toNumber(),
        ),
      )
    : new Decimal(getDollarsFromInt(modifier?.value_flat ?? 0)).times(quantity).toNumber();
};

export const calculatePriceModifier = ({
  price = 0,
  pricemodifier_name,
  pricemodifier_value_pct,
}: {
  price?: number;
  pricemodifier_name?: string;
  pricemodifier_value_pct?: string | number;
}) => {
  let amount;
  let priceModifier = "";

  if (pricemodifier_name && pricemodifier_value_pct) {
    let modifier = "";
    if (+pricemodifier_value_pct < 0) modifier = "-";
    const value = Math.abs(+pricemodifier_value_pct * 100);
    amount = price * +pricemodifier_value_pct;

    if (amount < -price) {
      amount = -price;
    }
    priceModifier = ` (${modifier}${value.toFixed(2)}%)`;
  }

  return {
    name: `${pricemodifier_name}${priceModifier}`,
    amount,
  };
};

export const sum = (values: any[], initialValue: number = 0): number =>
  !Array.isArray(values)
    ? 0
    : values.reduce((acc, inc) => new Decimal(acc).plus(new Decimal(inc)).toNumber(), initialValue);

export const getInvoiceTotals = (invoice) => {
  let subTotal = sum(
    (invoice?.items || []).map((item: any) => {
      const changeAmount =
        new Decimal(
          getChangeAmount(item?.price, item?.quantity, {
            value_pct: item?.pricemodifier_value_pct,
            value_flat: item?.pricemodifier_value_flat,
          }),
        ).toNumber() || 0;

      const d = new Decimal(getDollarsFromInt(item?.price) ?? 0)
        .times(item?.quantity ?? 1)
        .add(changeAmount || 0)
        .toNumber();

      return d < 0 ? 0 : d;
    }) as number[],
  );

  const modificationTotal = new Decimal(
    getChangeAmount(getIntFromDollars(subTotal), 1, {
      value_pct: invoice?.pricemodifier_value_pct,
      value_flat: invoice?.pricemodifier_value_flat,
    }),
  ).toNumber();

  // If flat amount, subtract the flat amount from the subtotal
  // If percentage amount, subtract (subtotal * percentage) from the subtotal

  // if percentage amount = -0.03

  // Subtotal = 100
  // Modified value:  100 * 0.03 = 3 -> surcharge
  // Modified value:  100 * -0.03 = -3 -> discount
  // Modified value = 100 + 3 = surcharge
  // Modified value = 100 + (-3) = discount

  let invoiceTotal = new Decimal(subTotal).add(modificationTotal).toDecimalPlaces(2).toNumber();

  subTotal = subTotal < 0 ? 0 : subTotal;
  invoiceTotal = invoiceTotal < 0 ? 0 : invoiceTotal;

  return {
    subTotal,
    modificationTotal,
    invoiceTotal,
  };
};
