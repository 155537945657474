import { User } from "@/types";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

export const initLogRocket = () => {
  if (typeof window !== "undefined" && process.env.ENV_NAME === "production") {
    LogRocket.init("4wno1l/finli-master-view");
    setupLogRocketReact(LogRocket);
  }
};

export const identifyLogRocketUser = (user: Pick<User, "id" | "name" | "email" | "cell_phone">) => {
  if (typeof window !== "undefined" && Boolean(user?.id) && process.env.ENV_NAME === "production") {
    LogRocket.identify(user?.id as string, {
      name: user?.name as string,
      email: user?.email as string,
      cell_phone: user?.cell_phone as string,
    });
  }
};
