import { AccountRoles, Role } from "@/types";
import useUser from "../auth/useUser";
import usePartnership from "./usePartnership";
import { useMemo } from "react";

type UsePartnershipRoleReturn = {
  role: Role | undefined;
  isPartnerAdmin: boolean;
  isPartnerMember: boolean;
};

const usePartnershipRole = (): UsePartnershipRoleReturn => {
  const { user } = useUser();
  const { partnership } = usePartnership();

  const role = useMemo(
    () => (user?.roles || [])?.find((role) => role?.partnership_id === partnership?.id) || undefined,
    [user?.roles, partnership?.id],
  );

  return {
    role,
    isPartnerAdmin: role?.type === AccountRoles.PARTNERSHIP_OWNER,
    isPartnerMember: role?.type === AccountRoles.PARTNERSHIP_MEMBER,
  };
};

export default usePartnershipRole;
