import { ReactNode } from "react";
import Alert, { AlertProps } from "@mui/material/Alert";
import TestId from "@/constants/testIds";

export const SystemFeedback = ({
  message,
  type = "error",
  ...props
}: AlertProps & {
  message?: string | ReactNode | null;
  type?: AlertProps["color"];
}) => {
  return !Boolean(message) ? null : (
    <Alert
      data-testid={TestId.System.Feedback}
      color={type}
      severity={type}
      {...props}
      sx={{
        margin: "1rem 0",
        textAlign: "left",
        ...(props?.sx ? props?.sx : {}),
      }}
    >
      {message}
    </Alert>
  );
};

export default SystemFeedback;
