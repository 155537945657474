import { initApi, routes } from "@/config/api";
import stringifyQueryFilters from "@/utils/stringifyQueryParams";
import { downloadFromBlob } from "@/utils/download";
import { GetServerSidePropsContext } from "next";
import {
  Merchant,
  AdminPayment,
  Paginated,
  MerchantStatusEnum,
  ComplianceStatusEnum,
  AdminBasicUser,
  AdminUser,
  AdminPartner,
  User,
} from "@/types";

export const fetchAdminPayments = async (
  filters = {},
  req?: GetServerSidePropsContext["req"],
): Promise<Paginated<AdminPayment>> =>
  await initApi(req)
    .get(routes.admin.payments.list(filters))
    .then(({ data }) => ({
      pageInfo: data?.data?.pageInfo,
      results: Boolean(data?.data?.payments) ? data?.data?.payments : data?.data?.results,
    }));

export const exportAdminPayments = async (filters) =>
  await initApi()
    .get(routes.admin.payments.export(filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));

export const initiatePayouts = async (paymentIds: string[]) =>
  await initApi()
    .post(routes.admin.payments.payout, { paymentIds })
    .then((response) => response);

// The endpoint supports multiple returns, but the UI only allows
// one return to be initiated at a time - this is probably going
// to change at some point in the future.
export const initiateCardReturn = async (paymentId: string) =>
  await initApi()
    .post(routes.admin.payments.cardReturn, { paymentIds: [paymentId] })
    .then((response) => response);

export const initiateCardReturnReversal = async (paymentId: string) =>
  await initApi()
    .post(routes.admin.payments.cardReturnReversal(paymentId))
    .then((response) => response);

export const fetchMerchants = async (
  filters = {},
  req?: GetServerSidePropsContext["req"],
  options?: {
    has_partnership?: boolean;
    valid_for_partnership_id?: string;
  },
) =>
  await initApi(req)
    .get(routes.admin.merchant.list(filters), {
      params: options,
    })
    .then(({ data }) => ({
      pageInfo: data?.data?.pageInfo,
      results: Boolean(data?.data?.merchants) ? data?.data?.merchants : data?.data?.results,
    }));

export const exportMerchants = async (filters) =>
  await initApi()
    .get(routes.admin.merchant.export(filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));

export const fetchMerchant = async (merchantId: string, req?: GetServerSidePropsContext["req"]): Promise<Merchant> =>
  await initApi(req)
    .get(routes.admin.merchant.id(merchantId))
    .then(({ data }) => data?.data);

export const updateMerchant = async (
  merchantId: string,
  merchantData: {
    status?: MerchantStatusEnum;
    compliance_status?: ComplianceStatusEnum;
  },
  req?: GetServerSidePropsContext["req"],
): Promise<Merchant> =>
  await initApi(req)
    .patch(routes.admin.merchant.id(merchantId), merchantData)
    .then(({ data }) => data?.data);

export const fetchAdminUsers = async (
  filters = {},
  req?: GetServerSidePropsContext["req"],
): Promise<Paginated<AdminBasicUser>> =>
  await initApi(req)
    .get(routes.admin.users.list(filters))
    .then(({ data }) => ({
      pageInfo: data?.data?.pageInfo,
      results: Boolean(data?.data?.users) ? data?.data?.users : data?.data?.results,
    }));

export const fetchAdminUser = async (userId: string, req?: GetServerSidePropsContext["req"]): Promise<AdminUser> =>
  await initApi(req)
    .get(routes.admin.users.user(userId))
    .then(({ data }) => data?.data);

export const fetchAdminUserInvoices = async (userId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.admin.users.invoices(userId))
    .then(({ data }) => data?.data);

export const exportAdminUsers = async (filters) =>
  await initApi()
    .get(routes.admin.users.export(filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));

export const updateAdminUser = async (userId: string, values: any) =>
  await initApi()
    .patch(routes.admin.users.user(userId), { ...values })
    .then((response) => response);

export const resetAllProductUpdates = async () =>
  await initApi()
    .post(routes.admin.resetProductUpdates)
    .then((response) => response);

export const updateAdminPartner = async (partnerId: string, values) =>
  await initApi()
    .patch(routes.admin.partnership.edit(partnerId), values)
    .then((response) => response);

export const adminResetUserPassword = async (userId: string) =>
  await initApi()
    .post(routes.admin.users.passwordReset(userId))
    .then((response) => response);

export const mergeAdminUsers = async (values: {
  userIds: { primary: string; merge: string };
  fields: {
    cell_phone?: "PRIMARY" | "MERGE";
    email?: "PRIMARY" | "MERGE";
  };
}) =>
  await initApi()
    .post(routes.admin.users.merge, values)
    .then((response) => response);

export const spoofUser = async (userId: string) => {
  return await initApi()
    .post(routes.user.context.switch, { spoofUserId: userId })
    .then(({ data }) => {
      return data;
    });
};

export const spoofAccount = async (accountId: string) => {
  return await initApi()
    .post(routes.user.context.switch, { spoofAccountId: accountId })
    .then(({ data }) => {
      return data;
    });
};

export const unspoofUser = async () => {
  return await initApi()
    .post(routes.user.context.switch, { isReset: true })
    .then(({ data }) => {
      return data;
    });
};

export const fetchAdminPartners = async (
  filters = {},
  req?: GetServerSidePropsContext["req"],
  options?: {
    valid_for_merchant_id?: string;
  },
): Promise<Paginated<AdminPartner>> =>
  await initApi(req)
    .get(routes.admin.partnership.list(filters), {
      params: options,
    })
    .then(({ data }) => ({
      pageInfo: data?.data?.pageInfo,
      results: Boolean(data?.data?.partnerships) ? data?.data?.partnerships : data?.data?.results,
    }));

export const fetchAdminPartner = async (
  partnerId: string,
  req?: GetServerSidePropsContext["req"],
): Promise<AdminPartner> =>
  await initApi(req)
    .get(routes.admin.partnership.partner(partnerId))
    .then(({ data }) => data?.data);

export const fetchAdminPartnerInvoices = async (
  partnerId: string,
  req?: GetServerSidePropsContext["req"],
  filters = {},
) =>
  await initApi(req)
    .get(`${routes.admin.partnership.invoices(partnerId)}${stringifyQueryFilters(filters)}`)
    .then(({ data }) => data?.data);

export const fetchAdminPartnerTeamMembers = async (partnerId: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.admin.partnership.users(partnerId))
    .then(({ data }) => data?.data);

export const invitePartnershipTeamMember = async (partnershipId, values: Partial<User>) =>
  await initApi()
    .post(routes.admin.partnership.role.create(partnershipId), values)
    .then(({ data }) => data?.data);

export const resendPartnershipInvitation = async (partnershipId, roleId: string) =>
  await initApi()
    .post(routes.admin.partnership.role.resendNotification(partnershipId, roleId))
    .then(({ data }) => data?.data);

export const revokeMemberAccess = async (partnershipId: string, roleId: string) =>
  await initApi()
    .delete(routes.admin.partnership.role.edit(partnershipId, roleId))
    .then(({ data }) => data?.data);

export const exportAdminPartners = async (filters) =>
  await initApi()
    .get(routes.admin.partnership.export(filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));

export const exportPartnerInvoices = async (filters) =>
  await initApi()
    .get(routes.admin.partnership.exportInvoices(filters), {
      responseType: "blob",
    })
    .then((res) => downloadFromBlob(res));

export const addMerchant = async (partnerId: string = "", merchantId?: string) =>
  await initApi()
    .post(routes.admin.partnership.merchant(partnerId), { merchantId })
    .then((response) => response);

export const removeMerchant = async (partnerId: string, merchantId: string) =>
  await initApi()
    .delete(routes.admin.partnership.merchant(partnerId), {
      data: { merchantId },
    })
    .then((response) => response);

export const fetchAdminEvents = async (filters = {}, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.admin.events.base(filters))
    .then(({ data }) => data?.data);

export const disassociateUserAndMerchant = async (merchantId: string, userId: string) =>
  await initApi()
    .delete(routes.admin.removeUserFromMerchant(merchantId, userId))
    .then(({ data }) => data?.data);

export const modifyUserRule = async ({
  partnerId,
  userId,
  role,
}: {
  partnerId: string;
  userId: string;
  role: string;
}) =>
  await initApi()
    .patch(routes.admin.partnership.role.edit(partnerId, userId), {
      type: role,
    })
    .then(({ data }) => data?.data);

export const adminCreatePartnerMember = async ({ partnerId, values }: { partnerId: string; values: any }) =>
  await initApi()
    .post(routes.admin.partnership.role.create(partnerId), values)
    .then(({ data }) => data?.data);
