import { styled } from "@mui/material";
import Box from "@mui/material/Box";

const RadioBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "disabled",
})<{ active?: boolean; disabled?: boolean }>(
  ({ theme, active, disabled }) => `
      border: 2px solid ${theme.palette.grey[400]};
      cursor: pointer;
      pointer-events: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      background-color: white;
      padding: ${theme.spacing(1.5, 2.5)};
      border-radius: ${theme.shape.borderRadius}px;
      box-shadow: ${active ? theme.shadows[3] : "none"};
      border-color: ${
        disabled ? theme.palette.grey[300] : active ? theme.palette.primary.main : theme.palette.grey[400]
      };
      box-sizing: border-box;
      width: 100%;

      p {
        margin-top: ${theme.spacing(0.75)};
      }
  
  `,
);

export default RadioBox;
