import { NumericFormat, NumericFormatProps } from "react-number-format";
import TextField, { CustomTextFieldProps } from "@/components/TextField";

export type NumberFieldProps = NumericFormatProps<CustomTextFieldProps>;

const NumberField = (props: NumberFieldProps) => {
  return (
    <NumericFormat
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
      displayType="input"
      valueIsNumericString
      allowLeadingZeros={props?.allowNegative ?? false}
      allowNegative={props?.allowNegative ?? false}
      customInput={TextField}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values || undefined;
        const billion = 1000000000;

        return typeof floatValue === "undefined"
          ? formattedValue === "" || formattedValue === "-"
          : floatValue <= billion && floatValue >= -billion;
      }}
      {...props}
    />
  );
};

export default NumberField;
