import omitBy from "lodash/omitBy";
import isEqual from "lodash/isEqual";

/**
 * Compares values in the two provided objects and omits all equal values.
 * This is primarily intended for comparing the intial values in a form with the
 * values the user may or may not have entered or modified.
 * @param {Object} newValues Object containing the potentially modified values.
 * @param {Object} currentValues Object containing the initial or current values to test against.
 * @returns {Object} Modified object containing only the values that have changed.
 */
const getModifiedValues = <T>(newValues: any, currentValues: any, excludeKeys?: string[]): Partial<T> => {
  const modified = omitBy(newValues, (value, key) => excludeKeys?.includes(key) || isEqual(currentValues[key], value));

  if (Object.keys(modified).length) {
    return modified;
  }

  return {};
};

export default getModifiedValues;
