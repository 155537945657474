import { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InlineContainer from "@/components/InlineContainer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseRounded";
import NeutralButton from "@/components/buttons/NeutralButton";
import AffirmativeButton from "@/components/buttons/AffirmativeButton";
import { padWithZeros } from "@/utils/stringUtils";
import { Invoice } from "@/types";

export default NiceModal.create<{ invoice: Invoice }>(({ invoice }) => {
  const modal = useModal();

  const [decision, setDecision] = useState<"individual" | "series" | null>(null);

  const handleCloseModal = () => {
    modal.hide();
  };

  return (
    <Dialog
      open={modal.visible}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseModal}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
    >
      <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between">
          <Typography
            variant="h6"
            sx={{
              color: "primary.dark",
              paddingLeft: { xs: "30px", md: 0 },
              textAlign: { xs: "center", md: "left" },
              width: "100%",
            }}
          >
            Cancel Invoice
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={handleCloseModal}
            sx={{
              color: "grey.500",
              position: "relative",
              right: -6,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </InlineContainer>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          Invoice #{padWithZeros(invoice?.invoice_number || 0)} is actively recurring. Choose one of the following
          options:
        </Typography>
        <Box sx={{ marginTop: 2 }}>
          <FormGroup
            sx={{
              background: "#F6F9FB",
              borderRadius: "6px",
              padding: "0.25rem 0.75rem",
            }}
          >
            <FormControlLabel
              sx={{ justifyContent: "start", alignItems: "start" }}
              control={
                <Radio size="small" checked={decision === "individual"} onClick={() => setDecision("individual")} />
              }
              label={
                <Box margin="0.5rem 0">
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Cancel this invoice only
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.700" }}>
                    The next invoice will be scheduled as planned.
                  </Typography>
                </Box>
              }
            />
          </FormGroup>
        </Box>
        <Box sx={{ marginTop: 1 }}>
          <FormGroup
            sx={{
              background: "#F6F9FB",
              borderRadius: "6px",
              padding: "0.25rem 0.75rem",
            }}
          >
            <FormControlLabel
              control={<Radio size="small" checked={decision === "series"} onClick={() => setDecision("series")} />}
              label={
                <Typography variant="body1" sx={{ fontWeight: 500, margin: "0.5rem 0" }}>
                  Cancel this and all future invoices
                </Typography>
              }
            />
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
        <InlineContainer justifyContent="space-between" width="100%">
          <NeutralButton
            disableElevation
            onClick={() => {
              modal.reject();
              handleCloseModal();
            }}
          >
            Cancel
          </NeutralButton>
          <AffirmativeButton
            onClick={() => {
              modal.resolve(decision);
              handleCloseModal();
            }}
          >
            Confirm
          </AffirmativeButton>
        </InlineContainer>
      </DialogActions>
    </Dialog>
  );
});
