import { Account, ComplianceStatusEnum, User } from "@/types";

export const complianceFailed = (account) => {
  return account?.compliance_status === ComplianceStatusEnum.FAIL;
};

export const complianceNotSubmitted = (account) => {
  return account?.compliance_status === ComplianceStatusEnum.NOT_SUBMITTED;
};

export const compliancePending = (account?: Account) => {
  return account?.compliance_status === ComplianceStatusEnum.PENDING;
};

export const compliancePassed = (account?: Account) => {
  return (
    account?.compliance_status === ComplianceStatusEnum.PASS ||
    account?.compliance_status === ComplianceStatusEnum.PROVISIONAL
  );
};

export const getComplianceCompletionPercentage = (user?: User, account?: Account) => {
  if (compliancePending(account)) {
    return 100;
  }

  if (!account?.legal_address1) {
    return 0;
  }

  const requiredFields = {
    first_name: user?.compliance_answer?.first_name ?? null,
    date_of_birth: user?.compliance_answer?.date_of_birth ?? null,
    personal_address: user?.compliance_answer?.address_1 ?? null,
    legal_name: account?.legal_name ?? null,
    public_name: account?.public_name ?? null,
    legal_address1: account?.legal_address1 ?? null,
    legal_email: account?.legal_email ?? null,
    legal_phone: account?.legal_phone ?? null,
    service_type: account?.compliance_answer?.industry ?? null,
    customer_type: account?.compliance_answer?.customer_type ?? null,
    description: account?.description ?? null,
    business_structure: account?.compliance_answer?.business_structure ?? null,
    // ssn: account?.compliance_answer?.ssn_last_4 ?? null,
    // ein: account?.ein ?? null,
    last_four_ssn: account?.compliance_answer?.ssn_last_4 ?? null,
    relationship_to_business: account?.compliance_answer?.relationship_to_business ?? null,
    payout_wallet_instrument_id: account?.wallet?.payout_wallet_instrument_id ?? null,
    persona_id_completed_at: account?.persona_id_completed_at ?? null,
  };

  const requiredCount = Object.keys(requiredFields).length;

  const filledCount = Object.values(requiredFields).reduce((total, field) => (field !== null ? total + 1 : total), 0);

  return Math.round((filledCount / requiredCount) * 100);
};

export const getContentByComplianceStatus = (account?: Account, hasStartedCompliance: boolean = false) => {
  const complianceStatus = account?.compliance_status || ComplianceStatusEnum.NOT_SUBMITTED;

  const content = {
    [ComplianceStatusEnum.NOT_SUBMITTED]: {
      header: "Enable Direct Deposits",
      body: "Get approved to have online payments deposited directly into your bank account.",
      image: "/direct-deposit.svg",
      button: "Get Started",
    },
    [ComplianceStatusEnum.PENDING]: {
      header: "We're reviewing your information",
      body: "After your business details are verified, funds will be directly deposited into your bank account.",
      image: "/hourglass.svg",
      button: null,
    },
    [ComplianceStatusEnum.FAIL]: {
      header: "We are unable to approve you for payment processing",
      body: "No worries! All other Finli features will keep running smoothly.",
      image: "/light-bulb.svg",
      button: null,
    },
    [ComplianceStatusEnum.PASS]: {
      header: "Congratulations, you’re approved!",
      body: "Payments from your customers will now be deposited directly into your Bank Account for Deposits. You can see which payments have been sent under “Deposits Sent” on your Dashboard.",
      image: "/icon-approved-app.svg",
      button: "Get Started",
    },
    // Provisional is the same as the PASS compliance status
    [ComplianceStatusEnum.PROVISIONAL]: {
      header: "Congratulations, you’re approved!",
      body: "Payments from your customers will now be deposited directly into your Bank Account for Deposits. You can see which payments have been sent under “Deposits Sent” on your Dashboard.",
      image: "/icon-approved-app.svg",
      button: "Get Started",
    },
    STARTED: {
      header: "You're almost done!",
      body: "Pick up where you left off to activate direct deposits.",
      image: "/direct-deposit.svg",
      button: "Resume",
    },
    ID_VERIFICATION: {
      header: "You're almost done!",
      body: "Finish verifying your identity to activate direct deposits.",
      image: "/direct-deposit.svg",
      button: "Resume",
    },
  };

  if (complianceStatus !== ComplianceStatusEnum.NOT_SUBMITTED) {
    return content[complianceStatus];
  }

  if (
    hasStartedCompliance &&
    !Boolean(account?.wallet?.payout_wallet_instrument_id) &&
    !Boolean(account?.persona_id_completed_at)
  ) {
    return content["STARTED"];
  }

  if (
    hasStartedCompliance &&
    Boolean(account?.wallet?.payout_wallet_instrument_id) &&
    !Boolean(account?.persona_id_completed_at)
  ) {
    return content["ID_VERIFICATION"];
  }

  return content[complianceStatus];
};
