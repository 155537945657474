import Image from "next/legacy/image";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Header } from "@/features/auth/components/styled";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Step from "@mui/material/Step";
import { StepIconProps } from "@mui/material/StepIcon";
import UserIcon from "@mui/icons-material/PersonOutlineOutlined";
import StoreIcon from "@mui/icons-material/StoreRounded";
import useAppSelector from "@/hooks/useAppSelector";
import CloseIcon from "@mui/icons-material/Close";
import { selectPartnerTheme } from "@/features/layout/layout-selectors";
import InlineContainer from "@/components/InlineContainer";
import { useMediaQuery, useTheme } from "@mui/material";

export const Title = ({ title, description = "" }) => {
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Typography
          variant="h3"
          sx={{
            margin: "1rem 0",
            textAlign: "center",
            maxWidth: "480px",
          }}
        >
          {title}
        </Typography>
      </Box>
      {description && (
        <Typography variant="body1" sx={{ maxWidth: "480px", textAlign: "center" }}>
          {description}
        </Typography>
      )}
    </>
  );
};

export const ProgressBar = styled(Box)<{ percent: number }>(
  ({ theme, percent }) => `
  background: ${theme.palette.grey[400]};
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 100%;
  height: 6px;

  &::before {
    background: ${theme.palette.primary.main};
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: ${percent}%;
    height: 6px;
    transition: width 300ms;
  }
`,
);

export const TopBar = ({ action = null }: { action?: any }) => {
  const partnerTheme = useAppSelector(selectPartnerTheme);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Header>
      {partnerTheme?.name ? (
        <InlineContainer sx={{ gap: { xs: ".35rem", md: "1rem" }, width: "100%" }}>
          <InlineContainer width="100%" sx={{ gap: { xs: "0.5rem", md: "1rem" } }}>
            {Boolean(partnerTheme?.logo) && (
              <img src={partnerTheme?.logo} alt="Partner Logo" style={{ maxWidth: "80%" }} height={40} />
            )}
            {!isMobile && (
              <Typography variant="h4" color="primary.contrastText" sx={{ lineHeight: "100%", marginLeft: "-0.25rem" }}>
                {partnerTheme?.name}
              </Typography>
            )}
            <CloseIcon
              fontSize="small"
              sx={{
                color: "primary.contrastText",
                margin: "0 0.1rem",
              }}
            />
            <Image src="/logo-finli-white-transparent.png" width={50} height={25} alt="Finli Logo" />
          </InlineContainer>
          {action ? <Box sx={{ whiteSpace: "nowrap" }}>{action}</Box> : null}
        </InlineContainer>
      ) : (
        <InlineContainer justifyContent="space-between" width="100%">
          <Image src="/logo-finli-white-transparent.png" width={50} height={25} alt="Finli Logo" />
          {action ? <Box sx={{ whiteSpace: "nowrap" }}>{action}</Box> : null}
        </InlineContainer>
      )}
    </Header>
  );
};

export const RadioLabel = styled(Box)(
  ({ theme }) => `
  border: 1px solid ${theme.palette.grey[300]};
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 1rem 0 0;
  padding: 0.25rem 1rem;
  width: 100%;
`,
);

const StepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: "grey.100",
  color: "grey.400",
  padding: "3px",
  border: "1px solid",
  borderColor: "grey.400",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1,
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  }),
  ...(ownerState.completed && {
    backgroundColor: "grey.300",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  }),
}));

const StepIcon = (props: StepIconProps) => {
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <StoreIcon fontSize="small" />,
    2: <UserIcon fontSize="small" />,
    3: <StoreIcon fontSize="small" />,
  };

  return <StepIconRoot ownerState={{ completed, active }}>{icons[String(props.icon)]}</StepIconRoot>;
};

export const Steps = ({ activeStep }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Stepper activeStep={activeStep} sx={{ width: 340 }}>
        {/* This is a hacky fix for only showing two steps instead of three.
                The Stepper component doesn't like having hidden steps.  */}
        <Step
          sx={{
            display: "none",
            "& + .MuiStepConnector-root": { display: "none" },
          }}
        />
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Personal Info</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Business Info</StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
};
