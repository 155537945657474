import TextField from "@/components/TextField";
import getFieldErrors from "@/utils/getFieldErrors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";

const DateOfBirth = ({ formik }) => {
  return (
    <>
      <Typography variant="h4">What is your date of birth?</Typography>
      <Box marginTop="2rem">
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            orientation="portrait"
            disableFuture
            openTo="year"
            views={["year", "month", "day"]}
            name="compliance_answer['date_of_birth']"
            value={DateTime.fromJSDate(new Date(formik?.values?.compliance_answer?.["date_of_birth"])) || undefined}
            onChange={(newValue) => {
              formik.setFieldValue("compliance_answer['date_of_birth']", newValue);
            }}
            slots={{ textField: TextField }}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                sx: {
                  maxWidth: "300px",
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px",
                    color: "grey.600",
                  },
                },
                ...getFieldErrors("compliance_answer['date_of_birth']", formik),
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
};

export default DateOfBirth;
