import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Query, Partnership } from "@/types";
import useAppSelector from "@/hooks/useAppSelector";
import { fetchPartnershipDetails } from "./partner-management-queries";
import { selectPartnershipId } from "@/features/auth/auth-selectors";
import { useMutation } from "@tanstack/react-query";
import decodeToken from "@/utils/saveSessionToken";
import { setAccountId, setAdminRole, setPartnershipId } from "@/features/auth/auth-reducers";
import { clientBaseUrl } from "@/config/api";
import { switchPartnership } from "@/features/partner-management/partner-management-queries";
import useAppDispatch from "@/hooks/useAppDispatch";
import { useSnackbar } from "notistack";

interface UsePartnership {
  partnership?: Partnership;
  actions: {
    switch: (accopartnershipIduntId: string) => void;
  };
}

const usePartnership = (): UsePartnership => {
  const queryClient = useQueryClient();
  const partnershipId = useAppSelector(selectPartnershipId);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const partnershipSwitch = useMutation(switchPartnership, {
    onSuccess: (res) => {
      queryClient.invalidateQueries([Query.partnership.PARTNERSHIP_INFO]);

      const { partnershipId } = decodeToken(res?.data?.token);
      dispatch(setAccountId(null));
      dispatch(setPartnershipId(partnershipId));
      dispatch(setAdminRole(null));

      window.location.href = `${clientBaseUrl}/partner`;
    },
    onError: () => {
      enqueueSnackbar("Could not switch to selected account", {
        variant: "error",
      });
    },
  });

  const { data: partnership } = useQuery<Partnership, Error>(
    [Query.partnership.PARTNERSHIP_INFO],
    () => fetchPartnershipDetails(),
    {
      initialData: undefined,
      retry: 2,
      keepPreviousData: true,
      enabled: !!partnershipId,
    },
  );

  return {
    partnership: partnershipId ? partnership : undefined,
    actions: {
      switch: partnershipSwitch.mutate,
    },
  };
};

export default usePartnership;
